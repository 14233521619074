import { format, parseISO } from 'date-fns';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/Store";
import { EStatus, ETypePayement, ITransaction, setTransactionList, updateTransaction } from "../../../../Toolkit/SubcriptionTransactionAction";

interface RowsTransactionProps {
  transaction: ITransaction;
  admin: boolean;
}
const RowsTransaction: React.FC<RowsTransactionProps> = ({ transaction, admin }) => {
  /**
* REDUX
*/
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: any) => state.subscriptionTransaction.message);
  const updatedTransaction: ITransaction = useSelector((state: any) => state.subscriptionTransaction.updatedTransaction);
  const transactionList: ITransaction[] = useSelector((state: any) => state.subscriptionTransaction.transactionList);

  /**
   * VARIABLE
   */
  const text = 'text-sm text-left leading-6 whitespace-nowrap px-5 py-3';

  /**
   * LYFECYLE
   */
  useEffect(() => {
    if (updatedTransaction) {
      const updateTransaction: ITransaction[] = transactionList.map((transaction: ITransaction) => {
        return transaction.id === updatedTransaction.id ? updatedTransaction : transaction
      })
      dispatch(setTransactionList(updateTransaction))
    }
  }, [updatedTransaction])

  /**
   * FUNCTION
   */
  const onChangeStatus = (status: EStatus) => {
    dispatch(updateTransaction({ id: transaction.id, status: status }));
  }

  const textTypeTransaction = (codePayement: string) => {
    switch (codePayement) {
      case ETypePayement.MVOLA:
        return 'MVola';
      case ETypePayement.ORANGE_MONEY:
        return 'Orange Money';
      case ETypePayement.VISA:
        return 'VISA';
      case ETypePayement.MASTERCARD:
        return 'MASTERCARD';
      default:
        return '';
    }
  }

  const textStatusTransaction = (status: string) => {
    switch (status) {
      case EStatus.PENDING:
        return 'En attente';
      case EStatus.VALIDATED:
        return 'Validé';
      case EStatus.CANCELED:
        return 'Rejeter';
      default:
        return '';
    }
  }

  const formatDate = (dateString: string) => {
    const date = parseISO(dateString);
    return format(date, 'dd-MM-yyyy');
  }

  return (
    <tr>
      <td className={`${text}`}>
        {formatDate(transaction.date)}
      </td>
      <td className={`${text}`}> {formatDate(transaction.expiration_date)}</td>
      <td className={`${text}`}>{transaction.subscription.type == 'Film' ? 'Abonnement  Film' : transaction.subscription.type}</td>
      <td className={`${text}`}>{transaction?.movie?.title}</td>
      <td className={`${text}`}>{textTypeTransaction(transaction?.type_payement)}</td>
      <td className={`${text}`}>{textStatusTransaction(transaction.status)}</td>
      <td>
        {
          admin && <>{transaction.status === EStatus.PENDING && (
            <div className="flex">
              <button
                className="border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2 bg-green-500"
                onClick={() => onChangeStatus(EStatus.VALIDATED)}
                aria-label="Valider"
              >
                Valider
              </button>
              <button
                className="me-2 border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2 ms-2 bg-subMain"
                onClick={() => onChangeStatus(EStatus.CANCELED)}
                aria-label="Rejeter"
              >
                Rejeter
              </button>
            </div>
          )} {transaction.status === EStatus.CANCELED && (
            <button
              className="border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2 bg-green-500"
              onClick={() => onChangeStatus(EStatus.VALIDATED)}
              aria-label="Valider"
            >
              Valider
            </button>
          )}</>
        }
      </td>

    </tr>
  );
}

export default RowsTransaction;
