// src/api/axiosInstance.ts
import axios from 'axios';
import { localStorageService } from '../shared/utils/localStorage';

// Créer une instance Axios
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api` || 'http://localhost:3001', // URL de base de l'API
  headers: {
    'Content-Type': 'application/json',
  },
});

// Intercepteur pour ajouter le token à chaque requête
axiosInstance.interceptors.request.use(
  (config) => {
    const user: any = localStorageService.getItem('user'); // Récupérer l'utilisateur stocké
    if (user?.access_token) {
      config.headers['Authorization'] = `Bearer ${user.access_token}`; // Ajouter le token à l'en-tête Authorization
    }
    return config;
  },
  (error) => {
    // Gestion des erreurs de requête
    return Promise.reject(error);
  }
);

// Intercepteur pour gérer les réponses
axiosInstance.interceptors.response.use(
  (response) => {
    // Toute réponse réussie peut être gérée ici
    return response;
  },
  (error) => {
    // Gérer les erreurs de réponse ici
    if (error.response) {
      const { status } = error.response;

      if (status === 401) {
        // Si non autorisé, on peut rediriger vers la page de connexion
        // window.location.href = '/login'; // Décommenter pour rediriger automatiquement
        console.error('Non autorisé, redirection vers la page de connexion.');
      }

      // On peut également retourner un message d'erreur personnalisé
      return Promise.reject(error);
    }

    // Si l'erreur n'a pas de réponse (erreur réseau par exemple)
    return Promise.reject(error);
  }
);

export default axiosInstance;
