export default function createArrayFromInput(input: string): string[] {
  if (input.includes(',')) {
    return input.split(',');
  } else {
    return [input];
  }
}

export function strToNum(str: string): number {
  // Remove the square brackets and the quotation marks from the string.
  const numStr: any = str.slice(4, -4);

  // Check if the string can be parsed as a number.
  if (!isNaN(numStr)) {
    // Convert the string to a number using the Number() constructor.
    return Number(numStr);
  } else {
    return NaN;
  }
}

export function ChooseEmission(id: number) {
  if (id == 1) {
    return 'À la bonne heure';
  } else if (id == 2) {
    return 'Dagolife';
  } else if (id == 3) {
    return 'Fantsy bobaka';
  } else if (id == 4) {
    return 'Manavatsava';
  } else if (id == 5) {
    return 'Starvan';
  } else {
    return 'Starvan live';
  }
}

export function parseArrayFromString(inputString: string): string[] {
  try {
    // Supprimer les caractères non nécessaires du début et de la fin de la chaîne
    const cleanedString = inputString.trim().slice(1, -1);

    // Diviser la chaîne en utilisant les virgules comme séparateurs
    const arrayItems = cleanedString.split(',');

    // Supprimer les espaces et les guillemets inutiles autour de chaque élément
    const cleanedArray = arrayItems.map((item) => item.trim().replace(/"/g, ''));

    return cleanedArray;
  } catch (error) {
    console.error('Erreur lors de la conversion de la chaîne en tableau :', error);
    return [];
  }
}

export function stringInArrayIgnoreCase(inputString: string, inputArray: string[]): boolean {
  const lowerCaseInput = inputString.toLowerCase().trim();
  for (const item of inputArray) {
    if (lowerCaseInput === item.toLowerCase().trim()) {
      return true;
    }
  }
  return false;
}

export const convertArrayToString = (array: string[]): string => {
  return array.join(', '); // Concatenate array elements with a comma and space
};

export function convertIndexesToNames(indexArray: string[]): string {
  const nameMap: { [key: string]: string } = {
    '1': 'Starvan',
    '2': 'Fantsy bobaka',
    '3': 'Dagolife',
    '4': 'Manavatsava'
  };

  const names: string[] = [];

  for (const indexStr of indexArray) {
    const name = nameMap[indexStr];
    if (name) {
      names.push(name);
    }
  }

  return names.join(',');
}

export function convertNamesToIndexes(inputString: string): string[] {
  const nameToIndex: { [key: string]: string } = {
    starvan: '1',
    'fantsy bobaka': '2',
    dagolife: '3',
    manavatsava: '4'
  };

  const inputNames = inputString.toLowerCase().split(',');
  const outputIndexes: string[] = [];

  for (const name of inputNames) {
    const index = nameToIndex[name];
    if (index) {
      outputIndexes.push(index);
    } else {
      nameToIndex[name] = String(Object.keys(nameToIndex).length + 1);
      outputIndexes.push(nameToIndex[name]);
    }
  }

  return outputIndexes;
}
