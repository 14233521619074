import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../Store/Store";
import { IMovie, getMovieLists, setMovieList } from "../../../Toolkit/MovieAction";
import RowsMovie from "./RowsMovie";

const Head = 'text-xs text-left text-main font-semibold px-6 py-2 uppercase';
const Text = 'text-sm text-left leading-6 whitespace-nowrap px-5 py-3';

const TableMovie: React.FC = () => {
  /**
   * REDUX
   */
  const dispatch = useDispatch<AppDispatch>();
  const movieLists: IMovie[] = useSelector((state: any) => state.movie.movieLists);

  /**
   * LYFECYCLE
   */
  useEffect(() => {
    dispatch(getMovieLists())
  }, [])

  /**
   * FUNCTION
   */
  const handleDeleteMovie = (id: number) => {
    dispatch(setMovieList(movieLists.filter((m: IMovie) => m.id !== id)))
  }



  return (
    <div className="overflow-x-auto relative w-full h-[400px] overflow-y-auto">
      <table className="w-full table-auto border border-border divide-y divide-border">
        <thead>
          <tr className="bg-dryGray">
            <th scope="col" className={`${Head}`}>
              Titre
            </th>
            <th scope="col" className={`${Head}`} style={{ width: '20%' }}>
              Description
            </th>
            <th scope="col" className={`${Head}`} style={{ width: '20%' }}>
              Catégorie
            </th>
            <th scope="col" className={`${Head}`} style={{ width: '20%' }}>
              Genre
            </th>
            <th scope="col" className={`${Head}`}>
              Réalisateur
            </th>
            <th scope="col" className={`${Head}`}>
              Producteur
            </th>
            <th scope="col" className={`${Head}`}>
              Price
            </th>
            <th scope="col" className={`${Head}`}>
              Gratuis
            </th>
            <th scope="col" className={`${Head}`}>
              Date de sortie
            </th>
            <th scope="col" className={`${Head}`}>
              Nombre de vue
            </th>
            <th scope="col" className={`${Head}`}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-main divide-y divide-gray-800">
          {movieLists && movieLists?.map((movie: IMovie, i: number) => (
            <RowsMovie movie={movie} key={i} admin={true} handleDeleteMovie={handleDeleteMovie} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableMovie;
