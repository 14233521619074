import { FiLogIn } from 'react-icons/fi';
import { Link } from 'react-router-dom';

function Promos() {
  return (
    <>
      <div className="my-20 py-10 md:px-20 px-8 bg-main">
        <div className="lg:grid lg:grid-cols-2 lg:gap-10 items-center">
          <div className="flex lg:gap-10 gap-6 flex-col">
            <h1 className="xl:tex-3xl text-xl  font-sans font-medium leading-loose">ZA MANDRESY APP</h1>
            <p className="text-text text-sm xl-text-base leading-6 xl:leading-8">
              L'avenir du divertissement malgache est entre vos mains. Téléchargez Za Mandresy App dès maintenant pour
              accéder à nos émissions, trailers, clips, événements culturels et pour célébrer la culture malgache avec
              notre communauté en ligne
            </p>
            <div className="flex-rows gap-6 text-md uppercase tracking-widset bg-subMain p-3 rounded-md download-apk-mobile">
              <Link to="/apk/zamandresy.apk" target="_blank" className="flex justify-between gap-2">
                TELECHARGEZ ICI
                <FiLogIn className="w-6 h-6" />
              </Link>
            </div>
          </div>
          <div className="flex-rows  gap-4 lg:w-96 md:w-72 max-w-md xs:mt-4">
            <img src="/assets/images/Accueil.png" alt="Mobile app" className="w-full h-64 object-contain" />
            <img src="/assets/images/lecture.png" alt="Mobile app" className="w-full h-64 object-contain" />
            <img src="/assets/images/Emissions.png" alt="Mobile app" className="w-full h-64 object-contain xs:hidden" />
            <img src="/assets/images/Login.png" alt="Mobile app" className="w-full h-64 object-contain xs:hidden" />
          </div>
        </div>
      </div>
    </>
  );
}
export default Promos;
