import React, { useState, useEffect } from 'react';
import Sidebar from '../../Components/Sidebar';
import CreateQuiz from '../../Components/Modals/Create/CreateQuiz';
import AllCards from './Components/AllCards';
import { HiPlusCircle } from 'react-icons/hi';
import TableQuiz from '../../Components/TableQuiz';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { GetAllQuiz, setMessageNull } from '../../../../Toolkit/QuizAction';
import { AppDispatch } from '../../../../Store/Store';

function Quiz() {
  const [openModal, setOpenModal] = useState(false);
  const message = useSelector((state: any) => state.quiz.message);
  const dispatch = useDispatch<AppDispatch>();
  const quizzes = useSelector((state: any) => state.quiz.lists);
  useEffect(() => {
    dispatch(GetAllQuiz());
  }, [message]);
  return (
    <>
      <Sidebar>
        <CreateQuiz modalOpen={openModal} setModalOpen={setOpenModal} />
        <div className="flex flex-col gap-6">
          <div className="flex-btn gap-2">
            <h2 className="text-xl font-bold">Liste des quiz</h2>
            <button
              onClick={() => setOpenModal(true)}
              className="bg-main font-medium  hover:bg-subMain border border-subMain text-white py-3 px-6 rounded flex gap-2 items-center"
            >
              <HiPlusCircle /> Ajouter
            </button>
          </div>
          <TableQuiz data={quizzes} />
        </div>
      </Sidebar>
      {message && (
        <div
          className="fixed z-[80] left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{message}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(setMessageNull())} />
          </div>
        </div>
      )}
    </>
  );
}

export default Quiz;
