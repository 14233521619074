import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { backendURL } from "../Config/baseUrl";
import { IMovie } from "./MovieAction";


export const getSubscriptionLists = createAsyncThunk('get/subcriptions', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/subscription`, config);
    return data.data;
  } catch (error: any) {
    return error.message;
  }
});

/**
 * CREATE TRANSACTION
 */
export const createTransaction = async (newTransaction: any) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.post(`${backendURL}/transaction`, newTransaction, config);
    return data.data;
  } catch (error: any) {
    return error.message;
  }
};

/**
 * get  TRANSACTION by id user
 */
export const getTransactionById = createAsyncThunk('get/transactionById', async (id_user: string) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/transaction/${id_user}`, config);
    return data.data;
  } catch (error: any) {
    return error.message;
  }
})

/**
 * get all  TRANSACTION
 */
export const getAllTransaction = createAsyncThunk('get/allTransaction', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/transaction`, config);
    return data.data;
  } catch (error: any) {
    return error.message;
  }
})

/**
 * UPDATE status transaction
 */
export const updateTransaction = createAsyncThunk('update/transaction', async (dataUpdated: { id: number, status: EStatus }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.put(`${backendURL}/transaction/${dataUpdated.id}/status`, { status: dataUpdated.status }, config);
    return data.data;
  } catch (error: any) {
    return error.message;
  }
})



/**
 * INTERFACE
 */
interface ISubscriptionTransactionState {
  loading: boolean;
  error: string | null;
  message: string | null;
  subscriptionList: ISubscription[];
  transactionList: ITransaction[];
  createdTransaction: ITransaction | null;
  transactionForUser: ITransaction[];
  updatedTransaction: ITransaction | null;
}

export interface ISubscription {
  id: number;
  type: string;
  price: number;
  duration: string
}

export interface ITransaction {
  id: number;
  date: string;
  expiration_date: string;
  amount: number;
  status: EStatus,
  num_telephone: string;
  type_payement: ETypePayement;
  subscription: ISubscription;
  movie: IMovie;
}


export enum EStatus {
  PENDING = 'pending',
  VALIDATED = 'validated',
  CANCELED = 'canceled'
}

export enum ETypePayement {
  MVOLA = 'mvola',
  ORANGE_MONEY = 'orange_money',
  VISA = 'visa',
  MASTERCARD = 'mastercard'
}


const initialState: ISubscriptionTransactionState = {
  loading: false,
  error: null,
  message: null,
  subscriptionList: [],
  transactionList: [],
  createdTransaction: null,
  transactionForUser: [],
  updatedTransaction: null
};

const subscriptionTransactionSlice = createSlice({
  name: 'movies',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    },
    setTransactionList: (state, action) => {
      state.transactionList = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionLists.pending, (state: ISubscriptionTransactionState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSubscriptionLists.fulfilled, (state: ISubscriptionTransactionState, { payload }: any) => {
      state.loading = false;
      state.subscriptionList = payload;
    });
    builder.addCase(getSubscriptionLists.rejected, (state: ISubscriptionTransactionState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });


    builder.addCase(getTransactionById.pending, (state: ISubscriptionTransactionState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getTransactionById.fulfilled, (state: ISubscriptionTransactionState, { payload }: any) => {
      state.loading = false;
      state.transactionForUser = payload;
    });
    builder.addCase(getTransactionById.rejected, (state: ISubscriptionTransactionState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });


    builder.addCase(getAllTransaction.pending, (state: ISubscriptionTransactionState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllTransaction.fulfilled, (state: ISubscriptionTransactionState, { payload }: any) => {
      state.loading = false;
      state.transactionList = payload;
    });
    builder.addCase(getAllTransaction.rejected, (state: ISubscriptionTransactionState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(updateTransaction.pending, (state: ISubscriptionTransactionState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateTransaction.fulfilled, (state: ISubscriptionTransactionState, { payload }: any) => {
      state.loading = false;
      state.updatedTransaction = payload;
    });
    builder.addCase(updateTransaction.rejected, (state: ISubscriptionTransactionState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
  }
})

export const { seterrornull, setMessageNull, setTransactionList } = subscriptionTransactionSlice.actions;
export default subscriptionTransactionSlice.reducer;
