import { useEffect } from "react";
import { Navigate, Outlet } from 'react-router-dom';
import { removeExpiredVideos } from "../shared/indexBb/indexedDBHelper";

const ClientRoutes = () => {
  const token = localStorage.getItem('clientToken') ? localStorage.getItem('clientToken') : null;
  const role = localStorage.getItem('role') ? localStorage.getItem('role') : null;

  function isAdmin(token: string | null, client: string | null): boolean {
    return !!token && client === 'USER';
  }


  useEffect(() => {
    handleRemoveMovieExpired()
  }, [])

  const handleRemoveMovieExpired = async () => {
    // Supprimer les video expirer
    await removeExpiredVideos();
  }


  if (!token) {
    return (
      <>
        <Navigate to="/login" />
      </>
    );
  } else {
    return isAdmin(token, role) ? <Outlet /> : <Navigate to="/login" />;
  }
};

export default ClientRoutes;
