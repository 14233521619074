import React, { useEffect, useState } from 'react';
import { FaSearch } from "react-icons/fa";
import { CirclesWithBar } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/Store";
import { ICategoryMovie, IGenreMovie, IMovie, filterMovieByCategoryAndGenre, getCategoryLists, getGenreList } from "../../../../Toolkit/MovieAction";
import Sidebar from "../../../Admin/Components/Sidebar";
import MovieItems from "./components/MovieItems";

const MoviesClient: React.FC = () => {
  /**
   * REDUX
   */
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: any) => state.movie.loading);
  const movies: IMovie[] = useSelector((state: any) => state.movie.movieLists);
  const categorys: ICategoryMovie[] = useSelector((state: any) => state.movie.categoryLists);
  const genres: IGenreMovie[] = useSelector((state: any) => state.movie.genreList)

  /**
   * VARIABLE
   */
  const [categorySelected, setCategorySelected] = useState<ICategoryMovie>()
  const [idGenreSelected, setIdGenreSelected] = useState<number | null>(null)

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    dispatch(getCategoryLists());
    dispatch(getGenreList());
  }, [])


  useEffect(() => {
    if (categorySelected) {
      dispatch(filterMovieByCategoryAndGenre({ category: categorySelected?.id, genre: idGenreSelected }));
    }
  }, [categorySelected, idGenreSelected])

  useEffect(() => {
    if (categorys) {
      setCategorySelected(categorys[0])
    }
  }, [categorys])

  /**
   * FUNCTION
   */
  const handleChangeCategory = (category: ICategoryMovie) => {
    setCategorySelected(category)
  }

  return (
    <Sidebar>
      <div className="flex flex-col gap-6">
        <div className="flex-btn gap-2">
          <h2 className="text-xl font-bold">Films</h2>
          <div className="col-span-3">
            <form className="w-full text-sm bg-dryGray rounded flex-btn gap-4">
              <button className="bg-subMain w-12 flex-colo h-12 rounded text-white">
                <FaSearch />
              </button>
              <input
                type="text"
                className="font-medium placeholder:text-border text-sm w-11/12 h-12 bg-transparent border-none px-2 text-black"
                placeholder="Rechercher un film"
              />
            </form>
          </div>
        </div>
        <div className=" m-15">
          <div className="flex flex-row gap-6">
            {
              categorys && categorys.length > 0 && categorys?.map((category: ICategoryMovie, index: number) => (
                <div
                  className={`cursor-pointer ${categorySelected?.id == category.id ? 'font-semibold' : ''}`}
                  key={`category_${index}`}
                  onClick={() => handleChangeCategory(category)}>{category.name}</div>
              ))
            }
          </div>


          <div className="w-full grid md:grid-cols-2 gap-6 grid-cols-2">
            <div className="text-sm w-50 col-span-2 mt-4 flex flex-col">
              <label className="text-subMain font-semibold">Genre</label>
              <select
                name="important"
                className="w-3/6 h-10 text-text bg-main border border-border rounded"
                onChange={(event) => {
                  const { value } = event.target
                  setIdGenreSelected(+value)
                }}
              >
                <option value="">
                  Tous
                </option>
                {genres?.map((genre: IGenreMovie, index: number) => (
                  <option key={index} value={genre.id}>
                    {genre.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {!loading &&
            <div className="grid gap-x-8 gap-y-4 grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-2 max-[400px]:grid-cols-1 mt-2">
              {
                movies && movies?.map((movie: IMovie, index: number) => (
                  <MovieItems key={`movie_${index}`} movie={movie} />
                ))
              }
            </div>}
          <div className="flex justify-center m-15 mt-4">
            {loading && (
              <CirclesWithBar
                height="100"
                width="100"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            )}
          </div>
        </div>
      </div>
    </Sidebar>
  );
}

export default MoviesClient;
