import axiosInstance from "../../api/axiosInstance";
import { ClientReg } from "../../Toolkit/authAction";
import { IUser } from "../model/user";

export const authService = {
  register: async (user: Partial<ClientReg>): Promise<Partial<IUser>> => {
    const response = await axiosInstance.post<Partial<IUser>>(
      `/subscribe`,
      user,
    );
    return response.data;
  }
};
