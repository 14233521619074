import React from 'react';
import { CgClose } from "react-icons/cg";

interface SimpleModalProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  children: React.ReactNode;
}

const SimpleModal: React.FC<SimpleModalProps> = ({ modalOpen, setModalOpen, children }) => {
  if (!modalOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
      <div className="absolute right-5 top-5">
        <button
          onClick={() => setModalOpen(false)}
          type="button"
          className="transitions w-10 h-10 flex-colo text-base text-white bg-subMain rounded-full hover:bg-white hover:text-subMain"
        >
          <CgClose />
        </button>
      </div>
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
        <button
          className="absolute top-0 right-0 mt-4 mr-4 text-gray-500 hover:text-gray-700"
          onClick={() => setModalOpen(false)}
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default SimpleModal;
