import React from 'react';
import Sidebar from "../../Components/Sidebar";
import TableTransaction from "./TableTransaction";

const Abonnement: React.FC = () => {
  return (
    <Sidebar>
      <div className="flex flex-col gap-6">
        <div className="flex-btn gap-2">
          <h2 className="text-xl font-bold">Abonnement</h2>
        </div>
        <TableTransaction />
        {/* <CreateMovie modalOpen={openModalCreate} setModalOpen={setModalOpenCreate} /> */}
      </div>
    </Sidebar>
  );
}

export default Abonnement;
