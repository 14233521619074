export const CategoriesData = [
  {
    _id: '4',
    title: 'starvan'
  },
  {
    _id: '1',
    title: 'dagolife'
  },
  {
    _id: '3',
    title: 'manavatsava'
  },
  {
    _id: '5',
    title: 'starvanlive'
  },
  {
    _id: '2',
    title: 'fantsybobaka'
  }
];

export const MusicCategories = [
  'Salegy',
  'Reggae malgache',
  'Malesa',
  'Kilalaky',
  'Hiragasy',
  'Baoenjy',
  'Tsapika',
  'Kawitry',
  'Rabòdy',
  'Antsa',
  'Begogo',
  'Kilalao',
  'Tamatave Rock',
  'Marovany',
  'Savika',
  'Tso-drano',
  'Ségasèga',
  'Ba-gasy',
  'Vakodrazana',
  'Sodo',
  'Valse malgache',
  'Fitiavana',
  'Tantsaha',
  'Hira gasy',
  'Beguine',
  'Wazimba',
  'Betioky',
  'Kabary',
  'Kaiamba',
  'Hain-teny',
  'Pop',
  'Rock',
  'Hip-hop',
  'R&B (Rhythm and Blues)',
  'Country',
  'Jazz',
  'Blues',
  'Electronic',
  'Reggae',
  'Folk',
  'Classical (Classique)',
  'Alternative',
  'Metal',
  'Punk',
  'Funk',
  'Soul',
  'Dance',
  'Rap',
  'Indie',
  'Gospel',
  'Salsa',
  'Bossa Nova',
  'Techno',
  'House',
  'Disco',
  'Ambient',
  'World (Musique du monde)',
  'Instrumental',
  'New Age',
  'Experimental (Expérimental)'
];

export const RankingCategorie = ['POPULAIRE', 'NOUVEAUTE'];
