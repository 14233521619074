import { useState } from 'react';
import { FaCloudDownloadAlt, FaEdit } from 'react-icons/fa';
import { GoEye } from 'react-icons/go';
import { MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import UpdateStarvan from './Modals/Update/UpdateStarvan';

import React from 'react';
import { backendURLFile } from '../../../Config/baseUrl';
import DeletePost from './Modals/Delete/DeletePost';
import UpdateDago from './Modals/Update/UpdateDago';
import UpdateFantsy from './Modals/Update/UpdateFantsy';
import UpdateManavatsava from './Modals/Update/UpdateManavatsava';
import UpdateStarvanlive from './Modals/Update/UpdateStarvanlive';

export default function Rows({ movie, i, admin, emission }: any) {
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const emissions = [
    {
      name: 'starvan',
      component: <UpdateStarvan modalOpen={openModal} setModalOpen={setOpenModal} movie={movie} />,
      deleteComponent: <DeletePost modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} movie={movie} />
    },
    {
      name: 'starvanlive',
      component: <UpdateStarvanlive modalOpen={openModal} setModalOpen={setOpenModal} movie={movie} />,
      deleteComponent: <DeletePost modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} movie={movie} />
    },
    {
      name: 'dagolife',
      component: <UpdateDago modalOpen={openModal} setModalOpen={setOpenModal} movie={movie} />,
      deleteComponent: <DeletePost modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} movie={movie} />
    },
    {
      name: 'fantsy',
      component: <UpdateFantsy modalOpen={openModal} setModalOpen={setOpenModal} movie={movie} />,
      deleteComponent: <DeletePost modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} movie={movie} />
    },
    {
      name: 'manavatsava',
      component: <UpdateManavatsava modalOpen={openModal} setModalOpen={setOpenModal} movie={movie} />,
      deleteComponent: <DeletePost modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} movie={movie} />
    }
  ];
  const SelectedEmission = emissions.filter((em) => em.name == emission);
  const Head = 'text-xs text-left text-main font-semibold px-6 py-2 uppercase';
  const Text = 'text-sm text-left leading-6 whitespace-nowrap px-5 py-3';
  return (
    <>
      {SelectedEmission.map((Selected, index) => {
        return (
          <React.Fragment key={index}>
            {Selected.component}
            {Selected.deleteComponent}
          </React.Fragment>
        );
      })}
      <tr key={i}>
        <td className={`${Text}`}>
          <div className="w-12 p-1 bg-dry border border-border h-12 rounded overflow-hidden">
            <img className="h-full w-full  object-cover" src={`${backendURLFile}/${movie.cover}`} alt="movie" />
          </div>
        </td>
        <td className={`${Text}`}>{movie.legend}</td>
        <td className={`${Text}`}>{movie.created_at}</td>
        <td className={`${Text} float-left flex-rows gap-2`}>
          {admin ? (
            <>
              <button
                onClick={() => setOpenModal(true)}
                className="border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2"
              >
                Edit <FaEdit className="text-green-500" />
              </button>
              <button
                onClick={() => setOpenModalDelete(true)}
                className="bg-subMain text-white rounded flex-colo w-7 h-7"
              >
                <MdDelete />
              </button>
            </>
          ) : (
            <>
              <button className="border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2">
                Download <FaCloudDownloadAlt className="text-green-500" />
              </button>
              <Link to={`/movies/${movie?.name}`}>
                <button className="bg-subMain text-white rounded flex-colo w-6 h-6">
                  <GoEye />
                </button>
              </Link>
            </>
          )}
        </td>
      </tr>
    </>
  );
}
