import { yupResolver } from '@hookform/resolvers/yup';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsImage } from 'react-icons/bs';
import { MdUpload } from 'react-icons/md';
import { CirclesWithBar } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { backendURLFile } from '../../../../../Config/baseUrl';
import { AppDispatch } from '../../../../../Store/Store';
import { AnimatorUpdate } from '../../../../../Toolkit/AnimatorAction';
import { GetAllemissions, emission } from '../../../../../Toolkit/EmissionAction';
import MainModal from '../MainModal';

const schema = yup
  .object({
    name: yup.string().required('Le champ nom est obligatoire'),
    about: yup.string().required('Le champ profil est obligatoire'),
    profilePicture: yup.mixed().required('Vous devez choisir un photo'),
    contact: yup.string().required('Le champ contact est obligatoire'),
    facebook_username: yup.string().required()
  })
  .required();
type FormData = yup.InferType<typeof schema>;

function UpdateAnimator({ modalOpen, setModalOpen, animator }: any) {
  const emissions: emission[] = useSelector((state: any) => state.emission.lists);
  const [selectedOptionId, setSelectedOptionId] = useState<string[]>([]);
  const [selectedOptionObject, setSelectedOptionObject] = useState<emission[]>([]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.currentTarget.value;

    if (!selectedOptionId.includes(selectedValue)) {
      const selectedEmission = emissions.find((emission) => emission.id?.toString() === selectedValue);
      setSelectedOptionId([...selectedOptionId, selectedValue]);

      if (selectedEmission) {
        setSelectedOptionObject([...selectedOptionObject, selectedEmission]);
      }
    }
  };

  const handleRemoveOption = (option: string) => {
    const updatedOptions = selectedOptionId.filter((value) => value !== option);
    const updatedOptionObject = selectedOptionObject.filter((value) => value.id?.toString() !== option);
    setSelectedOptionId(updatedOptions);
    setSelectedOptionObject(updatedOptionObject);
  };

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: any) => state.animator.loading);
  const [link, setLink] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setLink(URL.createObjectURL(e.target.files[0]));
    }
  };
  const HandlePostAnimator = async (data: FormData) => {
    try {
      const photo = (data.profilePicture as any[])[0];
      const formData = new FormData();
      if (photo) {
        formData.append('profilePicture', photo);
      }
      const { name, about, contact, facebook_username, ...rest } = data;
      const tvShows = selectedOptionId;
      const animat = {
        name,
        about,
        contact,
        facebook_username,
        tvShows: tvShows,
        ...Object.fromEntries(formData.entries())
      };
      const id = animator.id;
      dispatch(AnimatorUpdate({ animat, id })).then((res) => {
        setModalOpen(false);
      });
    } catch (error) { }
  };
  useEffect(() => {
    dispatch(GetAllemissions());
  }, [dispatch]);

  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-block sm:w-4/5 md:w-4/5 lg:w-4/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl">
        <div className="flex flex-col gap-6 relative">
          {loading && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
              <CirclesWithBar
                height="100"
                width="100"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            </div>
          )}
          <form className="w-full" onSubmit={handleSubmit(HandlePostAnimator)}>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Nom de votre animateur</label>
                <input
                  defaultValue={animator.name}
                  {...register('name')}
                  name="name"
                  type="text"
                  placeholder="Mandresy ..."
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.name?.message}</p>
              </div>
              <div className="text-sm w-full">
                <label className="text-border font-semibold">À propos de votre animateur</label>
                <input
                  defaultValue={animator.about}
                  {...register('about')}
                  name="about"
                  type="text"
                  placeholder="Le meilleur animateur de tout le temps"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.about?.message}</p>
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Contact de votre animateur</label>
                <input
                  defaultValue={animator.contact}
                  {...register('contact')}
                  name="contact"
                  type="text"
                  placeholder="034 32 788 99"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.contact?.message}</p>
              </div>
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Nom sur facebook de votre animateur</label>
                <input
                  required
                  defaultValue={animator.facebook_username}
                  {...register('facebook_username')}
                  name="facebook_username"
                  type="text"
                  placeholder="Votre nom sur facebook"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.facebook_username?.message}</p>
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full">
                <select
                  defaultValue={animator.tv_shows}
                  onChange={handleSelectChange}
                  name="important"
                  className="w-full  text-sm h-16  mt-7 p-5 border border-border rounded text-white bg-dry"
                >
                  {emissions.map((option: any, i: number) => (
                    <option key={i} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <div className="grid grid-cols-3 gap-2 mt-3">
                  {selectedOptionObject.map((option, index) => (
                    <div
                      key={index}
                      className="text-white bg-subMain rounded-2xl col-span-1 md:col-span-2 lg:col-span-1 px-1"
                    >
                      <div className="justify-between flex items-center">
                        <p>{option.name}</p>
                        <span
                          onClick={() => handleRemoveOption(option.id?.toString() ?? '')}
                          className="rounded-full cursor-pointer"
                        >
                          <AiOutlineCloseCircle />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-border font-semibold text-sm">Photo du teaser</p>
                <div className="w-full">
                  <label className="flex justify-center h-16  transition text-subMain bg-dry border-2 px-6 py-4  border-gray-300 border-dashed  rounded-md  appearance-none cursor-pointer hover:border-gray-main focus:outline-none">
                    <span className="flex items-center w-full">
                      <BsImage className="text-subMain  h-8 w-8" />
                      <span className="font-medium text-white text-[12px] w-full">
                        Ajouter le photo de votre teaser
                      </span>
                    </span>
                    <input
                      {...register('profilePicture', {
                        onChange: handleFileChange
                      })}
                      type="file"
                      name="image"
                      accept="image/*"
                      className="opacity-0"
                    />
                  </label>
                </div>
                <div className="w-16 h-16 p-2 bg-main border border-border rounded">
                  <img
                    src={link ? link : `${backendURLFile}/${animator.profilePicturePath}`}
                    alt=""
                    className="w-full h-full object-cover rounded"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center my-4">
              <button
                type="submit"
                className="bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6"
              >
                <MdUpload /> Mettre à jour l'animateuur
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainModal>
  );
}

export default UpdateAnimator;
