import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaPlay } from 'react-icons/fa';
import { FiLogIn } from 'react-icons/fi';
import { IoIosCloseCircleOutline, IoIosEye, IoIosEyeOff } from 'react-icons/io';
import { RxEnter } from 'react-icons/rx';
import { CirclesWithBar } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { backendURLFile } from '../../../Config/baseUrl';
import { AppDispatch } from '../../../Store/Store';
import { LoginClient, seterrornull } from '../../../Toolkit/authAction';
import MainModal from '../../Admin/Components/Modals/MainModal';
import FlexMovieItem from '../Components/FlexMovieItem';

const schema = yup
  .object({
    pseudonyme: yup.string().required(),
    password: yup.string().required()
  })
  .required();
type FormData = yup.InferType<typeof schema>;

function MovieInfo({ movie }: any) {
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const error = useSelector((state: any) => state.auth.error);
  const loading = useSelector((state: any) => state.auth.loading);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const checkValidation = () => {
    const adminToken = localStorage.getItem('adminToken');
    const clientToken = localStorage.getItem('clientToken');
    if (adminToken || clientToken) {
      navigate(`/watch/${movie?.id}`);
    } else {
      setOpenModal(!openModal);
    }
  };

  const onSubmit = async (data: FormData) => {
    try {
      await dispatch(LoginClient(data)).then((res) => {
        navigate(`/watch/${movie?.id}`);
      });
    } catch (error) {
      return error;
    }
  };
  const hover = 'hover:text-subMain transitions text-white';
  const Hover = ({ isActive }: any) => (isActive ? 'text-subMain' : hover);

  return (
    <>
      <div className="w-full xl:h-screen relative text-white">
        <img
          src={`${backendURLFile}/${movie?.cover}`}
          alt={movie.legend}
          className="w-full hidden xl:inline-block h-full object-cover"
        />
        <div className="xl:bg-main bg-dry flex-colo xl:bg-opacity-90 xl:absolute top-0 left-0 right-0 bottom-0">
          <div className="container px-3 mx-auto 2xl:px-32 xl:grid grid-cols-3 flex-colo py-10 lg:py-20 gap-8">
            <div className="xl:col-span-1 w-full xl:order-none order-last h-header bg-dry border border-gray-800 rounded-lg overflow-hidden">
              <img
                src={`${backendURLFile}/${movie?.cover}`}
                alt={movie.legend}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="col-span-2 md:grid grid-cols-5 gap-4 items-center">
              <div className="col-span-3 flex flex-col gap-10">
                <h1 className="xl:text-4xl capitalze font-sans text-2xl font-bold">{movie.legend}</h1>
                <div className="flex items-center gap-4 font-medium text-dryGray">
                  <div className="flex-colo bg-subMain text-xs px-2 py-1">HD 4K</div>
                  <FlexMovieItem movie={movie && movie} />
                </div>
                <p className="text-text text-sm leading-7">{movie?.created_at}</p>
                <div className="grid sm:grid-cols-5 grid-cols-3 gap-4 p-6 bg-main border border-gray-800 rounded-lg">
                  <div className="col-span-1 flex-colo border-r border-border">
                    <button className="w-10 h-10 flex-colo rounded-lg bg-white bg-opacity-20"></button>
                  </div>
                  <div className="col-span-2 flex-colo font-medium text-sm">
                    <p>
                      Langues: <span className="ml-2 truncate">MALAGASY</span>
                    </p>
                  </div>
                  <div className="sm:col-span-2 col-span-3 flex justify-end font-medium text-sm">
                    <button
                      onClick={checkValidation}
                      className="bg-dry py-4 hover:bg-subMain transition border-2 border-subMain rounded-full flex-rows gap-4 w-full sm:py-3"
                    >
                      <FaPlay className="w-3 h-3" /> Regarder
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-span-2 md:mt-0 mt-2 flex justify-end ">
                <button className="md:w-1/4 w-full relative flex-colo bg-subMain hover:bg-transparent border-2 border-subMain transition md:h-64 h-20 rounded font-medium">
                  <div className="flex-rows gap-6 text-md uppercase tracking-widset absolute md:rotate-90">
                    <Link
                      to="https://drive.google.com/file/d/1tllzJ-M3bk2F-30YlpTMs6sIiadLAfg5/view?fbclid=IwAR180vfSnGZpo5NNbBNXeAHmMqVCcXRDp2Rh2gPEt8PxnKhWOjPUXhBeU8E"
                      className="flex justify-between gap-2"
                    >
                      L'application
                      <FiLogIn className="w-6 h-6" />
                    </Link>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {openModal && (
          <MainModal modalOpen={openModal} setModalOpen={setOpenModal}>
            <div className="opacity-90 inline-block lg:w-2/6 w-11/12 h-96 max-sm:h-[420px] p-2 sticky top-24 overflow-y-auto border border-border bg-main text-white rounded-2xl">
              <div className="relative w-full gap-8 flex-col p-6 bg-dry rounded-lg border border-border">
                <img src="/assets/images/logo.png" alt="logo" className="w-full h-12 object-contain" />
                <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                  <div className="text-sm w-full">
                    <label className="text-white font-semibold">Pseudonyme</label>
                    <input
                      {...register('pseudonyme')}
                      name="pseudonyme"
                      required
                      type="text"
                      placeholder="Saisissez ici votre pseudo"
                      className="w-full text-sm  mt-2 p-5 border h-4 border-border rounded-md text-text bg-main"
                    />
                  </div>
                  <div className="text-sm w-full mt-4 relative">
                    <label className="text-white font-semibold">Password</label>
                    <div className="relative">
                      <input
                        {...register('password')}
                        name="password"
                        required
                        type={showPassword ? 'text' : 'password'} // Toggle type based on state
                        placeholder="Saisissez ici votre mot de passe"
                        className="w-full text-sm mt-2 p-5 border h-4 border-border rounded-md text-text bg-main"
                      />
                      <div
                        className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <IoIosEyeOff /> : <IoIosEye />}
                      </div>
                    </div>
                  </div>
                  <div className="text-sm w-full flex justify-center">
                    {loading ? (
                      <div className="mt-4">
                        <CirclesWithBar
                          height="50"
                          width="50"
                          color="#E90064"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          outerCircleColor=""
                          innerCircleColor=""
                          barColor=""
                          ariaLabel="circles-with-bar-loading"
                        />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="mt-4 bg-subMain h-12 transitions hover:bg-main flex-rows gap-4 text-white p-4 rounded-lg w-full cursor-pointer"
                      >
                        <RxEnter /> Connecter
                      </button>
                    )}
                  </div>
                </form>
                <p className="text-center text-border gap-y-2">Vous avez oublié votre mot de passe ? </p>
                <div className="text-center text-border space-x-8 flex justify-between">
                  <Link to="/inscription" className="text-dryGray font-semibold ml-2 cursor-pointer">
                    S'Inscrire
                  </Link>
                  <Link to="/contact-us" className="text-dryGray font-semibold ml-2 cursor-pointer">
                    Contacter l'admin
                  </Link>
                </div>
              </div>
              {error && (
                <div
                  className="absolute left-1 bottom-1 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
                  role="alert"
                >
                  <div className="text-[12px] flex gap-8 items-center">
                    <p className="text-gray-100">
                      Une erreur a été survenu lors de votre connexion,verifiez vos données
                    </p>
                  </div>
                  <div className="text-white cursor-pointer">
                    <IoIosCloseCircleOutline onClick={() => dispatch(seterrornull())} />
                  </div>
                </div>
              )}
            </div>
          </MainModal>
        )}
      </div>
    </>
  );
}

export default MovieInfo;
