import React, { ChangeEvent, FormEvent } from 'react';
import Layout from '../Layout/Layout';
import Head from '../Components/Head';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { useState } from 'react';
import { CirclesWithBar } from 'react-loader-spinner';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import Flyers from '../Components/flyers';

interface Formule {
  nom: string;
  email: string;
  message: string;
}

function AboutUs() {
  const [formData, setFormData] = useState<Formule>({
    nom: 'Anonyme',
    email: 'Anonyme',
    message: ''
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    // Replace these values with your Email.js template ID and user ID
    const serviceID = 'service_ggkxyy4';
    const templateID = 'template_f018z9j';
    const userID = 'GstsnLu2Apfp8XBmR';

    // Send the email using Email.js
    emailjs
      .send(serviceID, templateID, formData as any, userID)
      .then((response: EmailJSResponseStatus) => {
        setMessage('Message anvoyer avec succèss');
        setFormData({ ...formData, message: '' });
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  return (
    <Layout>
      <div className="min-h-screen container mx-auto px-2 my-6 overflow-hidden">
        <Head title="Qui sommes nous ?" />
        <div className="xl:py-20 py-10 px-4">
          <div className="grid grid-flow-row xl:grid-cols-2 gap-4 xl:gap-16 items-center">
            <div>
              <h3 className="text-xl lg:text-3xl mb-4 font-semibold">zamandresyprod</h3>
              <div className="mt-3 text-sm leading-8 text-text">
                <p className="">
                  Za mandresy prod Za Mandresy Prod, une entreprise de production audiovisuelle innovante, est à
                  l'origine du succès de l'émission de divertissement Starvan depuis 2018. Face aux défis de la pandémie
                  de COVID-19, nous avons élargi notre expertise aux émissions digitales, dont Fantsy Bobaka, Dago Life
                  et l'édito humoristique Manavatsava. Notre équipe jeune, créative et professionnelle est prte à donner
                  vie à vos idées. Avec Za Mandresy Prod, l'innovation rencontre la passion pour l'audiovisuel;
                </p>
              </div>
              <p className="mt-4 font-bold text-[#fdc500]">Laissez un commentaire</p>
              <form className="w-full" onSubmit={handleSubmit}>
                <div className="relative w-full  gap-6 mt-4">
                  {loading && (
                    <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                      <CirclesWithBar
                        height="40"
                        width="40"
                        color="#E90064"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        outerCircleColor=""
                        innerCircleColor=""
                        barColor=""
                        ariaLabel="circles-with-bar-loading"
                      />
                    </div>
                  )}
                  <div className="text-sm w-full">
                    <input
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      type="text"
                      placeholder="Ecrivez votre commentaire ici ✍️"
                      className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-dry`}
                    />
                  </div>
                  <div className="flex justify-end items-center my-2 mt-8 w-2/6">
                    <button
                      type="submit"
                      className="bg-subMain transitions hover:bg-dry border border-subMain text-white py-2  rounded w-full  flex-rows gap-6"
                    >
                      🏌️‍♀️ Envoyer
                    </button>
                  </div>
                </div>
              </form>
              {message && (
                <div
                  className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
                  role="alert"
                >
                  <div className="text-[12px] flex gap-8 items-center">
                    <p className="text-gray-100">Votre commentaire à ete envoyé avec succèss</p>
                  </div>
                  <div className="text-white cursor-pointer">
                    <IoIosCloseCircleOutline onClick={() => setMessage('')} />
                  </div>
                </div>
              )}
            </div>
            <div className="mt-10 lg:mt-0 max-h-auto">
              <Flyers />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AboutUs;
