// Gallery.js

import { useLocation } from 'react-router-dom';
import { GalleryVideo, GalleryVideoMusic, GalleryVideoShort, GalleryVideoTeaser } from './Galleryvideo';
import Sidebar from '../../../../Admin/Components/Sidebar';

export default function HomeVideo() {
  const location = useLocation();
  const state = location.state;
  const movie = state.movie;
  const data = state.data;
  const title = state.title;
  const type = state.type;
  return (
    <Sidebar>
      {type == 'emission' && <GalleryVideo movie={movie} data={data} title={title} type={type} />}
      {type == 'short' && <GalleryVideoShort movie={movie} data={data} title={title} />}
      {type == 'teaser' && <GalleryVideoTeaser movie={movie} data={data} title={title} />}
      {type == 'music' && <GalleryVideoMusic movie={movie} data={data} title={title} />}
    </Sidebar>
  );
}
