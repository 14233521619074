import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { CirclesWithBar } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../Store/Store';
import { GetAllshort, setMessageNull, seterrornull } from '../../../../Toolkit/ShortAction';
import Sidebar from '../../../Admin/Components/Sidebar';
import Pagination from '../Components/Pagination/Pagination';
import Videosplayer from '../Components/Videos/Videosplayer';

export function ShortClient() {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: any) => state.short.message);
  const error = useSelector((state: any) => state.short.error);
  const shorts = useSelector((state: any) => state.short.lists);
  const loading = useSelector((state: any) => state.short.loading);

  // useState
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setCurrentTableData] = useState<any>([]);

  // Functions
  const dataFilter = (term: string) => {
    let array: any[] = [];
    shorts.filter((item: any) => {
      if (item.post.legend?.toLowerCase().includes(term?.toLowerCase())) {
        array.push(item);
      }
    });
    return array;
  };
  const resultSearch = dataFilter(searchTerm);

  // Lifecycle: useEffect for fetching shorts
  useEffect(() => {
    dispatch(GetAllshort());
  }, []);

  // Constants
  const PageSize = 12;

  // Lifecycle: useEffect for handling pagination
  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTableData(shorts.slice(firstPageIndex, lastPageIndex));
  }, [shorts, currentPage]);


  // fin Pagination
  return (
    <>
      <Sidebar>
        <div className="flex flex-col gap-6">
          <div className="flex-btn gap-2">
            <h2 className="text-xl font-bold">Short</h2>
            <div className="col-span-3">
              <form className="w-full text-sm bg-dryGray rounded flex-btn gap-4">
                <button className="bg-subMain w-12 flex-colo h-12 rounded text-white">
                  <FaSearch />
                </button>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="font-medium placeholder:text-border text-sm w-11/12 h-12 bg-transparent border-none px-2 text-black"
                  placeholder="Rechercher une vidéo"
                />
              </form>
            </div>
          </div>
          {loading ? (<div className="flex justify-center m-15">
            <CirclesWithBar
              height="300"
              width="300"
              color="#E90064"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              outerCircleColor=""
              innerCircleColor=""
              barColor=""
              ariaLabel="circles-with-bar-loading"
            />

          </div>
          ) : <div>
            {shorts && searchTerm == '' && <Videosplayer data={currentTableData} titleVideo="Short" type="short" />}
            {shorts && searchTerm != '' && <Videosplayer data={resultSearch} titleVideo="Short" type="short" />}
          </div>}

          <div>
            <Pagination
              className="flex w-full justify-center"
              currentPage={currentPage}
              totalCount={shorts.length}
              pageSize={PageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
          </div>
        </div>
      </Sidebar>
      {message && (
        <div
          className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{message}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(setMessageNull())} />
          </div>
        </div>
      )}
      {error && (
        <div
          className="fixed left-1 bottom-1 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{error}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(seterrornull())} />
          </div>
        </div>
      )}
    </>
  );
}
