import React, { useEffect } from 'react';
import { FaRegListAlt, FaUser } from 'react-icons/fa';
import Sidebar from '../Components/Sidebar';
import { MdHolidayVillage } from 'react-icons/md';
import Statistique from './Chart';
import { useSelector } from 'react-redux';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../Store/Store.js';
import { setMessageNull } from '../../../Toolkit/authAction';
import { GetAllposts } from '../../../Toolkit/PostAction';
import { GetAllevents } from '../../../Toolkit/EventAction';
import { GetAllAnimators } from '../../../Toolkit/AnimatorAction';

function Dashboard() {
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: any) => state.auth.message);
  const posts = useSelector((state: any) => state.post.lists);
  const totalpost = useSelector((state: any) => state.post.total);
  const dagolife = posts.filter((post: any) => post.tvShowId == 3).length;
  const fantsybobaka = posts.filter((post: any) => post.tvShowId == 2).length;
  const manavatsava = posts.filter((post: any) => post.tvShowId == 4).length;
  const starvan = posts.filter((post: any) => post.tvShowId == 1).length;
  const events = useSelector((state: any) => state.event.lists);
  const animators = useSelector((state: any) => state.animator.lists);
  const datapost = {
    dagolife,
    fantsybobaka,
    manavatsava,
    starvan
  };

  const DashboardData = [
    {
      bg: 'bg-[#1470C6]',
      back: 'bg-gradient-to-r from-[#0090F8] to-[#006BB9]',
      icon: FaRegListAlt,
      title: 'Total de publication',
      total: totalpost
    },
    {
      bg: 'bg-[#5C00B9]',
      back: 'bg-gradient-to-r from-[#5C00B9] to-[#F31F5F]',
      icon: MdHolidayVillage,
      title: "Nombre d'événements",
      total: events.length
    },
    {
      bg: 'bg-[#880000]',
      back: 'bg-gradient-to-r from-[#F80000] to-[#810000]',
      icon: FaUser,
      title: "Nombre d'animateur",
      total: animators.length
    }
  ];
  useEffect(() => {
    dispatch(GetAllposts());
    dispatch(GetAllevents());
    dispatch(GetAllAnimators());
  }, []);
  return (
    <>
      <Sidebar>
        <h2 className="text-xl font-bold">TABLEAU DE BORD</h2>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
          {DashboardData.map((data, index) => (
            <div key={index} className={`p-4 rounded ${data.back} border-border grid grid-cols-4 gap-2`}>
              <div className={`col-span-1 rounded-full h-12 w-12 flex-colo ${data.bg}`}>
                <data.icon />
              </div>
              <div className="col-span-3">
                <h2>{data.title}</h2>
                <p className="mt-2 font-bold">{data.total}</p>
              </div>
            </div>
          ))}
        </div>
        <h3 className="text-md font-medium my-6 text-white">Statistique</h3>
        <Statistique data={datapost} />
      </Sidebar>
      {message && (
        <div
          className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">Connexion avec succèss</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(setMessageNull())} />
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
