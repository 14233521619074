import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { backendURL } from "../Config/baseUrl";


export const payWithMvola = createAsyncThunk('post/payelmentMvola', async (dataToMvola: any) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.post(`${backendURL}/mvola/payment`, dataToMvola, config);

    return data.data;
  } catch (error: any) {
    return error.message;
  }
});



/**
 * INTERFACE
 */
interface ISubscriptionTransactionState {
  loading: boolean;
  error: string | null;
  message: string | null;
  responseMvola: any;
}


const initialState: ISubscriptionTransactionState = {
  loading: false,
  error: null,
  message: null,
  responseMvola: null,
};

const subscriptionTransactionSlice = createSlice({
  name: 'movies',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    },
    setTransactionList: (state, action) => {
      state.responseMvola = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(payWithMvola.pending, (state: any, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(payWithMvola.fulfilled, (state: any, { payload }: any) => {
      state.loading = false;
      state.responseMvola = payload;
    });
    builder.addCase(payWithMvola.rejected, (state: any, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
  }
})

export const { seterrornull, setMessageNull, setTransactionList } = subscriptionTransactionSlice.actions;
export default subscriptionTransactionSlice.reducer;
