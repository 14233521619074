import { useState } from 'react';
import { FaCloudDownloadAlt, FaEdit } from 'react-icons/fa';
import { GoEye } from 'react-icons/go';
import { MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { convertIndexesToNames } from '../../../Config/AlgoInput';
import { backendURLFile } from '../../../Config/baseUrl';
import DeleteAnimator from './Modals/Delete/DeleteAnimator';
import UpdateAnimator from './Modals/Update/UpdateAnimator';

const Head = 'text-xs text-left text-main font-semibold px-6 py-2 uppercase';
const Text = 'text-sm text-left leading-6 whitespace-nowrap px-5 py-3';

export const RowsAnimator = ({ animator, i, admin }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  return (
    <>
      <UpdateAnimator modalOpen={openModal} setModalOpen={setOpenModal} animator={animator} />
      <DeleteAnimator modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} animator={animator} />
      <tr key={i}>
        <td className={`${Text}`}>
          <div className="w-12 p-1 bg-dry border border-border h-12 rounded overflow-hidden">
            <img
              className="h-full w-full  object-cover"
              src={`${backendURLFile}/${animator.profilePicturePath}`}
              alt="movie"
            />
          </div>
        </td>
        <td className={`${Text}`}>{animator.name}</td>
        <td className={`${Text}`}>{animator.contact}</td>
        <td className={`${Text}`}>{convertIndexesToNames(animator.tv_shows)}</td>
        <td className={`${Text} float-left flex-rows gap-2`}>
          {admin ? (
            <>
              <button
                onClick={() => setOpenModal(true)}
                className="border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2"
              >
                Edit <FaEdit className="text-green-500" />
              </button>
              <button
                onClick={() => setOpenModalDelete(true)}
                className="bg-subMain text-white rounded flex-colo w-7 h-7"
              >
                <MdDelete />
              </button>
            </>
          ) : (
            <>
              <button className="border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2">
                Download <FaCloudDownloadAlt className="text-green-500" />
              </button>
              <Link to={`/movies/${animator?.name}`}>
                <button className="bg-subMain text-white rounded flex-colo w-6 h-6">
                  <GoEye />
                </button>
              </Link>
            </>
          )}
        </td>
      </tr>
    </>
  );
};

/*
Soit l'index de chaque nom d'emission  suivant :
1->Starvan, 2->Fantsy bobaka,3->Dagolife,4->Manavatsava

Crées-moi-une fonction en typescript qui prend en parametre un tableau de string(nombre mais en string ) puis convertir le tableau en string separer par un virgule selon l'emission convenable

example :
    input : array["1","4"]
    output : Starvan,Manavatsava

*/
