import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendURL } from '../Config/baseUrl';

interface userState {
  loading: boolean;
  user: any | null;
  message: string | null;
  error: string | null;
  total: number | null;
}

const initialState: userState = {
  loading: false,
  user: null,
  message: null,
  error: null,
  total: null
};

const token = localStorage.getItem('clientToken');
export const currentUser = createAsyncThunk('user', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await axios.get(`${backendURL}/user`, config);

    return data.user;
  } catch (error: any) {
    return error.message;
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(currentUser.pending, (state: userState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(currentUser.fulfilled, (state: userState, { payload }: any) => {
      state.loading = false;
      state.total = payload.length;
      state.user = payload;
    });
    builder.addCase(currentUser.rejected, (state: userState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
  }
});

export const { seterrornull, setMessageNull } = userSlice.actions;
export default userSlice.reducer;
