import { Elements } from "@stripe/react-stripe-js";
import 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { stripePromise } from "./Config/StrypeConfig";
import App from './Router/AppRouter';
import { store } from './Store/Store';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root')!);
const appearance = {
  theme: 'stripe',
};

if (navigator.storage && navigator.storage.estimate) {
  navigator.storage.estimate().then((estimate: any) => {
    const usageGB: any = (estimate.usage / (1024 * 1024 * 1024)).toFixed(2);
    const quotaGB: any = (estimate.quota / (1024 * 1024 * 1024)).toFixed(2);

    const neededGB = 2; // Approximativement 2GB en GB
    const availableGB = quotaGB - usageGB;

    if (availableGB < neededGB) {
      console.warn("Pas assez d'espace pour télécharger une nouvelle vidéo de 2GB");
    } else {
      // Logique pour télécharger la vidéo
    }
  });
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Elements stripe={stripePromise} options={{ appearance: { theme: "stripe" } }}><App /></Elements>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
