import { IMovie } from "../../Toolkit/MovieAction";

export interface VideoData {
  movie: IMovie;
  blob: Blob;
}

let db: IDBDatabase;

export const openDB = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("videoDatabase", 1);

    request.onupgradeneeded = (event) => {
      db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains("videos")) {
        db.createObjectStore("videos", { keyPath: "id" });
      }
    };

    request.onsuccess = (event) => {
      db = (event.target as IDBOpenDBRequest).result;
      resolve(db);
    };

    request.onerror = (event) => {
      reject("Database error: " + (event.target as IDBOpenDBRequest).error);
    };
  });
};

export const saveVideo = async (videoBlob: Blob, movie: IMovie): Promise<void> => {
  if (!db) {
    await openDB();
  }
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["videos"], "readwrite");
    const store = transaction.objectStore("videos");
    const videoData: VideoData = { movie, blob: videoBlob };
    const request = store.add({ id: videoData.movie.id, ...videoData, addedDate: new Date() });

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = (event) => {
      reject("Error storing video: " + (event.target as IDBRequest).error);
    };
  });
};

export const getVideo = (id: number): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["videos"], "readonly");
    const store = transaction.objectStore("videos");
    const request = store.get(id);

    request.onsuccess = (event) => {
      const result = (event.target as IDBRequest).result;
      if (result) {
        resolve(result.blob);
      } else {
        reject("Video not found");
      }
    };

    request.onerror = (event) => {
      reject("Error retrieving video: " + (event.target as IDBRequest).error);
    };
  });
};

export const checkVideoExists = (id: number): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["videos"], "readonly");
    const store = transaction.objectStore("videos");
    const request = store.get(id);

    request.onsuccess = (event) => {
      const result = (event.target as IDBRequest).result;
      if (result) {
        resolve(true);
      } else {
        resolve(false);
      }
    };

    request.onerror = (event) => {
      reject("Error checking video existence: " + (event.target as IDBRequest).error);
    };
  });
};

export const getAllVideos = async (): Promise<VideoData[]> => {
  if (!db) {
    await openDB();
  }
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["videos"], "readonly");
    const store = transaction.objectStore("videos");
    const request = store.getAll();

    request.onsuccess = (event) => {
      const results = (event.target as IDBRequest).result;
      if (results.length > 0) {
        // Transforme la liste des objets vidéo en une liste de blobs
        resolve(results);
      } else {
        // reject("No videos found");
      }
    };

    request.onerror = (event) => {
      reject("Error retrieving videos: " + (event.target as IDBRequest).error);
    };
  });
};


export const downloadAndSaveVideo = async (videoUrl: string, movie: IMovie, token: string, callBack: Function): Promise<void> => {
  try {
    const response = await fetch(videoUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'video/mp4',
        'Authorization': token,
      },
    });

    if (!response.ok) {
      callBack(false)
      return;
    }

    const blob = await response.blob();

    // Assure-toi que cette fonction gère correctement le blob et sauvegarde les données
    await saveVideo(blob, movie);
    callBack(true)
  } catch (error: any) {
    callBack(false)
    throw error; // Propager l'erreur pour la gestion plus haut dans la chaîne des appels
  }
};


export const removeExpiredVideos = async (): Promise<void> => {
  if (!db) {
    await openDB();
  }
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["videos"], "readwrite");
    const store = transaction.objectStore("videos");
    const request = store.openCursor();

    request.onsuccess = (event) => {
      const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
      if (cursor) {
        const video = cursor.value;
        const addedDate = new Date(video.addedDate);
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate.getTime() - addedDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 7) {
          store.delete(cursor.primaryKey);
        }
        cursor.continue();
      } else {
        resolve();
      }
    };

    request.onerror = (event) => {
      reject("Error removing expired videos: " + (event.target as IDBRequest).error);
    };
  });
};
