import { useEffect } from 'react';
import { AiOutlineAccountBook } from 'react-icons/ai';
import { BsFillGridFill } from 'react-icons/bs';
import { FaEuroSign, FaHandshake } from 'react-icons/fa';
import { IoIosEasel } from 'react-icons/io';
import { MdAccessibilityNew, MdCameraRoll, MdGroupWork, MdHelpCenter, MdLogout } from 'react-icons/md';
import { RxAvatar } from 'react-icons/rx';
import { SiAwesomelists } from 'react-icons/si';
import { NavLink, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { backendURLFile } from '../../../Config/baseUrl';
import { AppDispatch } from '../../../Store/Store';
import { GetAllemissions } from '../../../Toolkit/EmissionAction';
import Layout from '../../Client/Layout/Layout';

function Sidebar({ children }: any) {
  const navigate = useNavigate();
  const role = localStorage.getItem('role');
  const logout = () => {
    localStorage.clear();
    setTimeout(() => {
      if (role === 'ADMIN') {
        navigate('/dashboard/login');
      } else {
        navigate('/login');
      }
    }, 1000);
  };
  const emissions = useSelector((state: any) => state.emission.lists);
  const reverse = [...emissions].reverse();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(GetAllemissions());
  }, []);

  const SideLinks = [
    {
      name: 'Evénements',
      link: '/dashboard/event',
      icon: AiOutlineAccountBook
    },
    {
      name: 'Shorts',
      link: '/dashboard/short',
      icon: MdCameraRoll
    },
    {
      name: 'Teasers',
      link: '/dashboard/teaser',
      icon: MdGroupWork
    },
    {
      name: 'Films',
      link: '/dashboard/movies',
      icon: MdGroupWork
    },
    {
      name: 'Emissions',
      link: '/dashboard/emission',
      icon: IoIosEasel
    },
    {
      name: 'Music',
      link: '/dashboard/music',
      icon: IoIosEasel
    },
    {
      name: 'Animateurs',
      link: '/dashboard/animators',
      icon: MdAccessibilityNew
    },
    {
      name: 'Quiz',
      link: '/dashboard/quiz',
      icon: MdHelpCenter
    },
    {
      name: 'Sponsor',
      link: '/dashboard/sponsor',
      icon: FaHandshake
    }, {
      name: 'Abonnement',
      link: '/dashboard/abonnement',
      icon: FaEuroSign
    },
    /*
        {
            name:"Mettre à jour mon profil",
            link:"/dashboard/profile",
            icon:FiSettings
        },
        {
            name:"Changer mon mot de passe",
            link:"/dashboard/password",
            icon:RxCounterClockwiseClock
        },
         */
    {
      name: 'Ajouter un admin',
      link: '/dashboard/register',
      icon: RxAvatar
    }
  ];

  const SideLinksClient = [
    {
      name: 'Evénements',
      link: '/dashboard/client/event',
      icon: AiOutlineAccountBook
    },
    {
      name: 'Shorts',
      link: '/dashboard/client/short',
      icon: MdCameraRoll
    },
    {
      name: 'Teasers',
      link: '/dashboard/client/teaser',
      icon: MdGroupWork
    },
    {
      name: 'Films',
      link: '/dashboard/client/movies',
      icon: MdGroupWork
    },
    {
      name: 'Mon téléchargement',
      link: '/dashboard/client/download',
      icon: MdGroupWork
    },
    {
      name: 'Music',
      link: '/dashboard/client/music',
      icon: IoIosEasel
    },
    {
      name: 'Animateurs',
      link: '/dashboard/client/animators',
      icon: MdAccessibilityNew
    },
    {
      name: 'Us',
      link: '/dashboard/client/nous',
      icon: SiAwesomelists
    },
    {
      name: 'Quiz',
      link: '/dashboard/client/quiz',
      icon: MdHelpCenter
    },
    // {
    //   name: 'Sponsor',
    //   link: '/dashboard/client/sponsor',
    //   icon: FaHandshake
    // }
    /*
        {
            name:"Mettre à jour mon profil",
            link:"/dashboard/profile",
            icon:FiSettings
        },
        {
            name:"Changer mon mot de passe",
            link:"/dashboard/password",
            icon:RxCounterClockwiseClock
        },
         */
  ];
  const active = 'bg-dryGray text-subMain';
  const hover = 'hover:text-white hover:bg-main ';
  const inActive = 'rounded font-medium text-sm transitions flex gap-3 items-center p-4';
  const Hover = ({ isActive }: any) => (isActive ? `${active} ${inActive}` : `${inActive} ${hover}`);
  return (
    <Layout>
      <div className="relative min-h-screen container mx-auto px-1">
        <div className="lg:grid grid-cols-8 gap-4 items-start md:py-12 py-6">
          <div className="col-span-2 sticky bg-dry border border-gray-800 p-4 rounded-md xl:mb-0 mb-5">
            {role === 'ADMIN' ? (
              <NavLink to="/dashboard" className={`${inActive} ${active}`}>
                <BsFillGridFill /> <p>Tableau de bord</p>
              </NavLink>
            ) : (
              <NavLink to="/dashboard/client" className={`${inActive} ${active}`}>
                <BsFillGridFill /> <p>Tableau de bord</p>
              </NavLink>
            )}
            <div className="ml-4">
              <div className="grid grid-cols-12 items-center">
                <span className="col-span-10 flex items-center gap-2 cursor-pointer font-bold py-2">
                  <BsFillGridFill /> <p>Emissions</p>
                </span>
                <div className="col-span-2 cursor-pointer"></div>
              </div>
            </div>
            <div className="mx-5">
              {role == 'ADMIN' &&
                reverse.map((link: any, index: number) => (
                  <NavLink to={`/dashboard/emission/${link.id}`} key={index} className={Hover}>
                    <img src={`${backendURLFile}/${link.logoPath}`} className="h-4 w-4" />{' '}
                    <p className="text-[12px]">{link.name}</p>
                  </NavLink>
                ))}
              {role == 'USER' &&
                reverse.map((link: any, index: number) => (
                  <NavLink to={`/dashboard/client/emission/${link.id}`} key={index} className={Hover}>
                    <img src={`${backendURLFile}/${link.logoPath}`} className="h-4 w-4" />{' '}
                    <p className="text-[12px]">{link.name}</p>
                  </NavLink>
                ))}
            </div>
            {role == 'ADMIN' &&
              SideLinks.map((li, i) => (
                <NavLink to={li.link} key={i} className={Hover}>
                  <li.icon /> <p>{li.name}</p>
                </NavLink>
              ))}
            {role == 'USER' &&
              SideLinksClient.map((li, i) => (
                <NavLink to={li.link} key={i} className={Hover}>
                  <li.icon /> <p>{li.name}</p>
                </NavLink>
              ))}

            <div onClick={logout} className="ml-4 hover:bg-subMain p-2 rounded">
              <div className="grid grid-cols-12 items-center">
                <span className="col-span-10 flex items-center gap-2 cursor-pointer font-bold py-2">
                  <MdLogout /> <p>Deconnexion</p>
                </span>
                <div className="col-span-2 cursor-pointer"></div>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="10"
            data-aos-offset="100"
            className="col-span-6 rounded-md bg-dry border border-gray-800 p-6"
          >
            {children}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Sidebar;
