import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { backendURL } from "../Config/baseUrl";


export const getMovieLists = createAsyncThunk('get/movies', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/movies/category-genre`, config);
    return data.data;
  } catch (error: any) {
    return error.message;
  }
});

/**
 * GET LIST CATEGORY
 */
export const getCategoryLists = createAsyncThunk('get/category_movie', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/movies/category`, config);
    return data.data;
  } catch (error: any) {
    return error.message;
  }
});

/**
 * FILTER MOVIE BY CATEGORY AND GENRE
 */
export const filterMovieByCategoryAndGenre = createAsyncThunk('filter/category_genre', async (filter: { category: number; genre: number | null }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.post(`${backendURL}/movies/filter`, filter, config);
    return data.data;
  } catch (error: any) {
    return error.message;
  }
});

/**
 * GET BY ID MOVIE
 */
export const getByIdMovie = createAsyncThunk('get/byIdMovie', async (id: string) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/movie/by-id/${id}`, config);
    return data.data;
  } catch (error: any) {
    return error.message;
  }
});


export const getGenreList = createAsyncThunk('get/genre', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/genre`, config);
    return data.data;
  } catch (error: any) {
    return error.message;
  }
});

export const createMovie = createAsyncThunk('create/movie', async (movieInfo: any, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.post(`${backendURL}/post/movie`, movieInfo, config);
      return data.message;
    } else {
      throw new Error('Erreur de jéton,veuillez reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});


/**
 * DELETE MOVIE
 */
export const deleteMovie = createAsyncThunk('movie/delete', async (id: number, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.delete(`${backendURL}/post/movie/delete/${id}`, config);
      return { data: data, id: id };
    } else {
      throw new Error('Le jéton est périmé,veuillez vous reconnecter');
    }
  } catch (error: any) {
    return error;
  }
});

/**
 * UPDATE MOVIE
 */
export const updateMovie = createAsyncThunk('movie/update', async (dataUpdated: { id: number, updatedMovie: IMovie }, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.post(`${backendURL}/post/movie/update/${dataUpdated.id}`, dataUpdated.updatedMovie, config);
      return data;
    } else {
      throw new Error('Le jéton est périmé,veuillez vous reconnecter');
    }
  } catch (error: any) {
    return error;
  }
});

/**
 * ADD VIEW MOVIE
 */
export const addViewToMovie = createAsyncThunk('add/view-movie', async (idMovie: number) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const user = JSON.parse(localStorage.getItem('user')!)
    if (user) {
      const { data } = await axios.post(`${backendURL}/movies/${idMovie}/view`, {
        userId: user.id
      }, config);
      return data;
    } else {
      return {}
    }
  } catch (error: any) {
    return error.message;
  }
});




/**
 * INTERFACE
 */
interface IMovieState {
  loading: boolean;
  error: string | null;
  message: string | null;
  movieLists: IMovie[];
  categoryLists: ICategoryMovie[],
  detailMovie: IMovie | null,
  genreList: IGenreMovie[],
  createdMovie: IMovie | null,
  updatedMovie: IMovie | null
}

export interface IMovie {
  id: number;
  filename: string;
  teaserId: number;
  description?: string;
  release_date?: string;
  duration?: string;
  director?: string;
  category: ICategoryMovie;
  genres: IGenreMovie,
  title: string,
  cover_image: string,
  price: number,
  isFree: number,
  productor: string,
  view_count: number
}

export interface ICategoryMovie {
  id: number;
  name: string;
  genres?: IGenreMovie[]
}

export interface IGenreMovie {
  id: number;
  name: string
}


const initialState: IMovieState = {
  loading: false,
  error: null,
  message: null,
  movieLists: [],
  categoryLists: [],
  detailMovie: null,
  genreList: [],
  createdMovie: null,
  updatedMovie: null
};

const movieSlice = createSlice({
  name: 'movies',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    },
    setMovieList: (state, action) => {
      state.movieLists = action.payload;
    },
    setUpdatedMovieNull: (state) => {
      state.updatedMovie = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMovieLists.pending, (state: IMovieState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getMovieLists.fulfilled, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.movieLists = payload;
    });
    builder.addCase(getMovieLists.rejected, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(getCategoryLists.pending, (state: IMovieState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCategoryLists.fulfilled, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.categoryLists = payload;
    });
    builder.addCase(getCategoryLists.rejected, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(filterMovieByCategoryAndGenre.pending, (state: IMovieState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(filterMovieByCategoryAndGenre.fulfilled, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.movieLists = payload;
    });
    builder.addCase(filterMovieByCategoryAndGenre.rejected, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(getByIdMovie.pending, (state: IMovieState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getByIdMovie.fulfilled, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.detailMovie = payload;
    });
    builder.addCase(getByIdMovie.rejected, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(getGenreList.pending, (state: IMovieState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getGenreList.fulfilled, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.genreList = payload;
    });
    builder.addCase(getGenreList.rejected, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(createMovie.pending, (state: IMovieState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createMovie.fulfilled, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.error = null;
      state.createdMovie = payload
    });
    builder.addCase(createMovie.rejected, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(deleteMovie.pending, (state: IMovieState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteMovie.fulfilled, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.message = 'Suppression du film avec succèss';
    });
    builder.addCase(deleteMovie.rejected, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(updateMovie.pending, (state: IMovieState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateMovie.fulfilled, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.updatedMovie = payload.movie;
      state.message = payload.message;
    });
    builder.addCase(updateMovie.rejected, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(addViewToMovie.pending, (state: IMovieState, { payload }: any) => {
      state.error = null;
    });
    builder.addCase(addViewToMovie.fulfilled, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.message = payload.message;
    });
    builder.addCase(addViewToMovie.rejected, (state: IMovieState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

  }
})

export const { seterrornull, setMessageNull, setMovieList, setUpdatedMovieNull } = movieSlice.actions;
export default movieSlice.reducer;
