import { FaFacebook } from 'react-icons/fa';
import { FcCellPhone, FcContacts } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { backendURLFile } from '../../../../../Config/baseUrl';

export function EventShow({ data }: any) {
  const navigate = useNavigate();
  const showFullScreen = (i: number) => {
    navigate(`/dashboard/client/events/show`, {
      state: {
        data: data,
        index: i
      }
    });
  };

  return (
    <div className="grid gap-x-8 gap-y-4 grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-2 max-[400px]:grid-cols-1">
      {data.map((event: any, i: number) => {
        return (
          <div key={i} className="cursor-pointer">
            <img
              onClick={() => showFullScreen(i)}
              style={{ height: 280 }}
              className="w-full"
              src={`${backendURLFile}/${event.post.files[0]}`}
            />
            <ul key={i} className="text-center list-none">
              <li>
                {event.post.legend} le {event.date}
              </li>
              <li> Chez {event.place}</li>
              <li>
                {' '}
                <a href={event.ticketPlace} className="hover:bg-violet-800 rounded p-1" target="_blank">
                  Ticket
                </a>
              </li>
            </ul>
          </div>
        );
      })}
    </div>
  );
}

export function AnimatorList({ data }: any) {
  const navigate = useNavigate();
  const showFullScreenAnim = (i: number) => {
    navigate(`/dashboard/client/Animator/about`, {
      state: {
        data: data,
        index: i
      }
    });
  };
  return (
    <div className="grid gap-x-8 gap-y-4 grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-2 max-[400px]:grid-cols-1">
      {data.map((event: any, i: number) => {
        return (
          <div key={i} className="cursor-pointer">
            <img
              onClick={() => showFullScreenAnim(i)}
              style={{ height: 220 }}
              className="w-full"
              src={`${backendURLFile}/${event.profilePicturePath}`}
            />
            <ul key={i} className="text-center list-none mt-5">
              <li className="flex flex-row ">
                <FcContacts size={35} />
                <span className="m-2 font-semibold">{event.name}</span>
              </li>
              <li className="flex flex-row ">
                <FcCellPhone size={40} />
                <span className="m-2 font-semibold">{event.contact}</span>
              </li>
              <li className="justify-center">
                <a
                  href={event.facebook_username}
                  className="hover:bg-violet-800 rounded p-1 flex flex-row justifify-between"
                  target="_blank"
                >
                  <FaFacebook size={30} /> <span className="m-2 ml-5">FaceBook</span>
                </a>
              </li>
            </ul>
          </div>
        );
      })}
    </div>
  );
}
