import { PaymentIntent } from "@stripe/stripe-js";
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { FcComments, FcDownload, FcFilm, FcLeft, FcLike } from "react-icons/fc";
import { CirclesWithBar } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { backendURLFile, streamingUrlFile } from "../../../../Config/baseUrl";
import { AppDispatch } from "../../../../Store/Store";
import { IMovie, addViewToMovie, getByIdMovie } from "../../../../Toolkit/MovieAction";
import { payWithMvola } from "../../../../Toolkit/PayementAction";
import { EStatus, ISubscription, ITransaction, getTransactionById } from "../../../../Toolkit/SubcriptionTransactionAction";
import { ETypeVideo } from "../../../../shared/enum";
import { checkVideoExists, downloadAndSaveVideo, openDB } from "../../../../shared/indexBb/indexedDBHelper";
import Sidebar from "../../../Admin/Components/Sidebar";
import AbonnementPending from "../../Shared/components/AbonnementPending";
import CheckoutFormStrype from "../../Shared/components/CheckoutFormStrype";
import ModalChoicePayement from "../../Shared/components/ModalChoicePayement";
import ModalPayement from "../../Shared/components/ModalPayement";
import ModalPayementMobileMoney from "../../Shared/components/ModalPayementMobileMoney";

const DetailMovie: React.FC = () => {
  /**
   * REDUX
   */
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: any) => state.movie.loading);
  const detailMovie: IMovie = useSelector((state: any) => state.movie.detailMovie);
  const transactionForUser: ITransaction[] = useSelector((state: any) => state.subscriptionTransaction.transactionForUser);

  /**
   * VARIABLE
   */
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalStrype, setOpenModalStrype] = useState<boolean>(false);
  const [openModalChoicePayement, setOpenModalChoicePayement] = useState<boolean>(false);
  const [openModalPayementMobileMoney, setOpenModalPayementMobileMoney] = useState<boolean>(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null)
  const [amount, setAmount] = useState<number>()
  const [choicePayement, setChoicePayement] = useState<string>('mvola')
  const [selectedSubscription, setSelectedSubscription] = useState<ISubscription>()
  const [haveAbonnePending, sethaveAbonnePending] = useState<boolean>(false)
  const [haveAbonneAccepted, sethaveAbonneAccepted] = useState<boolean>(false)
  const [isDownloadSuccess, setIsDownloadSuccess] = useState<boolean>(false)
  const [isLoadingSucess, setIsLoadingSucess] = useState<boolean>(false)
  const [tokenPlay, setTokenPlay] = useState<string>('')
  const [showFullMovies, setShowFullMovies] = useState<boolean>(false)
  /**
   * LYFECYCLE
   */
  useEffect(() => {
    if (id) {
      dispatch(getByIdMovie(id)
      )
    }
  }, [id])

  useEffect(() => {
    handleConfirmPayement()
  }, [])

  useEffect(() => {
    sethaveAbonnePending(transactionForUser?.find((t: ITransaction) => t.status === EStatus.PENDING) !== undefined)
  }, [transactionForUser])

  useEffect(() => {
    openDB()
  }, []);


  /**
   * FUNCTION
   */
  const handleViewFilmIntegrale = async () => {
    sethaveAbonneAccepted(false)
    setShowFullMovies(true)
    if (detailMovie.isFree === 1) {
      const token = await fetchToken();
      if (token) {
        setTokenPlay(token)
        sethaveAbonneAccepted(true)

        // Ajouter le token dans le cookie
        Cookies.set('auth_token', token, { expires: 7 });
      }
      return
    }

    const checkIfMovieNull = transactionForUser?.find((t: ITransaction) => t.movie == null && t.status == EStatus.VALIDATED)
    if (checkIfMovieNull) {
      sethaveAbonneAccepted(true)
      return
    }

    if (id) {
      const checkIfMovieExist = transactionForUser?.find((t: ITransaction) => t.movie !== null && t.status == EStatus.VALIDATED && t.movie?.id === +id)
      if (checkIfMovieExist) {
        sethaveAbonneAccepted(true)
        return
      }
    }
    setOpenModal(true)
  }


  const handleChoiceSubscription = (subscription: ISubscription) => {
    setSelectedSubscription(subscription)
    setAmount(subscription.price)
    setOpenModal(false)
    setOpenModalChoicePayement(true)
  }


  const handleChoiceMoyenPayement = (choice: string) => {
    setOpenModalChoicePayement(false)
    if (choice == 'mvola' || choice == 'orange_money') {
      setChoicePayement(choice)
      setOpenModalPayementMobileMoney(true)
    } else {
      setOpenModalStrype(true)
    }
  }

  const handleConfirmPayement = () => {
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user)
      setUser(parsedUser)
      dispatch(getTransactionById(parsedUser.id))
    }
  }

  const handlePayementStrype = (payementItent: PaymentIntent) => {
    if (payementItent.amount) {
      handlePay(amount!, "No");
    }
  }

  /**
   * API
   */
  const handlePay = async (price: number, num_telephone: string) => {
    try {
      const data = selectedSubscription?.type == "Film" ? {
        "subscription_id": selectedSubscription?.id,
        "amount": price,
        "user_id": user.id,
        "status": "pending",
        "type_payement": choicePayement,
        "num_telephone": num_telephone,
        "movie_id": id
      } : {
        "subscription_id": selectedSubscription?.id,
        "amount": price,
        "user_id": user.id,
        "status": "pending",
        "type_payement": choicePayement,
        "num_telephone": num_telephone
      }
      dispatch(payWithMvola(data))
      // await createTransaction(data)
      // dispatch(getTransactionById(user.id))
      // setOpenModalPayementMobileMoney(false)
      // setOpenModalStrype(false)
    } catch (error) {
    }
  }

  const onAddViewVideo = () => {
    dispatch(addViewToMovie(detailMovie.id))
  }

  const downloadVideo = async (urlFile: string) => {
    if (!await checkVideoExists(detailMovie.id)) {
      const filename = urlFile.split('/')[2]
      const token = await fetchToken(true);

      if (token) {
        setIsLoadingSucess(true)
        downloadAndSaveVideo(`${streamingUrlFile}/video/download/stream/${filename}`, detailMovie, token, onDownloadSuccess);
      } else {
        toast.error("Vous n'avez pas le droit de télécharger ce film")
      }
    } else {
      toast.info('Vidéo dejà téléchargé')
    }
  };

  const onDownloadSuccess = (isSucces: boolean) => {
    if (isSucces) {
      setIsDownloadSuccess(isSucces)
      setIsLoadingSucess(false)
      toast.success('Vidéo télécharger avec succèes')
    } else {
      setIsLoadingSucess(false)
      toast.error('Erreur lors du téléchargement du film')
    }
  }

  const fetchToken = async (isDownload: boolean = false) => {
    const response = await fetch(`${streamingUrlFile}/generate-token/${isDownload ? 'down' : 'play'}`);
    const data = await response.json();
    return data.token;
  };

  const onCloseFullScreen = () => {
    setShowFullMovies(false)
  }
  return (
    <>
      <Sidebar>
        <div className="w-full flex flex-col m-2  max-md:mb-64">
          <div className="flex-btn gap-2">
            <h2 className="text-xl font-bold cursor-pointer"><FcLeft onClick={() => { navigate(-1) }} /></h2>
            <div className="col-span-3">
            </div>
          </div>
          <div className="flex justify-center m-15 mt-4">
            {loading && (
              <CirclesWithBar
                height="100"
                width="100"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            )}
          </div>
          {!loading && (
            <>
              <div className="container mx-auto p-4">
                <article className="text-wrap">
                  <h3 className="text-2xl font-semibold text-pink-500">{detailMovie?.title}</h3>
                  <h5 className="text-sm font-semibold mt-5">Description :</h5>
                  <p className="text-xs p-1">{detailMovie?.description}</p>
                </article>
                <div className="flex flex-col md:flex-row mt-4">
                  <img
                    className="w-full md:w-2/5 h-32 p-2"
                    src={`${backendURLFile}/${detailMovie?.cover_image}`}
                    style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                  />
                  <div className="p-3">
                    <ul>
                      <li>{`Genre : ${detailMovie?.genres?.name}`}</li>
                      <li>{`Durée : ${detailMovie?.duration}`}</li>
                      <li>{`Date de sortie : ${detailMovie?.release_date}`}</li>
                      <li>{`Directeur : ${detailMovie?.director}`}</li>
                      {detailMovie?.isFree == 1 ? <li>Gratuit</li> : <li>{`Prix: ${detailMovie?.price} Ar`}</li>}
                    </ul>
                  </div>
                </div>

                {
                  !haveAbonnePending &&
                  <div className="flex mt-4 space-x-2">
                    <div
                      onClick={handleViewFilmIntegrale}
                      className="flex items-center px-3 py-1.5 bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] cursor-pointer bg-white rounded-lg shadow-lg hover:border-2 border-zinc-50"
                    >
                      <FcFilm className="mr-2" />
                      <h2 className="text-sm font-semibold">Voir Film</h2>
                    </div>
                    {haveAbonneAccepted && <div
                      onClick={() => downloadVideo(detailMovie.filename)}
                      className="flex items-center px-3 py-1.5 bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] cursor-pointer bg-white rounded-lg shadow-lg hover:border-2 border-zinc-50"
                    >

                      {
                        isLoadingSucess ? <CirclesWithBar
                          height="20"
                          width="20"
                          color="#007aff"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          outerCircleColor=""
                          innerCircleColor=""
                          barColor=""
                          ariaLabel="circles-with-bar-loading"
                        /> : <FcDownload className="mr-2" />
                      }
                      <h2 className="text-sm font-semibold ms-2">Téléchargé</h2>
                    </div>}

                  </div>
                }
                <div className="w-full h-5/6 pt-2 rounded-xl mt-4">
                  {
                    haveAbonneAccepted && detailMovie?.id && <video className="w-full max-w-3xl mx-auto" controls onPlay={onAddViewVideo}>
                      <source src={`${streamingUrlFile}/video/${ETypeVideo.MOVIE}/${detailMovie?.filename.split('/')[2]}/${tokenPlay}`} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  }
                  <div className="h-1/3 text-base text-slate-100 flex flex-row justify-between mt-4">
                    <div>
                      <p className="text-sm font-semibold pt-2"></p>
                    </div>
                    <div className="text-base pt-3 flex flex-row">
                      <span className="cursor-pointer flex flex-row">
                        {0} <FcComments size={23} />
                      </span>
                      <span className="mx-10 cursor-pointer flex flex-row">
                        {0} <FcLike size={23} />
                      </span>
                    </div>
                  </div>
                  <div className="text-base mt-4">
                    <p>@ Utilisateur</p>
                    <p>Commentaire de l'utilisateur</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <ModalPayement detailMovie={detailMovie} openModal={openModal} setOpenModal={setOpenModal} handleChoiceSubscription={handleChoiceSubscription} />
        <ModalChoicePayement openModal={openModalChoicePayement} setOpenModal={setOpenModalChoicePayement} handleChoiceMoyenPayement={handleChoiceMoyenPayement} />
        <ModalPayementMobileMoney handlePay={handlePay} choicePayement={choicePayement} openModal={openModalPayementMobileMoney} setOpenModal={setOpenModalPayementMobileMoney} amount={amount} />
        <CheckoutFormStrype openModal={openModalStrype} setOpenModal={setOpenModalStrype} choicePayement={choicePayement} amount={amount!} handlePayementStrype={handlePayementStrype} />

        {
          haveAbonnePending && <AbonnementPending />
        }


      </Sidebar>
      {/* {
        showFullMovies && <FullScreenModal src={`${streamingUrlFile}/video/${detailMovie?.filename.split('/')[2]}`} onClose={onCloseFullScreen} />
      } */}
    </>
  );
}

export default DetailMovie;
