import { FaFacebook } from 'react-icons/fa';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { backendURLFile } from '../../../../../Config/baseUrl';

export function Fullescreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const data = state.data;
  const index: number = state.index;
  const Exit = () => {
    return navigate('/dashboard/client/event');
  };
  return (
    <div>
      <Swiper initialSlide={index} navigation>
        {data.map((image: any, index: number) => (
          <SwiperSlide key={index} virtualIndex={index}>
            <div className="flex flex-row max-sm:flex-col h-[39.2rem] max-sm:h-screen bg-gradient-to-r from-violet-500 to-fuchsia-500">
              <img
                className="w-4/6 bg-cover bg-center max-sm:w-full"
                src={`${backendURLFile}/${image.post.files[0]}`}
              />
              <div className="w-2/6 shadow-lg flex flex-col mt-4 max-sm:w-full">
                <div className="grid grid-cols-6 gap-4">
                  <p className="ms-8 mx-6 cursor-pointer col-end-8 col-span-2" onClick={() => Exit()}>
                    <IoCloseCircleOutline size={30} />
                  </p>
                </div>
                <ul key={index} className="text-center list-none my-4">
                  <li>
                    {image.post.legend} le {image.date}
                  </li>
                  <li> A {image.place}</li>
                  <li>
                    {' '}
                    <a href={image.ticketPlace} target="_blank">
                      Ticket
                    </a>
                  </li>
                </ul>
                <div className="mx-6">
                  <p className="text-base"> Réaction</p>
                  <p className="mt-4 mx-4">@ Users</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export function FullescreenAnimator() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const data = state.data;
  const index: number = state.index;
  const Exit = () => {
    return navigate('/dashboard/client/animators');
  };
  return (
    <div>
      <Swiper initialSlide={index} navigation>
        {data.map((image: any, index: number) => (
          <SwiperSlide key={index} virtualIndex={index}>
            <div className="flex flex-row max-sm:flex-col h-[50.4rem] max-sm:h-screen bg-gradient-to-r from-violet-500 to-fuchsia-500">
              <img
                className="w-4/6 bg-cover bg-center max-sm:w-full"
                src={`${backendURLFile}/${image.profilePicturePath}`}
              />
              <div className="w-2/6 shadow-lg flex flex-col mt-4 max-sm:w-full">
                <div className="grid grid-cols-6 gap-4">
                  <p className="ms-8 mx-6 cursor-pointer col-end-8 col-span-2" onClick={() => Exit()}>
                    <IoCloseCircleOutline size={30} />
                  </p>
                </div>
                <ul key={index} className="text-center text-white list-none my-4 m-8 justify-center">
                  <li className="text-2xl font-bold">{image.name}</li>
                  <li className="font-semibold">{image.contact}</li>
                  <li className="cursor-pointer">
                    <a href={image.facebook_username} target="_blank">
                      <FaFacebook size={30} />
                    </a>
                  </li>
                  <li className="font-medium text-black text-left p-1 mt-2">{image.about}</li>
                </ul>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
