import { PaymentIntent } from "@stripe/stripe-js";
import React from "react";
import SimpleModal from "../../../Admin/Components/Modals/SimpleModal";
import PaymentForm from "../../Components/PayementForm";

interface CheckoutFormStrypeProps {
  openModal: boolean;
  setOpenModal: any;
  choicePayement: string;
  amount: number;
  handlePayementStrype: (paymentIntent: PaymentIntent) => void;
}

const CheckoutFormStrype: React.FC<CheckoutFormStrypeProps> = ({ openModal, setOpenModal, choicePayement, amount, handlePayementStrype }) => {
  return (
    <SimpleModal modalOpen={openModal} setModalOpen={setOpenModal}>
      <PaymentForm amount={amount} handlePayementStrype={handlePayementStrype} />
    </SimpleModal>
  );
};

export default CheckoutFormStrype;
