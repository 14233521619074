import React from 'react';
import Layout from '../Layout/Layout';
import Banner from '../Components/Banner';
import PopularMovies from '../Components/PopularVideos';
import Promos from '../Components/Promos';
import TopRated from '../Components/TopRated';

function HomeScreen() {
  return (
    <Layout>
      <div className="container mx-auto min-h-screen px-4 mb-6 overflow-hidden">
        <Banner />
        <PopularMovies />
        <Promos />
        <TopRated />
      </div>
    </Layout>
  );
}

export default HomeScreen;
