import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendURL, notifURL } from '../Config/baseUrl';

interface teaser {
  title: string;
  date?: string;
  place: string;
  duration: string;
  category: string;
  legend: string;
  video?: File;
  image?: File;
  director: string;
  producer: string;
  principal_actors: Array<string>;
}
interface teaserWithId {
  tease: teaser;
  id: number;
}

interface Categories {
  wording: string;
}

interface teaserState {
  loading: boolean;
  lists: Array<teaser>;
  error: string | null;
  message: string | null;
  categories: Array<Categories>;
}
export const GetAllteasers = createAsyncThunk('teasers/all', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/post/teasers`, config);
    return data.teasers.reverse();
  } catch (error: any) {
    return error.message;
  }
});
export const GetAllCategories = createAsyncThunk('teasers/categories', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/teasers/categories`, config);
    return data.categories;
  } catch (error: any) {
    throw new Error(error.message);
  }
});

export const Createteaser = createAsyncThunk('teasers/add', async (teaserInfo: any, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.post(`${backendURL}/post/teaser`, teaserInfo, config);
      try {
        await axios.post(`${notifURL}/notif/trailer`).then((res) => {
          return res;
        });
      } catch (error) {
        return error;
      }
      return data.message;
    } else {
      throw new Error('Erreur de jéton,veuillez reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const TeaserUpdate = createAsyncThunk('teasers/update', async (teaserInfo: any, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.post(
        `${backendURL}/post/teaser/update/${teaserInfo.id!}`,
        teaserInfo.tease!,
        config
      );
      return data.message;
    } else {
      throw new Error('Erreur de jéton,veuillez reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
export const TeaserDelete = createAsyncThunk('teasers/delete', async (id: number, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.delete(`${backendURL}/post/teaser/delete/${id}`, config);
      return data;
    } else {
      throw new Error('Erreur de jéton,veuillez reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const initialState: teaserState = {
  loading: false,
  lists: [],
  error: null,
  message: null,
  categories: []
};

const teaserSlice = createSlice({
  name: 'teaser',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllteasers.pending, (state: teaserState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllteasers.fulfilled, (state: teaserState, { payload }: any) => {
      state.loading = false;
      state.lists = payload;
    });
    builder.addCase(GetAllteasers.rejected, (state: teaserState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(GetAllCategories.pending, (state: teaserState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllCategories.fulfilled, (state: teaserState, { payload }: any) => {
      state.loading = false;
      state.categories = payload;
    });
    builder.addCase(GetAllCategories.rejected, (state: teaserState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(Createteaser.pending, (state: teaserState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Createteaser.fulfilled, (state: teaserState, { payload }: any) => {
      state.loading = false;
      state.message = 'Création du teaser avec succèss';
    });
    builder.addCase(Createteaser.rejected, (state: teaserState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(TeaserUpdate.pending, (state: teaserState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(TeaserUpdate.fulfilled, (state: teaserState, { payload }: any) => {
      state.loading = false;
      state.message = 'Mise à jour du teaser avec succèss';
    });
    builder.addCase(TeaserUpdate.rejected, (state: teaserState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(TeaserDelete.pending, (state: teaserState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(TeaserDelete.fulfilled, (state: teaserState, { payload }: any) => {
      state.loading = false;
      state.message = 'Suppression  du teaser avec succèss';
    });
    builder.addCase(TeaserDelete.rejected, (state: teaserState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
  }
});

export const { seterrornull, setMessageNull } = teaserSlice.actions;
export default teaserSlice.reducer;
