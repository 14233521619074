import Layout from '../../Client/Layout/Layout';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RxEnter } from 'react-icons/rx';
import { Link } from 'react-router-dom';
import { CirclesWithBar } from 'react-loader-spinner';
import { IoIosCloseCircleOutline, IoIosEye, IoIosEyeOff } from 'react-icons/io';
import { useSelector } from 'react-redux';

import { AppDispatch } from '../../../Store/Store';
import { ClientReg, RegisterClient } from '../../../Toolkit/authAction';
import { seterrornull } from '../../../Toolkit/authAction';
import { useState } from 'react';
import axiosInstance from "../../../api/axiosInstance";
import { authService } from "../../../shared/service/auth.service";
import { toast } from "react-toastify";

const schema = yup
  .object({
    pseudonyme: yup
      .string()
      .required("Ce champ est requis"),
    birthDate: yup
      .string()
      .required("Ce champ est requis"),
    gender: yup
      .string()
      .required("Ce champ est requis"),
    email: yup
      .string()
      .email("L'email doit être valide")
      .required("Ce champ est requis"),
    password: yup
      .string()
      .min(6, "Le mot de passe doit contenir au moins 6 caractères")
      .required("Ce champ est requis"),
  })
  .required();


type FormData = yup.InferType<typeof schema>;

const Inscription: React.FC = () => {
  /**
   * VARIABLE
   */
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const error = useSelector((state: any) => state.auth.error);
  const loading = useSelector((state: any) => state.auth.loading);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });
  const [errorsApi, setErrorsApi] = useState<string>('')

  /**
   * FUNCTION
   */
  const onSubmit = async (data: FormData) => {
    try {
      // Formatage de la date pour correspondre au format attendu
      const date = new Date(data.birthDate).toLocaleDateString('fr-CA'); // format YYYY-MM-DD
      data.birthDate = date;

      // Appel du service d'authentification pour l'enregistrement
      await authService.register(data as ClientReg);

      toast.success("Compte créé avec succès.")

      navigate("/login")
    } catch (error: any) {
      // Vérification si l'erreur contient une réponse spécifique
      if (error.response && error.response.data) {
        const errorData = error.response.data;

        // Vérifie si le message d'erreur provient de contraintes de champs
        if (errorData.error) {
          const constraints = errorData.data;
          const errors = [];

          // Parcourt les contraintes et récupère les messages d'erreur
          for (const key in constraints) {
            if (constraints[key].length > 0) {
              errors.push(`${constraints[key].join(', ')}`);
            }
          }
          // Affiche ou retourne les erreurs de façon lisible
          setErrorsApi(errors.join(' | '))
          toast.error(errors.join(' | '))
          return errors.join(' | ');
        }

        setErrorsApi(errorData.message)

        return errorData.message;
      } else {
        // Gestion des erreurs génériques
        console.error('Erreur inattendue:', error.message || error);
        return 'Une erreur inattendue est survenue.';
      }
    }
  };



  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleRadioChange = (value: any) => {
    setSelectedOption(value);
  };

  return (
    <Layout>
      <div className="container mx-auto px-2 my-12 flex-colo">
        <div className="relative w-full 2xl:w-2/5 gap-8 flex-colo p-14 md:w-3/5 bg-dry rounded-lg border border-border">
          {loading && (
            <div className="absolute top-32 mx-auto">
              <CirclesWithBar
                height="100"
                width="100"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            </div>
          )}
          <img src="/assets/images/logo.png" alt="logo" className="w-full h-12 object-contain" />
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="text-sm w-full">
              <label className="text-white font-semibold">Pseudo</label>
              <input
                {...register('pseudonyme')}
                name="pseudonyme"
                type="text"
                placeholder="Saisissez ici votre pseudo"
                className="w-full text-sm mt-2 p-5 border border-border rounded text-text bg-main"
              />
              {errors.pseudonyme && <p className="text-red-500">{errors.pseudonyme.message}</p>}
            </div>
            <div className="text-sm w-full mt-4 relative">
              <label className="text-white font-semibold">Date de naissance</label>
              <div className="relative">
                <input
                  {...register('birthDate')}
                  name="birthDate"
                  type="date"
                  placeholder="Saisissez ici votre Date de naissance"
                  className="w-full text-sm mt-2 p-5 border border-border rounded text-text bg-main"
                />
                {errors.birthDate && <p className="text-red-500">{errors.birthDate.message}</p>}
              </div>
            </div>
            <div className="text-sm w-full">
              <label className="text-white font-semibold">Sexe</label>
              <div className="text-center space-x-16">
                <label>
                  <input
                    {...register('gender')}
                    type="radio"
                    value="M"
                    checked={selectedOption === 'M'}
                    onChange={() => handleRadioChange('M')}
                  />
                  M
                </label>

                <label>
                  <input
                    {...register('gender')}
                    type="radio"
                    value="F"
                    checked={selectedOption === 'F'}
                    onChange={() => handleRadioChange('F')}
                  />
                  F
                </label>
              </div>
              {errors.gender && <p className="text-red-500">{errors.gender.message}</p>}
            </div>
            <div className="text-sm w-full">
              <label className="text-white font-semibold">Email</label>
              <input
                {...register('email')}
                name="email"
                type="email"
                placeholder="Saisissez ici votre email"
                className="w-full text-sm mt-2 p-5 border border-border rounded text-text bg-main"
              />
              {errors.email && <p className="text-red-500">{errors.email.message}</p>}
            </div>
            <div className="text-sm w-full mt-4 relative">
              <label className="text-white font-semibold">Password</label>
              <div className="relative">
                <input
                  {...register('password')}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Saisissez ici votre mot de passe"
                  className="w-full text-sm mt-2 p-5 border border-border rounded text-text bg-main"
                />
                <div
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <IoIosEyeOff /> : <IoIosEye />}
                </div>
              </div>
              {errors.password && <p className="text-red-500">{errors.password.message}</p>}
            </div>

            <div className="text-sm w-full mt-4">
              <button
                type="submit"
                className="mt-4 bg-subMain transitions hover:bg-main flex-rows gap-4 text-white p-4 rounded-lg w-full cursor-pointer"
              >
                <RxEnter /> S'inscrire
              </button>
            </div>
          </form>

          <p className="text-center text-border space-x-36">
            <Link to="/login/#" className="text-dryGray font-semibold ml-2">
              Se connecter
            </Link>
            <Link to="/contact-us" className="text-dryGray font-semibold ml-2">
              Contacter l'admin
            </Link>
          </p>
        </div>
      </div>
      {error && (
        <div
          className="absolute left-1 bottom-1 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">Une erreur a été survenu lors de votre connexion,verifiez vos données</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(seterrornull())} />
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Inscription;
