import React, { useState, ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MdUpload } from 'react-icons/md';
import { BsImage } from 'react-icons/bs';
import { MdVideoLibrary } from 'react-icons/md';
import { CirclesWithBar } from 'react-loader-spinner';

import { Createteaser } from '../../../../../Toolkit/TeaserAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../Store/Store';
import { convertirDateTeaser } from '../../../../../Config/FormatDate';
import MainModal from '../MainModal';
import createArrayFromInput from '../../../../../Config/AlgoInput';

const schema = yup
  .object({
    title: yup.string().required(),
    date: yup.date().required(),
    place: yup.string().required(),
    duration: yup.string().required(),
    category: yup.string().required(),
    legend: yup.string().required(),
    video: yup.mixed().label('Votre video'),
    image: yup.mixed().label('Votre image'),
    director: yup.string().required(),
    producer: yup.string().required(),
    principal_actors: yup.string().required()
  })
  .required();
type FormData = yup.InferType<typeof schema>;

function CreateTeaser({ modalOpen, setModalOpen }: any) {
  const loading = useSelector((state: any) => state.teaser.loading);
  const dispatch = useDispatch<AppDispatch>();
  const [play, setPlay] = useState(false);
  const [link, setLink] = useState('');
  const [videolink, setvideolink] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setLink(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleFileChangeVideo = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setvideolink(URL.createObjectURL(e.target.files[0]));
    }
  };
  const HandlePostTeaser = (data: FormData) => {
    try {
      const video = (data.video as any[])[0];
      const photo = (data.image as any[])[0];

      const formData = new FormData();

      if (photo) {
        formData.append('image', photo);
      }

      if (video) {
        formData.append('video', video);
      }
      formData.append('principalActors', JSON.stringify(createArrayFromInput(data.principal_actors)));

      const { title, duration, place, category, legend, director, producer, ...restData } = data;
      const dataintermediaire = {
        title,
        date: convertirDateTeaser(data.date),
        duration,
        place,
        category,
        legend,
        director,
        producer
      };
      const datafinal = { ...dataintermediaire, ...Object.fromEntries(formData.entries()) };

      dispatch(Createteaser(datafinal)).then((res: any) => {
        setvideolink('');
        setLink('');
        reset();
        setModalOpen(false);
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-block sm:w-4/5 md:w-4/5 lg:w-4/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl">
        <div className="flex flex-col gap-6 relative">
          {loading && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
              <CirclesWithBar
                height="100"
                width="100"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            </div>
          )}
          <form className="w-full" onSubmit={handleSubmit(HandlePostTeaser)}>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Titre de votre teaser</label>
                <input
                  {...register('title')}
                  name="title"
                  type="text"
                  placeholder="Ici le nom de votre teaser"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.title?.message}</p>
              </div>
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Date de sortie</label>
                <input
                  {...register('date')}
                  name="date"
                  type="date"
                  placeholder="Ici la  date de votre teaser"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.date?.message}</p>
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Réalisateur</label>
                <input
                  {...register('director')}
                  name="director"
                  type="text"
                  placeholder="Réalisateur..."
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.director?.message}</p>
              </div>
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Producteur</label>
                <input
                  required
                  {...register('producer')}
                  name="producer"
                  type="text"
                  placeholder="Producteur..."
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.producer?.message}</p>
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-1 gap-6">
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Acteurs principaux</label>
                <input
                  {...register('principal_actors')}
                  name="principal_actors"
                  type="text"
                  placeholder="Acteurs(Séparer par un virgule si c'est plus que 1)"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.principal_actors?.message}</p>
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Place</label>
                <input
                  {...register('place')}
                  name="place"
                  type="text"
                  placeholder="Ici place de votre teaser"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.place?.message}</p>
              </div>
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Durée</label>
                <input
                  required
                  {...register('duration')}
                  name="duration"
                  type="text"
                  placeholder="1min 50"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.duration?.message}</p>
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Categorie</label>
                <input
                  {...register('category')}
                  name="category"
                  required
                  type="text"
                  placeholder="Ici le categorie de votre teaser"
                  className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
              </div>
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Description</label>
                <input
                  {...register('legend')}
                  name="legend"
                  required
                  type="text"
                  placeholder="Ici la description de votre teaser"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="flex flex-col gap-2">
                <p className="text-border font-semibold text-sm">Video teaser</p>
                <div className="w-full">
                  <label className="flex justify-center h-16  transition text-subMain bg-dry border-2 px-6 py-4  border-gray-300 border-dashed  rounded-md  appearance-none cursor-pointer hover:border-gray-main focus:outline-none">
                    <span className="flex items-center w-full">
                      <MdVideoLibrary className="text-subMain h-8 w-8" />
                      <span className="font-medium text-white text-[12px] w-full">
                        Ajouter le photo de votre intervenant
                      </span>
                    </span>
                    <input
                      {...register('video', {
                        onChange: handleFileChangeVideo
                      })}
                      accept="video/*"
                      type="file"
                      name="video"
                      className="opacity-0"
                    />
                  </label>
                </div>
                <div className="w-16 h-16 p-2 bg-main border border-border rounded">
                  {videolink && (
                    <video autoPlay={play} controls className="w-full h-full rounded">
                      <source src={videolink} type="video/mp4" title="titre" />
                    </video>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-border font-semibold text-sm">Photo du teaser</p>
                <div className="w-full">
                  <label className="flex justify-center h-16  transition text-subMain bg-dry border-2 px-6 py-4  border-gray-300 border-dashed  rounded-md  appearance-none cursor-pointer hover:border-gray-main focus:outline-none">
                    <span className="flex items-center w-full">
                      <BsImage className="text-subMain  h-8 w-8" />
                      <span className="font-medium text-white text-[12px] w-full">
                        Ajouter le photo de votre teaser
                      </span>
                    </span>
                    <input
                      {...register('image', {
                        onChange: handleFileChange
                      })}
                      accept="image/*"
                      type="file"
                      name="image"
                      className="opacity-0"
                    />
                  </label>
                </div>
                <div className="w-16 h-16 p-2 bg-main border border-border rounded">
                  <img src={link} alt="" className="w-full h-full object-cover rounded" />
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center my-4">
              <button
                type="submit"
                className="bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6"
              >
                <MdUpload /> Ajouter le teaser
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainModal>
  );
}

export default CreateTeaser;
