import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { backendURLFile } from '../../../../Config/baseUrl';
import { AppDispatch } from '../../../../Store/Store';
import { GetAllemissions } from '../../../../Toolkit/EmissionAction';
import Sidebar from '../../../Admin/Components/Sidebar';

export default function EmissionsCli() {
  const emissions = useSelector((state: any) => state.emission.lists);
  const reverse = [...emissions].reverse();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(GetAllemissions());
  }, []);

  return (
    <Sidebar>
      <div className=" flex justify-center p-4  rounded border-border gap-2">
        <NavLink to="/dashboard/client/">
          <img className="h-36 w-36" src={process.env.PUBLIC_URL + '/Images/logo/logo11.png'} />
        </NavLink>
      </div>
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-6 mt-4">
        {reverse.map((data, index) => (
          <NavLink to={`/dashboard/client/emission/${data.id}`} key={index}>
            <div
              className={`p-6 bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] cursor-pointer max-w-sm mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4 w-full hover:border-2 border-zinc-50`}
            >
              <div className="shrink-0 col-span-4">
                <img className="h-16 w-16" src={`${backendURLFile}/${data.logoPath}`} alt="Logo" />
              </div>
              <div>
                <h2 className="text-base font-semibold text-zinc-950">{data.name}</h2>
              </div>
            </div>
          </NavLink>
        ))}
      </div>
    </Sidebar>
  );
}
