// PaymentForm.tsx
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentIntent } from "@stripe/stripe-js";
import axios from "axios";
import React, { useState } from "react";
import { backendURL } from "../../../Config/baseUrl";

interface PaymentFormProps {
  amount: number;
  handlePayementStrype: (paymentIntent: PaymentIntent) => void;
}
const PaymentForm: React.FC<PaymentFormProps> = ({ amount, handlePayementStrype }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    try {
      const response = await axios.post(`${backendURL}/create-payment-intent`, {
        amount: Math.round(amount * 100)
      });

      const clientSecret = response.data.clientSecret;

      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement!,
        },
      });

      if (error) {
        setErrorMessage(error.message!);
      } else if (paymentIntent) {
        setSuccessMessage("Paiement réussi !");
        handlePayementStrype(paymentIntent)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage("Échec du paiement. Veuillez réessayer.");
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    theme: 'stripe',
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        spacingUnit: '2px',
        borderRadius: '4px'
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
      <div className="text-center mb-6">
        <p className="text-xl font-semibold text-gray-700">Payez  {Math.round(amount * 100)} Ar avec Stripe</p>
      </div>
      <div className="mb-4">
        <CardNumberElement options={CARD_ELEMENT_OPTIONS} className="p-2 border border-gray-300 rounded-md w-full" />
      </div>
      <div className="flex space-x-4 mb-4">
        <CardExpiryElement options={CARD_ELEMENT_OPTIONS} className="p-2 border border-gray-300 rounded-md w-1/2" />
        <CardCvcElement options={CARD_ELEMENT_OPTIONS} className="p-2 border border-gray-300 rounded-md w-1/2" />
      </div>
      <button
        type="submit"
        disabled={!stripe || loading}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
      >
        {loading ? "Chargement ..." : "Payer"}
      </button>
      {errorMessage && <div className="text-red-500 mt-2">{errorMessage}</div>}
      {successMessage && <div className="text-green-500 mt-2">{successMessage}</div>}
    </form>
  )
}

export default PaymentForm;
