import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { backendURL } from '../Config/baseUrl';

interface AdminInfo {
  email: string;
  password: string;
}

interface AdminReg {
  email: string;
  password: string;
  profile: File;
}

interface Clientlog {
  pseudonyme: string;
  password: string;
}

export interface ClientReg {
  pseudonyme: string;
  birthDate: string;
  gender: string;
  email: string;
  password: string;
}

interface InitialState {
  loading: boolean;
  adminInfo: any;
  error: string | null;
  success: boolean;
  message: string | null;
}

export const registerAdmin = createAsyncThunk('auth/register', async (userinfo: AdminReg, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.post(`${backendURL}/admin/subscribe`, userinfo, config);
      return data.Message;
    } else {
      throw new Error('Le jéton est périmé,veuillez reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
export const LoginAdmin = createAsyncThunk('auth/admin', async (userinfo: AdminInfo, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.post(`${backendURL}/admin/login`, userinfo, config);
    localStorage.setItem('role', data.user.role);
    localStorage.setItem('adminName', data.user.pseudonyme);
    localStorage.setItem('adminToken', data.token);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const LoginClient = createAsyncThunk('auth/admin', async (clienInfo: Clientlog, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.post(`${backendURL}/login`, clienInfo, config);
    localStorage.setItem('user', JSON.stringify(data.user));
    localStorage.setItem('clientToken', data.token);
    localStorage.setItem('role', 'USER');
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});


export const RegisterClient = createAsyncThunk(
  'auth/admin',
  async (userinfo: ClientReg, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(`${backendURL}/subscribe`, userinfo, config);
      localStorage.setItem('deviceToken', data.device_token.role);
      localStorage.setItem('clientName', data.user.pseudonyme);
      localStorage.setItem('clientToken', data.token);
      return data;
    } catch (error: any) {
      // Gestion détaillée des erreurs
      if (error.response) {
        // Erreurs de réponse du serveur (4xx, 5xx)
        console.error('Erreur de réponse du serveur:', error.response.data);
        return rejectWithValue(error.response.data.message || 'Erreur de serveur');
      } else if (error.request) {
        // Erreurs de requête (pas de réponse reçue)
        console.error('Erreur de requête:', error.request);
        return rejectWithValue('Erreur de connexion au serveur. Veuillez vérifier votre réseau.');
      } else {
        // Erreurs génériques (ex. problème de configuration)
        console.error('Erreur:', error.message);
        return rejectWithValue(error.message || 'Une erreur inattendue est survenue.');
      }
    }
  }
);


const initialState: InitialState = {
  loading: false,
  adminInfo: {},
  error: null,
  success: false,
  message: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(LoginAdmin.pending, (state: InitialState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(LoginAdmin.fulfilled, (state: InitialState, { payload }: any) => {
      state.loading = false;
      state.message = 'Connexion avec succèss';
    });
    builder.addCase(LoginAdmin.rejected, (state: InitialState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(registerAdmin.pending, (state: InitialState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(registerAdmin.fulfilled, (state: InitialState, { payload }: any) => {
      state.loading = false;
      state.message = payload;
    });
    builder.addCase(registerAdmin.rejected, (state: InitialState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
  }
});

export const { seterrornull, setMessageNull } = authSlice.actions;
export default authSlice.reducer;
