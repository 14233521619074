import { useEffect, useState } from 'react';
import { backendURLFile } from '../../../../../Config/baseUrl';
import Video from './Video';

export function GalleryVideo({ type, movie, data, title, emission }: any) {
  const [activeVid, setActiveVid] = useState(`${backendURLFile}/${movie.files[0]}`);
  const [actTitle, setActTitle] = useState(movie.created_at);
  const [description, setActiveDescription] = useState(movie.legend);
  const arr = data;
  return (
    <div className="flex flex-row max-md:flex-col">
      <Video link={activeVid} title={actTitle} description={description} type={type} />
      <div
        className="w-4/6 max-md:w-full shadow-lg
						overflow-y-scroll flex flex-col
						mt-4 border-slate-800
						border-2 rounded-lg"
        style={{ height: 'min(48vw, 750px)' }}
      >
        <h3 className="text-2xl p-2 font-semibold">{title}</h3>
        <p className="px-2"> Vidéos </p>
        {arr.map((e: any, index: number) => {
          return (
            <div
              className="hover:bg-slate-800 p-2 my-2 mx-1
									border-1 rounded-xl h-5/6
									shadow-xl shadow-gray-30 cursor-pointer"
              onClick={() => {
                setActiveVid(`${backendURLFile}/${e.files[0]}`);
                setActTitle(e.created_at);
                setActiveDescription(e.legend);
              }}
              key={`gallery_${index}`}
            >
              <img
                className="w-1/2 h-20 my-4
										mx-2 float-left"
                src={`${backendURLFile}/${e.cover}`}
              />
              <p
                className="ml-2 font-semibold
										pt-6 pl-8 text-sm"
              >
                {e.created_at}
              </p>
              <p className="px-2">{e.legend}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function GalleryVideoShort({ movie, data, title }: any) {
  /**
   * VARIABLE
   */
  const [activeVid, setActiveVid] = useState(`${backendURLFile}/${movie.post.files[0]}`);
  const [actTitle, setActTitle] = useState(movie.post.created_at);
  const [description, setActiveDescription] = useState(movie.post.legend);
  const arr = data;
  const coverUrl = process.env.PUBLIC_URL + '/Images/images.jpeg';


  return (
    <div className="flex flex-col md:flex-row">
      <Video link={activeVid} title={actTitle} description={description} type="shorts" />
      <div
        className="w-full md:w-4/6 shadow-lg
						overflow-y-auto flex flex-col
						mt-4 border-slate-800
						border-2 rounded-lg"
        style={{ height: 'min(48vw, 750px)' }}
      >
        <p className="px-2"> Vidéos  </p>
        <h3 className="text-2xl p-2 font-semibold">{title}</h3>
        {arr.map((e: any, index: number) => {
          return (
            <div
              className="hover:bg-slate-800 p-2 my-2 mx-1
									border-1 rounded-xl h-5/6
									shadow-xl shadow-gray-30 cursor-pointer"
              onClick={() => {
                setActiveVid(`${backendURLFile}/${e.post.files[0]}`);
                setActTitle(e.post.created_at);
                setActiveDescription(e.post.legend);
              }}
              key={`gallery_${index}`}
            >
              <img
                className="w-1/4 h-15 float-left p-2"
                src={movie.post.cover == undefined ? coverUrl : `${backendURLFile}/${movie.post.cover}`}
              />
              <p className=" font-semibold text-sm">{e.post.created_at}</p>
              <p>{e.post.legend}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function GalleryVideoTeaser({ movie, data, title }: any) {
  const [activeVid, setActiveVid] = useState(`${backendURLFile}/${movie.post.files[0]}`);
  const [actTitle, setActTitle] = useState(movie.post.created_at);
  const [description, setActiveDescription] = useState(movie.producer);
  const [videoDetails, setvideoDetails] = useState(movie);
  const arr = data;
  return (
    <div className="flex flex-col md:flex-row  md:mb-20">
      <Video link={activeVid} title={actTitle} description={description} movie={videoDetails} type={title} />
      <div
        className="w-full md:w-4/6 shadow-lg
						overflow-y-auto flex flex-col
						mt-4 border-slate-800
						border-2 rounded-lg"
        style={{ height: 'min(48vw, 750px)' }}
      >
        <p className="px-2 font-semibold"> Vidéos </p>
        <h3 className="text-2xl p-2 font-semibold text-pink-400">{title}</h3>
        {arr?.map((e: any) => {
          return (
            <div
              className="hover:bg-slate-800 p-2 my-2 mx-1
									border-1 rounded-xl h-5/6
									shadow-xl shadow-gray-30 cursor-pointer"
              onClick={() => {
                setActiveVid(`${backendURLFile}/${e.post.files[0]}`);
                setActTitle(e.post.created_at);
                setActiveDescription(e.post.legend);
                setvideoDetails(e);
              }}
            >
              <img className="w-1/4 h-15 float-left p-2" src={`${backendURLFile}/${e.image}`} />
              <p className=" font-semibold text-sm">{e.post.created_at}</p>
              <p>{title != 'Teasers' ? e.post.title : e.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function GalleryVideoMusic({ movie, data, title }: any) {
  const [activeVid, setActiveVid] = useState(`${backendURLFile}/${movie.post.files[0]}`);
  const [actTitle, setActTitle] = useState(movie.post.created_at);
  const [description, setActiveDescription] = useState(movie.producer);
  const [videoDetails, setvideoDetails] = useState(movie);
  const arr = data;
  return (
    <div className="flex flex-col md:flex-row  md:mb-20">
      <Video link={activeVid} title={actTitle} description={description} movie={videoDetails} type={title} />

      <div
        className="w-full md:w-4/6 shadow-lg
						overflow-y-auto flex flex-col
						mt-4 border-slate-800
						border-2 rounded-lg"
        style={{ height: 'min(48vw, 750px)' }}
      >
        <p className="px-2 font-semibold"> Vidéos </p>
        <h3 className="text-2xl p-2 font-semibold text-pink-400">{title}</h3>
        {arr.map((e: any) => {
          return (
            <div
              className="hover:bg-slate-800 p-2 my-2 mx-1
									border-1 rounded-xl h-5/6
									shadow-xl shadow-gray-30 cursor-pointer"
              onClick={() => {
                setActiveVid(`${backendURLFile}/${e.post.files[0]}`);
                setActTitle(e.post.created_at);
                setActiveDescription(e.post.legend);
                setvideoDetails(e);
              }}
            >
              <img className="w-1/4 h-15 float-left p-2" src={`${backendURLFile}/${e.post.cover}`} />
              <p className=" font-semibold text-sm">{e.post.created_at}</p>
              <p>{title == 'Musiques' && e.artists}</p>
              <p>{title != 'Teasers' && title != 'Musiques' ? e.post.title : e.title}</p>
              <p>{title == 'Musiques' && e.categories}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
