import React, { useState } from 'react';
import { RxEnter } from "react-icons/rx";
import MainModal from "../../../Admin/Components/Modals/MainModal";


interface ModalPayementMobileMoneyProps {
  openModal: boolean;
  setOpenModal: any;
  amount?: number;
  choicePayement: string;
  handlePay: (price: number, num_telephone: string) => void
}

const ModalPayementMobileMoney: React.FC<ModalPayementMobileMoneyProps> = ({ openModal, setOpenModal, amount, choicePayement, handlePay }) => {
  /**
   * VARIABLE
   */
  const [phone, setPhone] = useState<string>()
  const [titulaire, setTitulaire] = useState<string>()

  /**
   * FUNCTION
   */
  const handleSubmit = () => {
    if (amount && phone) {
      handlePay(amount, phone)
    }
  }

  return (
    <MainModal modalOpen={openModal} setModalOpen={setOpenModal}>
      <div className="inline-block align-middle w-auto p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl">
        <p className="text-xl font-extrabold text-yellow-500">Payement {`${choicePayement == 'mvola' ? 'Mvola' : 'Orange Money'}`}</p>
        <form className="flex flex-col py-10">
          <div>
            <label className="text-xl font-extrabold p-3">Montant</label>
            <input
              name="montant"
              required
              type="text"
              value={amount}
              disabled={true}
              className="w-full  text-sm  mt-2 p-5 border border-border rounded text-text bg-main"
            />
          </div>
          <div className="pt-5">
            <label className="text-xl font-extrabold p-3">N Telephone</label>
            <input
              name="phoneNumber"
              required
              value={phone}
              type="text"
              onChange={(event) => setPhone(event.target.value)}
              placeholder="Saisissez ici votre numero de telephone"
              className="w-full  text-sm  mt-2 p-5 border border-border rounded text-text bg-main"
            />
          </div>
          <div className="pt-5">
            <label className="text-xl font-extrabold p-3">Titulaire Mvola</label>
            <input
              name="titulaire"
              required
              type="text"
              value={titulaire}
              onChange={(event) => setTitulaire(event.target.value)}
              placeholder="nom inscript sur votre puce"
              className="w-full  text-sm  mt-2 p-5 border border-border rounded text-text bg-main"
            />
          </div>
          <div className="text-sm w-full mt-4">
            <div
              onClick={handleSubmit}
              className={`p-2 my-4 bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] cursor-pointer bg-white rounded-xl shadow-lg flex justify-center items-center space-x-4 w-full hover:border-2 border-zinc-50`}
            >
              <RxEnter /> Envoyer
            </div>
          </div>
        </form>
      </div>
    </MainModal>
  );
}

export default ModalPayementMobileMoney;
