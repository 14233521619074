interface Data {
  id: number;
  title: string;
  playedBy: string;
  created_at: string;
}

export function sortByCreatedAtDescending(data: Data[]): Data[] {
  return data.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
}
