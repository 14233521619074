import { useEffect, useState } from 'react';
import { FcAddImage, FcGallery, FcVideoCall } from 'react-icons/fc';
import { RiEmotionNormalLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { backendURLFile } from "../../../../Config/baseUrl";
import { AppDispatch } from '../../../../Store/Store';
import { userAddPost } from '../../../../Toolkit/UsAction';
import { currentUser } from '../../../../Toolkit/UserAction';
import MainModal from '../../../Admin/Components/Modals/MainModal';

export default function CurrentUser() {
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: any) => state.user.message);
  //const error = useSelector((state:any)=>state.user.error)
  const user = useSelector((state: any) => state.user.user);
  useEffect(() => {
    dispatch(currentUser());
  }, []);

  const sexeFilter = (sexe: string) => {
    if (sexe === 'M' && sexe !== undefined) {
      return process.env.PUBLIC_URL + '/Images/m.png';
    } else {
      return process.env.PUBLIC_URL + '/Images/f.png';
    }
  };

  const emojis = ['😊', '😎', '🥳', '😍', '🤩', '😂', '🥰', '😜', '😃', '😇'];
  const [openModal, setOpenModal] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState<any>(null);
  const [legend, setLegend] = useState('');

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      setFile(file);
      reader.onload = (e: any) => {
        setSelectedImage(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const sendPost = () => {
    const formData = new FormData();
    formData.append('legend', legend);
    if (file) {
      formData.append('file', file);
    }
    const datafinal = { legend, ...Object.fromEntries(formData.entries()) };
    dispatch(userAddPost(datafinal)).then((res: any) => {
      window.location.reload();
    });
  };

  return (
    <div>
      {user && (
        <>
          <div className="rounded-md bg-main border border-gray-800 p-4">
            <div className="flex flex-row">
              <div>
                <img
                  className="h-16 w-16 max-sm:h-12 rounded-full"
                  src={
                    user.profilePicturePath == null
                      ? sexeFilter(user.gender)
                      : `${backendURLFile}/${user.profilePicturePath}`
                  }
                  alt="avatar"
                />
              </div>
              <div className="ml-5 justify-center cursor-pointer">
                <input
                  type="text"
                  onClick={() => setOpenModal(!openModal)}
                  className="block rounded-full w-full border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300
                                        placeholder:text-stone-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                                        h-14 max-sm:h-10 bg-gradient-to-r from-violet-500 to-fuchsia-500 hover:bg-fuchsia-400 cursor-pointer"
                  placeholder={`Quoi de noeuf ${user.pseudonyme} ?`}
                  hidden
                />
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div
                className="m-2 cursor-pointer flex flex-row hover:bg-dry p-2 rounded-md"
                onClick={() => setOpenModal(!openModal)}
              >
                <FcVideoCall size={30} />
                <span className="text-sm font-semibold mt-2 ml-1 max-sm:hidden">Vidéo en direct</span>
              </div>
              <div
                className="m-2 cursor-pointer flex flex-row hover:bg-dry p-2 rounded-md"
                onClick={() => setOpenModal(!openModal)}
              >
                <FcGallery size={30} />
                <span className="text-sm font-semibold mt-2 ml-1 max-sm:hidden">Photo / vidéo</span>
              </div>
              <div
                className="m-2 cursor-pointer flex flex-row hover:bg-dry p-2 rounded-md"
                onClick={() => setOpenModal(!openModal)}
              >
                <RiEmotionNormalLine size={30} />
                <span className="text-sm font-semibold mt-2 ml-1 max-sm:hidden">Humeur / activité</span>
              </div>
            </div>
          </div>

          <div>
            {openModal && (
              <MainModal modalOpen={openModal} setModalOpen={setOpenModal}>
                <div className="inline-block sm:w-3/5 md:w-3/6 lg:w-3/6 w-full h-[530px] p-4 sticky top-14 overflow-y-auto border border-border bg-main text-white rounded-2xl">
                  <div className="flex flex-col gap-6">
                    {user && (
                      <div className="flex flex-col">
                        <div>
                          <h2 className="center text-xl font-semibold">Creér une publication</h2>
                        </div>
                        <div className="flex flex-row mt-6">
                          <div>
                            <img
                              className="h-16 w-16 rounded-full"
                              src={
                                user.profilePicturePath == null
                                  ? sexeFilter(user.gender)
                                  : `${backendURLFile}/${user.profilePicturePath}`
                              }
                              alt="avatar"
                            />
                          </div>
                          <div className="ml-5 justify-center cursor-pointer">{user.pseudonyme}</div>
                        </div>
                        <div className="mt-4">
                          <textarea
                            className="resize-none border-0 focus:outline-none bg-main p-2 w-full "
                            value={legend}
                            onChange={(e) => setLegend(e.target.value)}
                            placeholder={`Quoi de noeuf ${user.pseudonyme} ?`}
                          ></textarea>
                        </div>
                        {selectedImage === null ? (
                          <label
                            htmlFor="upload"
                            className="mt-3 bg-dry h-2/5 rounded-md w-full p-7 justify-center cursor-pointer"
                          >
                            <div className="mx-64">
                              <FcAddImage size={50} />
                            </div>
                            <h2 className="mt-3">Ajouter des photos/vidéos</h2>
                            <input type="file" id="upload" className="hidden" onChange={handleImageChange} required />
                          </label>
                        ) : (
                          <div className="overflow-y">
                            <img src={selectedImage} alt="Selected" />
                          </div>
                        )}

                        <div className="flex flex-row border-solid border-2 border-dry rounded-md mt-3 pl-6 justify-between">
                          <h3 className="text-lg font-semibold mt-3">Ajouter à votre publication</h3>
                          <div className="flex flex-row">
                            <div className="m-2 cursor-pointer">
                              <FcGallery size={40} />
                            </div>
                            <div className="m-2 cursor-pointer">
                              <RiEmotionNormalLine size={40} />
                            </div>
                          </div>
                        </div>
                        <div className="center bg-dry rounded-md mt-4 p-3 cursor-pointer" onClick={sendPost}>
                          Publier
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </MainModal>
            )}
          </div>
        </>
      )}
    </div>
  );
}
