import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { backendURL, notifURL } from '../Config/baseUrl';

interface Quiz {
  title: string;
}
interface QuizInfoW {
  title: string;
  id: number;
}
interface Question {
  sentence: string;
  correctAnswer: string;
  possibleAnswer: Array<string>;
}

interface QuestionwithID {
  question: Question;
  id: number;
}

export const CreateQuizW = createAsyncThunk('quiz/add', async (QuizInfo: Quiz, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.post(`${backendURL}/quiz`, QuizInfo, config);
      try {
        await axios.post(`${notifURL}/notif/quiz`).then((res) => {
          return res;
        });
      } catch (error) {
        return error;
      }
      return data.message;
    } else {
      throw new Error('Le jéton est périmé,veuillez reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const GetAllQuiz = createAsyncThunk('quiz/all', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/quizzes`, config);
    /*
        try {
          await axios.post(`${notifURL}/notif/quiz`).then((res:any)=>{
            return res
          })
        } catch (error) {
          throw new Error("Erreur lors d'envoie de notification");
        }
        */
    // Fetch questions for each quiz
    const quizzesWithQuestions = await Promise.all(
      data.quizzes.map(async (quiz: any) => {
        const { data: quizData } = await axios.get(`${backendURL}/quiz/${quiz.id}`, config);
        return { ...quiz, questions: quizData.quiz.questions };
      })
    );

    return quizzesWithQuestions;
  } catch (error: any) {
    throw new Error(error.message);
  }
});

export const QuizUpdate = createAsyncThunk('quiz/update', async (quizInfo: QuizInfoW, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.patch(`${backendURL}/quiz/update/${quizInfo.id}`, { title: quizInfo.title }, config);
      return data;
    } else {
      throw new Error('Le jéton est périmé,veuillez reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const GetOnequiz = createAsyncThunk('quiz/one', async (id: number, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/quiz/${id}`, config);
    return data.quiz;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
export const PatchUserPlayed = createAsyncThunk('quiz/userplayed', async (id, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.patch(`${backendURL}/quiz/played/${id}`, config);
      return data.message;
    } else {
      throw new Error('Le jéton est périmé,veuillez reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
export const AddQuestion = createAsyncThunk('quiz/addquestion', async (questionInfo: QuestionwithID) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.post(`${backendURL}/question/${questionInfo.id}`, questionInfo.question, config);
      return data.message;
    } else {
      throw new Error('Le jéton est périmé,veuillez reconnecter');
    }
  } catch (error: any) {
    return error.message;
  }
});
export const QuizDelete = createAsyncThunk('quiz/delete', async (id: number, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.delete(`${backendURL}/quiz/delete/${id}`, config);
      return data;
    } else {
      throw new Error('Le jéton est périmé,veuillez reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
export const QuestionDelete = createAsyncThunk('question/delete', async (id: number, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.delete(`${backendURL}/question/delete/${id}`, config);
      return data;
    } else {
      throw new Error('Le jéton est périmé,veuillez reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

interface InitialState {
  loading: boolean;
  lists: Array<Quiz>;
  error: null | string;
  message: null | string;
  quiz: Quiz | null;
  questions: Array<string> | null;
}

const initialState: InitialState = {
  loading: false,
  lists: [],
  error: null,
  message: null,
  quiz: null,
  questions: []
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllQuiz.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllQuiz.fulfilled, (state, action) => {
        state.loading = false;
        state.lists = action.payload;
      })
      .addCase(GetAllQuiz.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(GetOnequiz.fulfilled, (state, action) => {
        // Find the index of the quiz in the state based on the id
        const quizIndex = state.lists.findIndex((quiz: any) => quiz.id === action.payload.id);
        // Replace the quiz with its questions
        state.lists[quizIndex] = action.payload;
      });

    builder.addCase(CreateQuizW.pending, (state, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(CreateQuizW.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.message = 'Création du quiz avec accèss';
    });
    builder.addCase(CreateQuizW.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(PatchUserPlayed.pending, (state, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(PatchUserPlayed.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.message = payload;
    });
    builder.addCase(PatchUserPlayed.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(AddQuestion.pending, (state, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(AddQuestion.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.message = 'Ajout de question avec succèss';
    });
    builder.addCase(AddQuestion.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(QuizDelete.pending, (state, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(QuizDelete.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.message = 'Suppression de votre quiz avec succéss';
    });
    builder.addCase(QuizDelete.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(QuestionDelete.pending, (state, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(QuestionDelete.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.message = 'Suppression de votre question avec succéss';
    });
    builder.addCase(QuestionDelete.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(QuizUpdate.pending, (state, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(QuizUpdate.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.message = 'Mise à jour de votre quiz avec succèss';
    });
    builder.addCase(QuizUpdate.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
  }
});

export const { seterrornull, setMessageNull } = quizSlice.actions;
export default quizSlice.reducer;
