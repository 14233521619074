// ModalPayement.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/Store";
import { IMovie } from "../../../../Toolkit/MovieAction";
import { ISubscription, getSubscriptionLists } from "../../../../Toolkit/SubcriptionTransactionAction";
import MainModal from "../../../Admin/Components/Modals/MainModal";

interface ModalPayementProps {
  openModal: boolean;
  setOpenModal: any;
  detailMovie: IMovie;
  handleChoiceSubscription: (subscription: ISubscription) => void;
}

const ModalPayement: React.FC<ModalPayementProps> = ({ openModal, setOpenModal, handleChoiceSubscription, detailMovie }) => {
  /**
   * REDUX
   */
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: any) => state.subscriptionTransaction.loading);
  const subscriptionList: ISubscription[] = useSelector((state: any) => state.subscriptionTransaction.subscriptionList);

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    dispatch(getSubscriptionLists());
  }, [dispatch]);

  /**
   * FUNCTION
   */
  const handlePayment = (subscription: ISubscription) => {
    handleChoiceSubscription(subscription);
  };

  return (
    <MainModal modalOpen={openModal} setModalOpen={setOpenModal}>
      <div className="inline-block w-full sm:w-4/5 md:w-3/5 lg:w-2/5 align-middle p-6 sm:p-10 overflow-y-auto border border-border bg-main text-white rounded-2xl">
        <p className="text-2xl sm:text-3xl md:text-4xl font-extrabold text-yellow-500 mb-6">Abonnement streaming</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
          {subscriptionList && subscriptionList.map((subscription: ISubscription) => (
            <div key={subscription.id} className=" p-4 rounded-xl shadow-lg flex flex-col items-center space-y-4 cursor-pointer hover:border-2 border-zinc-50">
              <p className="text-2xl font-extrabold p-3">{subscription.type}</p>
              <p className="text-lg font-extrabold">{`${subscription.type === "Film" ? Math.round(detailMovie?.price) : Math.round(subscription.price)} ar TTC`}</p>
              <div
                onClick={() => handlePayment({ ...subscription, price: subscription.type === "Film" ? detailMovie?.price : subscription.price })}
                className="w-full text-center bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] text-white py-2 px-4 rounded-md"
              >
                <h2 className="text-base font-semibold">Abonnee</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MainModal>
  );
}

export default ModalPayement;
