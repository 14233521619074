import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { HiPlusCircle } from 'react-icons/hi';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import Sidebar from '../Components/Sidebar';
import { AppDispatch } from '../../../Store/Store';
import { GetAllemissions, setMessageNull, seterrornull } from '../../../Toolkit/EmissionAction';
import TableEmission from '../Components/TableEmission';
import CreateEmission from '../Components/Modals/Create/CreateEmission';

function Emissions() {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const emissions = useSelector((state: any) => state.emission.lists);
  const message = useSelector((state: any) => state.emission.message);
  const error = useSelector((state: any) => state.emission.error);
  useEffect(() => {
    dispatch(GetAllemissions());
  }, [message, dispatch]);
  return (
    <>
      <Sidebar>
        <CreateEmission modalOpen={openModal} setModalOpen={setOpenModal} />
        <div className="flex flex-col gap-6">
          <div className="flex-btn gap-2">
            <h2 className="text-xl font-bold">Emissions</h2>
            <button
              onClick={() => setOpenModal(true)}
              className="bg-main font-medium transitions hover:bg-subMain border border-subMain text-white py-3 px-6 rounded flex items-center gap-2"
            >
              <HiPlusCircle /> Ajouter
            </button>
          </div>
          {emissions && emissions.length > 0 && <TableEmission data={emissions} admin={true} />}
        </div>
      </Sidebar>
      {message && (
        <div
          className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{message}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(setMessageNull())} />
          </div>
        </div>
      )}
      {error && (
        <div
          className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{error}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(seterrornull())} />
          </div>
        </div>
      )}
    </>
  );
}

export default Emissions;
