import React from 'react';
import { Link } from 'react-router-dom';
import Pub from '../../Components/Pub';

function Footer() {
  const Links = [
    {
      title: 'zamandresyprod',
      links: [
        { name: 'Home', link: '/' },
        {
          name: 'Qui sommes-nous',
          link: '/about-us'
        },
        {
          name: 'Nos contacts',
          link: '/contact-us'
        }
      ]
    },
    {
      title: 'Nos emissions',
      links: [
        { name: 'Starvan', link: '#' },
        {
          name: 'Starvan live',
          link: '#'
        },
        {
          name: 'Dago life',
          link: '#'
        },
        {
          name: 'Manavatsava',
          link: '#'
        },
        {
          name: 'Fantsy bobaka',
          link: '#'
        }
      ]
    }
  ];
  return (
    <div className="bg-gradient-to-b from-main to-dry py-4 bprder:t-2 border-black overflow-hidden text-align-center  ">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-3 md:grid-cols-7 xl:grid-cols-12 gap-5 sm:gap-9 lg:gap-11 xl:gap-7 py-10 justify-between flex-col-contact">
          {Links.map((link, index) => (
            <div key={index} className="col-span-1 md:col-span-2 lg:col-span-3 pb-3.5 sm:pb-0">
              <h3 className="text-md lg:leading-7 font-bold mb-4 sm:mb-5 lg:mb-6 pb-0.5 break-word">{link.title}</h3>
              <ul className="text-sm flex flex-col space-y-3">
                {link.links.map((text, index) => (
                  <li key={index} className="flex items-baseline">
                    <Link to={text.link} className="text-white inline-block w-full hover:text-subMain">
                      {text.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <div className="pb-3.5 sm:pb-0 col-span-1 md:col-span-2 lg:col-span-3">
            <Link to="/">
              <img src="/assets/images/logo.png" alt="logo" className="w-2/4 object-contain h-12 d-inline-flex" />
            </Link>
            <p className="leading-7 text-sm text-white mt-3">
              <span>Antananarivo Madagascar</span>
              <br />
              <span>Tel : +261 32 42 394 73</span>
              <br />
              <span className="font-bold">
                <span className="text-subMain">Email</span> :<br /> contact@zamandresyprod.mg
                <br />
                commercial@zamandresyprod.mg
                <br />
                prod@zamandresyprod.mg
              </span>
            </p>
            <Link to={`/politic`} className="inline-block w-fulltext-subMain italic mt-4 text-yellow-400">
              Politique de confidentialité
            </Link>
          </div>
          <div className="pb-3.5 sm:pb-0 col-span-1 md:col-span-2 lg:col-span-3">
            <Pub />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
