import { Outlet, Navigate } from 'react-router-dom';

const AdminPrivateRoutes = () => {
  const token = localStorage.getItem('adminToken') ? localStorage.getItem('adminToken') : null;
  const role = localStorage.getItem('role') ? localStorage.getItem('role') : null;

  function isAdmin(token: string | null, admin: string | null): boolean {
    return !!token && admin === 'ADMIN';
  }

  if (!token) {
    return (
      <>
        <Navigate to="/dashboard/login" />
      </>
    );
  } else {
    return isAdmin(token, role) ? <Outlet /> : <Navigate to="/login" />;
  }
};

export default AdminPrivateRoutes;
