import { useEffect, useState } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AppDispatch } from '../../Store/Store';
import { GetAllAnimators } from '../../Toolkit/AnimatorAction';
import { setMessageNull } from '../../Toolkit/authAction';
import { GetAllevents } from '../../Toolkit/EventAction';
import { GetAllposts } from '../../Toolkit/PostAction';
import Sidebar from '../Admin/Components/Sidebar';

function DashboardCli() {
  const dispatch = useDispatch<AppDispatch>();
  const [showDiv, setShowDiv] = useState(true);
  const message = useSelector((state: any) => state.auth.message);
  const posts = useSelector((state: any) => state.post.lists);
  const totalpost = useSelector((state: any) => state.post.total);
  const dagolife = posts.filter((post: any) => post.tvShowId == 3).length;
  const fantsybobaka = posts.filter((post: any) => post.tvShowId == 2).length;
  const manavatsava = posts.filter((post: any) => post.tvShowId == 4).length;
  const starvan = posts.filter((post: any) => post.tvShowId == 1).length;
  const events = useSelector((state: any) => state.event.lists);
  const animators = useSelector((state: any) => state.animator.lists);
  const datapost = {
    dagolife,
    fantsybobaka,
    manavatsava,
    starvan
  };

  const DashboardData = [
    {
      title: 'Nos émissions',
      backUrl: '/Images/logo/logo2.png',
      back: 'bg-gradient-to-r from-[#0090F8] to-[#006BB9]',
      link: '/dashboard/client/emission'
    },
    {
      title: 'Events',
      back: 'bg-gradient-to-r from-[#5C00B9] to-[#F31F5F]',
      backUrl: '/Images/logo/logo4.png',
      link: '/dashboard/client/event'
    },
    {
      title: 'Shorts',
      backUrl: '/Images/logo/logo5.png',
      back: 'bg-gradient-to-r from-[#0090F8] to-[#006BB9]',
      link: '/dashboard/client/short'
    },
    {
      title: 'Trailers',
      backUrl: '/Images/logo/logo6.png',
      back: 'bg-gradient-to-r from-[#0090F8] to-[#006BB9]',
      link: '/dashboard/client/teaser'
    },
    {
      title: 'Clips',
      back: 'bg-gradient-to-r from-[#5C00B9] to-[#F31F5F]',
      backUrl: '/Images/logo/logo7.png',
      link: '/dashboard/client/music'
    },
    {
      title: 'Us',
      backUrl: '/Images/logo/logo3.png',
      back: 'bg-gradient-to-r from-[#0090F8] to-[#006BB9]',
      link: '/dashboard/client/nous'
    }
  ];
  useEffect(() => {
    dispatch(GetAllposts());
    dispatch(GetAllevents());
    dispatch(GetAllAnimators());
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowDiv(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <Sidebar>
        <div className=" flex justify-center p-4  rounded border-border gap-2">
          <img className="h-36 w-36" src={process.env.PUBLIC_URL + '/Images/logo/logo11.png'} />
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
          {DashboardData.map((data, index) => (

            <NavLink to={data.link} key={index}>
              <div
                className={`p-6 ${data.back} cursor-pointer max-w-sm mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4 w-11/12 hover:border-2 border-zinc-50`}
              >
                <div className="shrink-0 col-span-3">
                  <img className="h-16 w-16" src={process.env.PUBLIC_URL + data.backUrl} alt="Logo" />
                </div>
                <div>
                  <h2 className="text-base font-semibold text-zinc-950">{data.title}</h2>
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      </Sidebar>
      {message && (
        <div>
          {showDiv && (
            <div
              className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
              role="alert"
            >
              <div className="text-[12px] flex gap-8 items-center">
                <p className="text-gray-100">Connexion avec succèss</p>
              </div>
              <div className="text-white cursor-pointer">
                <IoIosCloseCircleOutline onClick={() => dispatch(setMessageNull())} />
              </div>
            </div>
          )}

          <div
            className="fixed right-3 top-24 w-56 bg-zinc-900 bg-opacity-70 p-4 text-base text-danger-700 gap-4 flex justify-center hover:bg-opacity-90"
            role="alert"
          >
            <div className="flex flex-row">
              <div>
                <img className="h-14 w-14" src={process.env.PUBLIC_URL + '/Images/casque.png'} />
              </div>
              <div className="text-[12px] flex flex-col">
                <p className="text-gray-100 text-lg font-semibold">Film intégrale</p>
                <div className="flex flex-row justify-between">
                  <p className="text-teal-500 hover:text-teal-200 cursor-pointer">
                    <NavLink to="/dashboard/client/teaser">Voir</NavLink>
                  </p>
                  <p
                    className="text-red-700 hover:text-red-500 cursor-pointer"
                    onClick={() => dispatch(setMessageNull())}
                  >
                    Fermer
                  </p>
                </div>
              </div>
            </div>
            <span className="z-50 bg-red-500 text-white text-xs font-semibold px-2 rounded-full absolute top-0 right-52">
              1
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default DashboardCli;
