import { MdUpload } from 'react-icons/md';
import { ImPlay } from 'react-icons/im';
import { BsImage } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useState, ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IoIosCloseCircleOutline } from 'react-icons/io';

import MainModal from '../../Modals/MainModal';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../Store/Store';
import { CirclesWithBar } from 'react-loader-spinner';
import { UpdatePost } from '../../../../../Toolkit/PostAction';

const schema = yup
  .object({
    legend: yup.string().required(),
    tvShowId: yup.number().required(),
    id: yup.number().required()
  })
  .required();
type FormData = yup.InferType<typeof schema>;

function UpdateStarvan({ modalOpen, setModalOpen, movie }: any) {
  const error = useSelector((state: any) => state.post.error);
  const loading = useSelector((state: any) => state.post.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const HandleUpdateStarvan = async (data: FormData) => {
    try {
      const { legend, tvShowId, ...rest } = data;
      const postInfo = { legend, tvShowId };
      const id = data.id;
      dispatch(UpdatePost({ postInfo, id })).then((res) => {
        setModalOpen(false);
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <>
      <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <div className="inline-block sm:w-4/5 md:w-4/5 lg:w-2/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl">
          <div className="flex flex-col gap-6 relative">
            {loading && (
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="#E90064"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  outerCircleColor=""
                  innerCircleColor=""
                  barColor=""
                  ariaLabel="circles-with-bar-loading"
                />
              </div>
            )}
            <form className="w-full" onSubmit={handleSubmit(HandleUpdateStarvan)}>
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Titre de votre publication</label>
                <input
                  defaultValue={movie.legend}
                  {...register('legend')}
                  name="legend"
                  type="text"
                  placeholder="Ici le titre de votre quiz"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.legend?.message}</p>
              </div>
              <div className="text-sm w-full hidden">
                <label className="text-border font-semibold">Titre de votre publication</label>
                <input
                  defaultValue={movie.legend}
                  {...register('tvShowId')}
                  name="tvShowId"
                  value={4}
                  type="number"
                  placeholder="Ici le titre de votre quiz"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.tvShowId?.message}</p>
              </div>
              <div className="text-sm w-full hidden">
                <label className="text-border font-semibold">Titre de votre publication</label>
                <input
                  defaultValue={movie.id}
                  {...register('id')}
                  name="id"
                  type="number"
                  placeholder="Ici le titre de votre quiz"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.id?.message}</p>
              </div>
              <div className="flex justify-end items-center my-4">
                <button
                  type="submit"
                  className="bg-subMain transitions hover:bg-dry border border-subMain text-white py-2  rounded w-full  flex-rows gap-6"
                >
                  <MdUpload /> Modifier la publication
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainModal>
      {error && (
        <div
          className="absolute -left-80 bottom-40 z-50 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">Une erreur a été survenu lors du mise à jour de votre publication</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline />
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateStarvan;
