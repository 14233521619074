import React from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { BiTime } from 'react-icons/bi';

function FlexMovieItem({ movie }: any) {
  return (
    <>
      <div className="flex items-center gap-2">
        <span className="text-sm text-subMain font-bold">{movie.category}</span>
      </div>
      <div className="flex items-center gap-2 pr-2">
        <FaRegCalendarAlt className="text-subMain w-3 h-3" />
        <span className="text-sm font-medium">{movie.created_at}</span>
      </div>
    </>
  );
}

export default FlexMovieItem;
