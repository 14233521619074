import { useState } from 'react';
import { FaCloudDownloadAlt, FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IMovie } from "../../../Toolkit/MovieAction";
import DeleteMovie from "./Modals/Delete/DeleteMovie";
import UpdateMovie from "./Modals/Update/UpdateMovie";

interface RowsMovieProps {
  movie: IMovie;
  admin: boolean;
  handleDeleteMovie: (id: number) => void
}
const RowsMovie: React.FC<RowsMovieProps> = ({ movie, admin, handleDeleteMovie }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalUpdate, setModalOpenUpdate] = useState<boolean>(false);
  const text = 'text-sm text-left leading-6 whitespace-nowrap px-5 py-3';
  return (
    <>
      <UpdateMovie modalOpen={openModalUpdate} setModalOpen={setModalOpenUpdate} movie={movie} />
      <DeleteMovie modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} movie={movie} handleDeleteMovie={handleDeleteMovie} />
      <tr >
        <td className={`${text}`}>{movie?.title}</td>
        <td className={`${text} text-[12px] text-subMain font-bold truncate `}>
          {movie.description && movie?.description.length > 15 ? movie?.description.slice(0, 25) + '...' : movie?.description}</td>
        <td className={`${text}`}>{movie?.category?.name}</td>
        <td className={`${text}`}>{movie?.genres?.name}</td>
        <td className={`${text}`}>{movie?.director}</td>
        <td className={`${text}`}>{movie?.productor}</td>
        <td className={`${text}`}>{movie?.price} Ar</td>
        <td className={`${text}`}>{movie?.isFree == 1 ? 'Oui' : 'Non'}</td>
        <td className={`${text}`}>{movie?.release_date}</td>
        <td className={`${text}`}>{movie?.view_count}</td>
        <td className={`${text} float-left flex-rows gap-2`}>
          {admin ? (
            <>
              <button
                onClick={() => setModalOpenUpdate(true)}
                className="border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2"
              >
                <FaEdit className="text-green-500" />
              </button>
              <button
                onClick={() => setOpenModalDelete(true)}
                className="bg-subMain text-white rounded flex-colo w-7 h-7"
              >
                <MdDelete />
              </button>
            </>
          ) : (
            <>
              <button className="border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2">
                Download <FaCloudDownloadAlt className="text-green-500" />
              </button>
              {/* <Link to={`/teasers/${teaser?.name}`}>
                <button className="bg-subMain text-white rounded flex-colo w-6 h-6">
                  <GoEye />
                </button>
              </Link> */}
            </>
          )}
        </td>
      </tr>
    </>
  );
}

export default RowsMovie;
