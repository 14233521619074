import React from 'react';
import { backendURLFile } from "../../../../Config/baseUrl";
import { VideoData } from "../../../../shared/indexBb/indexedDBHelper";

interface MovieItemsDownloadedProps {
  videoData: VideoData,
  handleDetailMovie: (videoData: VideoData) => void
}
const MovieItemsDownloaded: React.FC<MovieItemsDownloadedProps> = ({ videoData, handleDetailMovie }) => {

  /**
   * VARIANLE
   */
  const { movie } = videoData

  return (
    <div className="cursor-pointer max-sm:mx-8 shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105" onClick={() => handleDetailMovie(videoData)}>
      <img
        style={{ height: 110 }}
        className="w-full object-cover"
        src={`${backendURLFile}/${movie.cover_image}`}
      />
      <ul key={movie.id} className="text-center list-none p-2 bg-white">
        <li className="font-semibold  text-black text-base">{movie.title}</li> {/* Changer la couleur du texte ici */}
        <li className="text-gray-500 text-sm">{movie.release_date}</li>
      </ul>
    </div>
  );
}

export default MovieItemsDownloaded;
