import RowsEmission from './RowsEmission';

const Head = 'text-xs text-left text-main font-semibold px-6 py-2 uppercase';

function TableEmission({ data, admin }: any) {
  return (
    <div className="overflow-x-hidden relative w-full h-[400px] overflow-y-auto">
      <table className="w-full table-auto border border-border divide-y divide-border">
        <thead>
          <tr className="bg-dryGray">
            <th scope="col" className={`${Head}`}>
              Logo
            </th>
            <th scope="col" className={`${Head}`}>
              Nom
            </th>
            <th scope="col" className={`${Head}`}>
              Description
            </th>
            <th scope="col" className={`${Head}`}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-main divide-y divide-gray-800">
          {data.map((emission: any, i: number) => (
            <RowsEmission emission={emission} key={i} admin={admin} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableEmission;
