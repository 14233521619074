import { yupResolver } from "@hookform/resolvers/yup";
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { BsImage } from "react-icons/bs";
import { MdUpload, MdVideoLibrary } from "react-icons/md";
import { CirclesWithBar } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import * as yup from 'yup';
import { AppDispatch } from "../../../../../Store/Store";
import { ICategoryMovie, IGenreMovie, createMovie, getCategoryLists, getGenreList } from "../../../../../Toolkit/MovieAction";
import MainModal from "../MainModal";

interface CreateMovieProps {
  modalOpen: boolean;
  setModalOpen: any
}

const schema = yup
  .object({
    title: yup.string().required(),
    description: yup.string().required(),
    release_date: yup.date().required(),
    duration: yup.string().required(),
    director: yup.string().required(),
    productor: yup.string().required(),
    price: yup.number().required(),
    cover_image: yup.mixed().label('Image de couverture'),
    filename: yup.mixed().label('Fichier vidéo'),
    // teaserId: yup.number().required(),
    category_movie_id: yup.number().required(),
    genre_movie_id: yup.number().required(),
    isFree: yup.bool().required()
  }).required()
type FormData = yup.InferType<typeof schema>;

const CreateMovie: React.FC<CreateMovieProps> = ({ modalOpen, setModalOpen }) => {
  /**
  * REDUX
  */
  const dispatch = useDispatch<AppDispatch>();
  const categorys = useSelector((state: any) => state.movie.categoryLists);
  const loading = useSelector((state: any) => state.movie.loading);
  const genreLists = useSelector((state: any) => state.movie.genreList);
  const error = useSelector((state: any) => state.movie.error);


  /**
  * LIFECYCLE
  */
  useEffect(() => {
    dispatch(getCategoryLists());
    dispatch(getGenreList())
  }, [])

  /**
   * VARIABLE
   */
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });
  const [videolink, setvideolink] = useState<string>('');
  const [link, setLink] = useState<string>('');


  /**
   * FUNCTION
   */
  const handleCreateMovie = (data: FormData) => {
    try {
      const filename = (data.filename as File[])[0];
      const cover_image = (data.cover_image as File[])[0];

      const formData = new FormData();

      if (cover_image) {
        formData.append('cover_image', cover_image);
      }

      if (filename) {
        formData.append('filename', filename);
      }

      const datafinal = { ...data, ...Object.fromEntries(formData.entries()) };

      dispatch(createMovie(datafinal)).then((res: any) => {
        if (!error) {
          setModalOpen(false)
          reset()
          setvideolink('');
          setLink('')
        }
      });

    } catch (error) {
      return error;
    }

  }

  const handleFileChangeVideo = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setvideolink(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setLink(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-block sm:w-4/5 md:w-4/5 lg:w-4/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl">
        <div className="flex flex-col gap-6 relative">
          <form className="w-full" onSubmit={handleSubmit(handleCreateMovie)}>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Titre du film</label>
                <input
                  {...register('title')}
                  name="title"
                  type="text"
                  placeholder="Titre"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.title?.message}</p>
              </div>

              <div className="text-sm w-full">
                <label className="text-border font-semibold">Description du film</label>
                <textarea
                  {...register('description')}
                  name="description"
                  placeholder="Description"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                ></textarea>
                <p className="text-red-600 text-left">{errors.description?.message}</p>
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Durée</label>
                <input
                  required
                  {...register('duration')}
                  name="duration"
                  type="text"
                  placeholder="Exemple 1h50min"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.duration?.message}</p>
              </div>
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Prix</label>
                <input
                  required
                  {...register('price')}
                  name="price"
                  type="number"
                  placeholder=""
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                  min={0}
                />
                <p className="text-red-600 text-left">{errors.price?.message}</p>
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-3 gap-6">
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Date de sortie</label>
                <input
                  {...register('release_date')}
                  name="release_date"
                  type="date"
                  placeholder=""
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.release_date?.message}</p>
              </div>
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Réalisateur</label>
                <input
                  {...register('director')}
                  name="director"
                  type="text"
                  placeholder="Réalisateur..."
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.director?.message}</p>
              </div>
              <div className="text-sm w-full">
                <label className="text-border font-semibold">Producteur</label>
                <input
                  {...register('productor')}
                  name="productor"
                  type="text"
                  placeholder="Producteur..."
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.productor?.message}</p>
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full">
                <label className="text-subMain font-semibold">Catégorie</label>
                <select
                  {...register('category_movie_id')}
                  name="category_movie_id"
                  className="w-full h-16 mt-2 px-6 py-4 text-text bg-dry border border-border rounded"
                >
                  {categorys && categorys.map((cat: ICategoryMovie, i: number) => (
                    <option key={i} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
                <p className="text-red-600 text-left">{errors.category_movie_id?.message}</p>
              </div>
              <div className="text-sm w-full">
                <label className="text-subMain font-semibold">Genre</label>
                <select
                  {...register('genre_movie_id')}
                  name="genre_movie_id"
                  className="w-full h-16 mt-2 px-6 py-4 text-text bg-dry border border-border rounded"
                >
                  {genreLists && genreLists.map((genre: IGenreMovie, i: number) => (
                    <option key={i} value={genre.id}>
                      {genre.name}
                    </option>
                  ))}
                </select>
                <p className="text-red-600 text-left">{errors.genre_movie_id?.message}</p>
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full">
                <label className="text-subMain font-semibold">Gratuis</label>
                <select
                  {...register('isFree')}
                  name="isFree"
                  className="w-full h-16 mt-2 px-6 py-4 text-text bg-dry border border-border rounded"
                >
                  <option value={0}>
                    Non
                  </option>
                  <option value={1}>
                    Oui
                  </option>
                </select>
                <p className="text-red-600 text-left">{errors.isFree?.message}</p>
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="flex flex-col gap-2">
                <p className="text-border font-semibold text-sm">Video teaser</p>
                <div className="w-full">
                  <label className="flex justify-center h-16  transition text-subMain bg-dry border-2 px-6 py-4  border-gray-300 border-dashed  rounded-md  appearance-none cursor-pointer hover:border-gray-main focus:outline-none">
                    <span className="flex items-center w-full">
                      <MdVideoLibrary className="text-subMain h-8 w-8" />
                      <span className="font-medium text-white text-[12px] w-full">
                        Ajouter la vidéo
                      </span>
                    </span>
                    <input
                      {...register('filename', {
                        onChange: handleFileChangeVideo
                      })}
                      accept="video/*"
                      type="file"
                      name="filename"
                      className="opacity-0"
                    />
                  </label>
                </div>
                <div className="w-16 h-16 p-2 bg-main border border-border rounded">
                  {videolink && (
                    <video controls className="w-full h-full rounded">
                      <source src={videolink} type="video/mp4" title="titre" />
                    </video>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-border font-semibold text-sm">Photo du teaser</p>
                <div className="w-full">
                  <label className="flex justify-center h-16  transition text-subMain bg-dry border-2 px-6 py-4  border-gray-300 border-dashed  rounded-md  appearance-none cursor-pointer hover:border-gray-main focus:outline-none">
                    <span className="flex items-center w-full">
                      <BsImage className="text-subMain  h-8 w-8" />
                      <span className="font-medium text-white text-[12px] w-full">
                        Ajouter le photo de converture
                      </span>
                    </span>
                    <input
                      {...register('cover_image', {
                        onChange: handleFileChange
                      })}
                      accept="image/*"
                      type="file"
                      name="cover_image"
                      className="opacity-0"
                    />
                  </label>
                </div>
                <div className="w-16 h-16 p-2 bg-main border border-border rounded">
                  <img src={link} alt="" className="w-full h-full object-cover rounded" />
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center my-4">
              <button
                type="submit"
                className="bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6"
                disabled={loading}
              >
                {
                  loading ? <CirclesWithBar
                    height="30"
                    width="30"
                    color="#007aff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel="circles-with-bar-loading"
                  /> : <MdUpload />
                }
                Ajouter le film
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainModal>
  );
}

export default CreateMovie;
