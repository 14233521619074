import RowsEvent from './RowsEvent';
import RowsTeaser from './RowsTeaser';

const Head = 'text-xs text-left text-main font-semibold px-6 py-2 uppercase';
const Text = 'text-sm text-left leading-6 whitespace-nowrap px-5 py-3';

function TableTeaser({ data, admin, emission }: any) {
  return (
    <div className="overflow-x-hidden relative w-full h-[400px] overflow-y-auto">
      <table className="w-full table-auto border border-border divide-y divide-border">
        <thead>
          <tr className="bg-dryGray">
            <th scope="col" className={`${Head}`}>
              Affiche
            </th>
            <th scope="col" className={`${Head}`}>
              titre
            </th>
            <th scope="col" className={`${Head}`}>
              Date
            </th>
            <th scope="col" className={`${Head}`}>
              Place
            </th>
            <th scope="col" className={`${Head}`}>
              Date de pulication
            </th>
            {/* 
                    <th scope="col" className={`${Head}`}>
                        Language
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Year
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Hours
                    </th>
                    **/}
            <th scope="col" className={`${Head}`}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-main divide-y divide-gray-800">
          {data.map((teaser: any, i: number) => (
            <RowsTeaser teaser={teaser} key={i} admin={admin} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableTeaser;
