import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { backendURL, notifURL } from '../Config/baseUrl';

interface post {
  file1?: File;
  file2?: File;
  legend: string;
  tvShow: number;
}

interface postInfo {
  legend: string;
  tvShowId: number;
}

interface postInfoWithId {
  postInfo: postInfo;
  id: number;
}

interface postState {
  loading: boolean;
  post: any | null;
  starvan: Array<post>;
  fantsybobaka: Array<post>;
  manavatsava: Array<post>;
  dagolife: Array<post>;
  lists: Array<post>;
  error: string | null;
  message: string | null;
  total: null | number;
  filterWord: string;
}
export const GetOnePost = createAsyncThunk('posts/one', async (id: number) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/user/post/{id}`, config);
    return data.post;
  } catch (error: any) {
    return error.message;
  }
});
export const GetAllposts = createAsyncThunk('posts/all', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/posts`, config);
    return data.posts;
  } catch (error: any) {
    return error.message;
  }
});
export const GetAllPostDagolife = createAsyncThunk('posts/alldagolife', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/posts`, config);
    return data.posts;
  } catch (error: any) {
    return error.message;
  }
});
export const GetAllPostFantsy = createAsyncThunk('posts/allfantsy', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/posts`, config);
    return data.posts;
  } catch (error: any) {
    return error.message;
  }
});
export const GetAllPostManavatsava = createAsyncThunk('posts/allManavatsava', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/posts`, config);
    return data.posts;
  } catch (error: any) {
    return error.message;
  }
});
export const GetAllPostStarvan = createAsyncThunk('posts/allstarvan', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/posts`, config);
    return data.posts;
  } catch (error: any) {
    return error.message;
  }
});
export const GetAllPostStarvanlive = createAsyncThunk('posts/allstarvanlive', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/posts`, config);
    return data.posts.reverse();
  } catch (error: any) {
    return error.message;
  }
});
export const Createpost = createAsyncThunk('posts/add', async (postInfo: post, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.post(`${backendURL}/post`, postInfo, config);
      try {
        await axios.post(`${notifURL}/notif/post`).then((res) => {
          return res;
        });
      } catch (error) {
        return error;
      }
      return data.message;
    } else {
      throw new Error("Le jeton de l'admin est perimé,veuillez deconnecter et reconnecter à nouveau");
    }
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});
export const UpdatePost = createAsyncThunk('posts/update', async (postinfo: postInfoWithId) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.put(`${backendURL}/post/update/${postinfo.id}`, postinfo.postInfo, config);
      return data.message;
    } else {
      throw new Error('Le jéton est perimé');
    }
  } catch (error: any) {
    return error.message;
  }
});
export const DeletePost = createAsyncThunk('posts/delete', async (id: number) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.delete(`${backendURL}/post/delete/${id}`, config);
      return data.message;
    } else {
      throw new Error('Le jéton est périmé veuillez reconnecter');
    }
  } catch (error: any) {
    return error.message;
  }
});

const initialState: postState = {
  loading: false,
  post: null,
  lists: [],
  error: null,
  message: null,
  total: 0,
  filterWord: '',
  manavatsava: [],
  starvan: [],
  fantsybobaka: [],
  dagolife: []
};

const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    },
    SetPostFilter: (state, action) => {
      state.filterWord = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllposts.pending, (state: postState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllposts.fulfilled, (state: postState, { payload }: any) => {
      state.loading = false;
      state.total = payload.length;
      state.lists = payload.filter(
        (obj: any) => 'files' in obj && obj.files?.length === 1 && obj.cover != null && obj.legend != null
      );
    });
    builder.addCase(GetAllposts.rejected, (state: postState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    /*dago*/
    builder.addCase(GetAllPostDagolife.pending, (state: postState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllPostDagolife.fulfilled, (state: postState, { payload }: any) => {
      state.loading = false;
      state.dagolife = payload.filter((post: any) => 'name' in post && post.name.toLowerCase() == 'dagolife');
    });
    builder.addCase(GetAllPostDagolife.rejected, (state: postState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    /*fantsy*/
    builder.addCase(GetAllPostFantsy.pending, (state: postState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllPostFantsy.fulfilled, (state: postState, { payload }: any) => {
      state.loading = false;
      state.fantsybobaka = payload.filter(
        (post: any) => ('name' in post && post.name.toLowerCase() == 'fantsy bobaka') || post.name.includes('fantsy')
      );
    });
    builder.addCase(GetAllPostFantsy.rejected, (state: postState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    /*manavatsava*/
    builder.addCase(GetAllPostManavatsava.pending, (state: postState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllPostManavatsava.fulfilled, (state: postState, { payload }: any) => {
      state.loading = false;
      state.manavatsava = payload.filter((post: any) => 'name' in post && post.name.toLowerCase() == 'manavatsava');
    });
    builder.addCase(GetAllPostManavatsava.rejected, (state: postState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    /*starvan*/
    builder.addCase(GetAllPostStarvan.pending, (state: postState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllPostStarvan.fulfilled, (state: postState, { payload }: any) => {
      state.loading = false;
      state.starvan = payload.filter((post: any) => 'name' in post && post.name.toLowerCase() == 'starvan');
    });
    builder.addCase(GetAllPostStarvan.rejected, (state: postState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    /*starvanlive */
    builder.addCase(GetAllPostStarvanlive.pending, (state: postState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllPostStarvanlive.fulfilled, (state: postState, { payload }: any) => {
      state.loading = false;
      state.lists = payload.filter((post: any) => post.tvShowId == 5);
    });
    builder.addCase(GetAllPostStarvanlive.rejected, (state: postState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    /*one post */
    builder.addCase(GetOnePost.pending, (state: postState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetOnePost.fulfilled, (state: postState, { payload }: any) => {
      state.loading = false;
      state.post = payload;
    });
    builder.addCase(GetOnePost.rejected, (state: postState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(Createpost.pending, (state: postState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Createpost.fulfilled, (state: postState, { payload }: any) => {
      state.loading = false;
      state.message = 'Création de votre publication avec succèss';
    });
    builder.addCase(Createpost.rejected, (state: postState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(UpdatePost.pending, (state: postState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(UpdatePost.fulfilled, (state: postState, { payload }: any) => {
      state.loading = false;
      state.message = 'Mise à jour de votre publication avec succèss';
    });
    builder.addCase(UpdatePost.rejected, (state: postState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(DeletePost.pending, (state: postState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(DeletePost.fulfilled, (state: postState, { payload }: any) => {
      state.loading = false;
      state.message = 'Suppression de votre publication avec succèss';
    });
    builder.addCase(DeletePost.rejected, (state: postState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
  }
});
export const { seterrornull, setMessageNull, SetPostFilter } = postSlice.actions;
export default postSlice.reducer;
