import React, { useState, ChangeEvent } from 'react';
import MainModal from '../MainModal';
import { MdUpload } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../Store/Store';
import { CreateShort, seterrornull } from '../../../../../Toolkit/ShortAction';
import { useNavigate } from 'react-router-dom';
import { CirclesWithBar } from 'react-loader-spinner';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { UpdateShort } from '../../../../../Toolkit/ShortAction';

const schema = yup
  .object({
    title: yup.string().required(),
    description: yup.string().required()
  })
  .required();
type FormData = yup.InferType<typeof schema>;

function UpdateShortComponent({ modalOpen, setModalOpen, sho }: any) {
  const loading = useSelector((state: any) => state.short.loading);
  const error = useSelector((state: any) => state.short.erreur);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const HandlePost = async (data: FormData) => {
    try {
      const short = {
        title: data.title,
        description: data.description
      };
      const id = sho.id;
      dispatch(UpdateShort({ short, id })).then((res: any) => {
        setModalOpen(false);
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <>
      <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <div className="inline-block sm:w-4/5 md:w-4/5 lg:w-4/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl">
          {loading && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
              <CirclesWithBar
                height="100"
                width="100"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            </div>
          )}
          <form className="w-full" onSubmit={handleSubmit(HandlePost)}>
            <div className="flex flex-col gap-6">
              <div className="w-full  grid md:grid-cols-2 gap-6">
                <div className="text-sm w-full">
                  <label className="text-border font-semibold">Titre</label>
                  <input
                    defaultValue={sho.title}
                    {...register('title')}
                    name="title"
                    required
                    type="text"
                    placeholder="Titre de votre short"
                    className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-main`}
                  />
                  <p className="text-red-600 text-left">{errors.title?.message}</p>
                </div>
                <div className="text-sm w-full">
                  <label className="text-border font-semibold">Description</label>
                  <input
                    defaultValue={sho.post.legend}
                    {...register('description')}
                    name="description"
                    required
                    type="text"
                    placeholder="Description"
                    className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-main`}
                  />
                  <p className="text-red-600 text-left">{errors.description?.message}</p>
                </div>
              </div>
              <div className="flex justify-end items-center my-4">
                <button
                  type="submit"
                  className="bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6"
                >
                  <MdUpload /> Mettre à jour le short
                </button>
              </div>
            </div>
          </form>
        </div>
      </MainModal>
      {error && (
        <div
          className="absolute -left-80 bottom-40 z-50 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">Une erreur a été survenu lors de création de votre post</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(seterrornull())} />
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateShortComponent;
