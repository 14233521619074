import { useEffect, useState } from 'react';
import { HiPlusCircle } from 'react-icons/hi';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../Store/Store';
import { GetAllsponsor, setMessageNull, seterrornull } from '../../../Toolkit/SponsorsActions';
import CreateSponsor from '../Components/Modals/Create/CreateSponsor';
import Sidebar from '../Components/Sidebar';
import TableSponsor from '../Components/TableSponsor';

function SponsorList() {
  const message = useSelector((state: any) => state.sponsor.message);
  const error = useSelector((state: any) => state.sponsor.error);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const sponsors = useSelector((state: any) => state.sponsor.lists);

  useEffect(() => {
    dispatch(GetAllsponsor());
  }, []);

  return (
    <>
      <Sidebar>
        <CreateSponsor modalOpen={openModal} setModalOpen={setOpenModal} />
        <div className="flex flex-col gap-6">
          <div className="flex-btn gap-2">
            <h2 className="text-xl font-bold">Liste de vos partenaires</h2>
            <button
              onClick={() => setOpenModal(true)}
              className="bg-main font-medium  hover:bg-subMain border border-subMain text-white py-3 px-6 rounded flex gap-2 items-center"
            >
              <HiPlusCircle /> Ajouter
            </button>
          </div>
          {sponsors && sponsors.length > 0 && <TableSponsor data={sponsors} admin={true} />}
        </div>
      </Sidebar>
      {message && (
        <div
          className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{message}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(setMessageNull())} />
          </div>
        </div>
      )}
      {error && (
        <div
          className="fixed left-1 bottom-1 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{error}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(seterrornull())} />
          </div>
        </div>
      )}
    </>
  );
}

export default SponsorList;
