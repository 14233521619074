import { useNavigate } from 'react-router-dom';
import { backendURLFile } from '../../../../../Config/baseUrl';

function Listvideos({ movie, data, titleVideo, i, type }: any) {
  const navigate = useNavigate();
  const getVideosEmission = () => {
    navigate(`/dashboard/client/emission/video`, {
      state: {
        movie: movie,
        data: data,
        title: titleVideo,
        type: type
      }
    });
  };

  const getVideosShort = () => {
    navigate(`/dashboard/client/short/video`, {
      state: {
        movie: movie,
        data: data,
        title: titleVideo,
        type: type
      }
    });
  };

  const getVideosTeaser = () => {
    navigate(`/dashboard/client/Teaser/video`, {
      state: {
        movie: movie,
        data: data,
        title: titleVideo,
        type: type
      }
    });
  };
  const getVideosMusic = () => {
    navigate(`/dashboard/client/music/video`, {
      state: {
        movie: movie,
        data: data,
        title: titleVideo,
        type: type
      }
    });
  };
  const coverUrl = process.env.PUBLIC_URL + '/Images/images.jpeg';
  return (
    <div>
      {type == 'emission' && (
        <div className="cursor-pointer">
          <img onClick={() => getVideosEmission()} src={`${backendURLFile}/${movie.cover}`} />
          <li key={i} className="text-center list-none">
            {movie.created_at} - {movie.legend}
          </li>
        </div>
      )}
      {type == 'short' && (
        <div className="cursor-pointer">
          <img
            onClick={() => getVideosShort()}
            src={movie.post.cover == undefined ? coverUrl : `${backendURLFile}/${movie.post.cover}`}
          />
          <li key={i} className="text-center list-none">
            {movie.post.created_at} - {movie.post.legend}
          </li>
        </div>
      )}
      {type == 'teaser' && (
        <div className="cursor-pointer max-sm:mx-8">
          <img
            onClick={() => getVideosTeaser()}
            style={{ height: 110 }}
            className="w-full"
            src={`${backendURLFile}/${movie.image}`}
          />
          <ul key={i} className="text-center list-none">
            <li>{movie.title}</li>
            <li>{movie.category}</li>
          </ul>
        </div>
      )}
      {type == 'music' && (
        <div className="cursor-pointer">
          <img onClick={() => getVideosMusic()} style={{ height: 180 }} src={`${backendURLFile}/${movie.post.cover}`} />
          <ul key={i} className="text-center list-none">
            <li>{movie.artists}</li>
            <li>{movie.title}</li>
            <li>{movie.categories[0]}</li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Listvideos;
