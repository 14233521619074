import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendURL } from '../Config/baseUrl';

interface pubState {
  loading: boolean;
  post: any | null;
  message: string | null;
  error: string | null;
  total: number | null;
}

interface userAddPot {
  legend: string;
  file: File;
}

const initialState: pubState = {
  loading: false,
  post: null,
  message: null,
  error: null,
  total: null
};

export const userPost = createAsyncThunk('user/pub', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/user/posts`, config);
    return data.posts;
  } catch (error: any) {
    return error.message;
  }
});

export const userAddPost = createAsyncThunk('insert', async (post: any, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('clientToken')) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('clientToken')}`
        }
      };
      const { data } = await axios.post(`${backendURL}/user/add_post`, post, config);
      return data.message;
    } else {
      throw new Error('Le jéton est périmé,veuillez reconnecter');
    }
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

const userPostSlice = createSlice({
  name: 'publication',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userPost.pending, (state: pubState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(userPost.fulfilled, (state: pubState, { payload }: any) => {
      state.loading = false;
      state.total = payload.length;
      state.post = payload;
    });
    builder.addCase(userPost.rejected, (state: pubState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(userAddPost.pending, (state: pubState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(userAddPost.fulfilled, (state: pubState, { payload }: any) => {
      state.loading = false;
      state.total = payload.length;
      state.post = payload;
    });
    builder.addCase(userAddPost.rejected, (state: pubState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
  }
});

export const { seterrornull, setMessageNull } = userPostSlice.actions;
export default userPostSlice.reducer;
