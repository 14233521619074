import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { backendURL, notifURL } from '../Config/baseUrl';

interface music {
  legend: string;
  music1?: File;
  title: string;
  cover?: File;
  type: string;
  ranking: Array<string>;
  categories: Array<string>;
  artists: Array<string>;
}
interface musicWithId {
  mus: {
    legend: string;
    title: string;
  };
  id: number;
}

interface musicState {
  loading: boolean;
  lists: Array<music>;
  categorieList: Array<string>;
  error: string | null;
  message: string | null;
}
export const GetAllmusics = createAsyncThunk('musics/all', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/post/musics`, config);
    return data.musics;
  } catch (error: any) {
    return error.message;
  }
});
export const GetAllmusicsCategories = createAsyncThunk('musics/categories', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/post/music/categories`, config);
    return data;
  } catch (error: any) {
    return error.message;
  }
});
export const Createmusic = createAsyncThunk('musics/add', async (musicInfo: any, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.post(`${backendURL}/post/music`, musicInfo, config);
      try {
        await axios.post(`${notifURL}/notif/event`).then((res: any) => {
          return res;
        });
      } catch (error) {
        throw new Error("Erreur lors d'envoie de notification");
      }
      return data.message;
    } else {
      throw new Error('Le jéton est périmé,veuillez vous reconnecter');
    }
  } catch (error: any) {
    return error;
  }
});
export const MusicDelete = createAsyncThunk('musics/delete', async (id: number, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.delete(`${backendURL}/post/music/delete/${id}`, config);
      return data;
    } else {
      throw new Error('Le jéton est périmé,veuillez vous reconnecter');
    }
  } catch (error: any) {
    return error;
  }
});
export const MusicUpdate = createAsyncThunk('musics/update', async (musicInfo: musicWithId, { rejectWithValue }) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.put(
        `${backendURL}/post/music/update/${musicInfo.id}`,
        {
          legend: musicInfo.mus.legend,
          title: musicInfo.mus.title
        },
        config
      );
      return data;
    } else {
      throw new Error('Le jéton est périmé,veuillez vous reconnecter');
    }
  } catch (error: any) {
    return error;
  }
});

const initialState: musicState = {
  loading: false,
  lists: [],
  categorieList: [],
  error: null,
  message: null
};

const musicSlice = createSlice({
  name: 'music',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllmusics.pending, (state: musicState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllmusics.fulfilled, (state: musicState, { payload }: any) => {
      state.loading = false;
      state.lists = payload.reverse();
    });
    builder.addCase(GetAllmusics.rejected, (state: musicState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(Createmusic.pending, (state: musicState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Createmusic.fulfilled, (state: musicState, { payload }: any) => {
      state.loading = false;
      state.message = 'Création de music avec succèss';
    });
    builder.addCase(Createmusic.rejected, (state: musicState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(GetAllmusicsCategories.pending, (state: musicState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllmusicsCategories.fulfilled, (state: musicState, { payload }: any) => {
      state.loading = false;
      state.categorieList = payload;
    });
    builder.addCase(GetAllmusicsCategories.rejected, (state: musicState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(MusicDelete.pending, (state: musicState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(MusicDelete.fulfilled, (state: musicState, { payload }: any) => {
      state.loading = false;
      state.message = 'Suppression de votre music avec succèss';
    });
    builder.addCase(MusicDelete.rejected, (state: musicState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(MusicUpdate.pending, (state: musicState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(MusicUpdate.fulfilled, (state: musicState, { payload }: any) => {
      state.loading = false;
      state.message = 'modification de votre music avec succèss';
    });
    builder.addCase(MusicUpdate.rejected, (state: musicState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
  }
});

export const { seterrornull, setMessageNull } = musicSlice.actions;
export default musicSlice.reducer;
