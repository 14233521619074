export default function convertirDate(date: Date): string {
  const jour = date.getDate().toString().padStart(2, '0');
  const mois = (date.getMonth() + 1).toString().padStart(2, '0'); // Les mois sont indexés à partir de zéro, donc on ajoute 1
  const annee = date.getFullYear().toString();

  return `${jour}-${mois}-${annee}`;
}
export function convertirStringEnDate(dateString: any): Date {
  const [annee, mois, jour] = dateString.split('-');
  return new Date(annee, mois - 1, jour);
}

export function convertirDateTeaser(date: Date): string {
  const jour = date.getDate().toString().padStart(2, '0');
  const mois = (date.getMonth() + 1).toString().padStart(2, '0'); // Les mois sont indexés à partir de zéro, donc on ajoute 1
  const annee = date.getFullYear().toString();

  return `${annee}-${mois}-${jour}`;
}
export function convertirChaineEnDateLocale(chaine: string): string {
  const moisEnFrancais = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre'
  ];

  const [jour, mois, annee] = chaine.split(' ');
  const moisIndex = moisEnFrancais.findIndex((moisFrancais) => moisFrancais === mois) + 1;

  // Création de la date locale à 00:00:00
  const dateLocale = new Date(parseInt(annee), moisIndex - 1, parseInt(jour), 0, 0, 0);

  // Formatage manuel de la date au format "yyyy-mm-dd"
  const anneeFormattee = dateLocale.getFullYear().toString();
  const moisFormatte = (moisIndex < 10 ? '0' : '') + moisIndex.toString();
  const jourFormatte = (parseInt(jour) < 10 ? '0' : '') + jour;

  return `${anneeFormattee}-${moisFormatte}-${jourFormatte}`;
}
