import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { CirclesWithBar } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch } from '../../../../Store/Store.jsx';
import { GetAllposts, setMessageNull } from '../../../../Toolkit/PostAction';
import Sidebar from '../../../Admin/Components/Sidebar';
import Pagination from '../Components/Pagination/Pagination';
import Videosplayer from '../Components/Videos/Videosplayer';

// Variables and State
const PageSize = 16;

function Emissionsclients() {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: any) => state.post.message);
  const posts = useSelector((state: any) => state.post.lists);
  const loading = useSelector((state: any) => state.post.loading);

  // useState
  const [finalpost, setFinalpost] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setCurrentTableData] = useState<any>([]);

  // useParams
  const { id } = useParams();

  // Functions
  const titre = () => {
    let i: any = id;
    switch (parseInt(i)) {
      case (i = 1):
        return 'Starvan';
      case (i = 2):
        return 'Bobaka fantastique';
      case (i = 3):
        return 'La vie de Dago';
      case (i = 4):
        return 'Manavatsava';
      default:
        return 'Liste des vidéos';
    }
  };

  const dataFilter = (term: string) => {
    let array: any[] = [];
    finalpost.filter((item: any) => {
      if (item.legend?.toLowerCase().includes(term?.toLowerCase())) {
        array.push(item);
      }
    });
    return array;
  };

  const resultSearch = dataFilter(searchTerm);
  const titleVideo = titre();

  // Lifecycle: useEffect for fetching posts
  useEffect(() => {
    dispatch(GetAllposts());
  }, []);

  // Lifecycle: useEffect for filtering posts by tvShowId
  useEffect(() => {
    if (posts.length > 0) {
      const finalpost = posts.filter((post: any) => post.tvShowId == id);
      setFinalpost(finalpost);
    }
  }, [posts, id]);

  // Lifecycle: useEffect for handling pagination
  useEffect(() => {
    if (finalpost) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      setCurrentTableData(finalpost.slice(firstPageIndex, lastPageIndex));
    }
  }, [finalpost, currentPage]);

  // JSX
  return (
    <>
      <Sidebar>
        <div className="flex flex-col gap-6">
          <div className="flex-btn gap-2">
            <h2 className="text-xl font-bold">{titleVideo}</h2>
            <div className="col-span-3">
              <form className="w-full text-sm bg-dryGray rounded flex-btn gap-4">
                <button className="bg-subMain w-12 flex-colo h-12 rounded text-white">
                  <FaSearch />
                </button>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="font-medium placeholder:text-border text-sm w-11/12 h-12 bg-transparent border-none px-2 text-black"
                  placeholder="Rechercher une vidéo"
                />
              </form>
            </div>
          </div>
          <div className="flex justify-center m-15">
            {loading && (
              <CirclesWithBar
                height="300"
                width="300"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            )}
          </div>
          <div>
            {posts && searchTerm == '' && (
              <Videosplayer data={currentTableData} titleVideo={titleVideo} type="emission" />
            )}
            {posts && searchTerm != '' && <Videosplayer data={resultSearch} titleVideo={titleVideo} type="emission" />}
          </div>
          <div>
            <Pagination
              className="flex w-full justify-center"
              currentPage={currentPage}
              totalCount={finalpost.length}
              pageSize={PageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
          </div>
        </div>
      </Sidebar>
      {message && (
        <div
          className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{message}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(setMessageNull())} />
          </div>
        </div>
      )}
    </>
  );
}

export default Emissionsclients;
