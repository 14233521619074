export const RatingsAnimateurs = [
  {
    title: 'Mandresy',
    value: 0
  },
  {
    title: 'Eddy Marco',
    value: 1
  },
  {
    title: 'Benson',
    value: 2
  },
  {
    title: 'Aina Maharavo',
    value: 3
  }
];
export const Animateurs = [
  {
    name: 'Mandresy',
    message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    titleImage: '1.jpg',
    image: 'mandresy.jpg',
    category: 'Starvan',
    language: 'English',
    year: '2022',
    time: '3hr',
    video: '',
    rate: '3.4',
    reviews: 23
  },
  {
    name: 'Benson',
    message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    titleImage: '4.jpg',
    image: 'banson.jpg',
    category: 'Starvan',
    language: 'English',
    year: '2022',
    time: '3hr',
    video: '',
    rate: '3.4',
    reviews: 23
  },
  {
    name: 'Aina maharavo',
    message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    titleImage: '2.png',
    image: 'aina.jpg',
    category: 'Starvan',
    language: 'English',
    year: '2022',
    time: '3hr',
    video: '',
    rate: '3.4',
    reviews: 23
  },
  {
    name: 'Eddy Marco',
    message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    titleImage: '1.jpg',
    image: 'eddy.jpg',
    category: 'Starvan',
    language: 'English',
    year: '2022',
    time: '3hr',
    video: '',
    rate: '3.4',
    reviews: 23
  }
];
