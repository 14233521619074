import React from 'react';
import Layout from '../Layout/Layout';
import Head from '../Components/Head';
import { FiMail, FiMapPin, FiPhoneCall } from 'react-icons/fi';
import Contact from '../Components/Contact';

function ContactUs() {
  const ContactData = [
    {
      id: 1,
      title: 'Email',
      info: 'Interactive grow backend ideas for cross-plateform models',
      icon: FiMail,
      contact: 'contact@zamandresyprod.mg'
    },
    {
      id: 2,
      title: 'Appelez-nous',
      info: 'Interactive grow backend ideas for cross-plateform models',
      icon: FiPhoneCall,
      contact: '+261 32 42 394 73'
    },
    {
      id: 3,
      title: 'Localisation',
      info: 'Tananarivo',
      icon: FiMapPin,
      contact: 'Antananarivo Madagascar'
    }
  ];
  return (
    <Layout>
      <div className="min-h-screen container mx-auto px-2 my-6 overflow-hidden">
        <Head title="Contact Us" />
        <div className="grid mg:grid-cols-2 gap-6 lg:my-20 mt-10 lg:grid-cols-2 xl:gap-8">
          <div className="col-span-1 md:col-span-1">
            {ContactData.map((item) => (
              <div
                key={item.id}
                className="border border-border flex-colo p-10 bg-dry rounded-lg text-center h-[150px] py-2 mt-5"
              >
                <span className="flex-colo w-20 h-20 mb-4 rounded-full bg-main text-subMain text-xl">
                  <item.icon />
                </span>
                <h5 className="text-xl font-semibold mb-2">{item.title}</h5>
                <p className="mb-0 text-sm text-text leading-7">
                  <a href={`mailto:${item.contact}`} className="text-blue-600">
                    {item.contact}
                  </a>{' '}
                </p>
              </div>
            ))}
          </div>
          <div className="col-span-1 mt-4">
            <Contact />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ContactUs;
