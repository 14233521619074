import React from 'react';

function Head({ title }: any) {
  return (
    <div className="w-full bg-gray-500 lg:h-64 h-40 relative overflow-hidden rounded-sm">
      <img src="/assets/images/covert.jpg" alt="head" className="w-full h-full object-cover" />
      <div className="absolute top-16 lg:top-24 w-full flex-colo">
        <h1 className="text-2xl lg:text-h1 text-white text-center font-bold">{title && title}</h1>
      </div>
    </div>
  );
}

export default Head;
