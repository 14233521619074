import { configureStore } from '@reduxjs/toolkit';
import AnimatorAction from '../Toolkit/AnimatorAction';
import ArtistAction from '../Toolkit/ArtistAction';
import EmissionAction from '../Toolkit/EmissionAction';
import EventAction from '../Toolkit/EventAction';
import FavouriteAction from '../Toolkit/FavouriteAction';
import MovieAction from "../Toolkit/MovieAction";
import MusicAction from '../Toolkit/MusicAction';
import PostAction from '../Toolkit/PostAction';
import QuizAction from '../Toolkit/QuizAction';
import ShortAction from '../Toolkit/ShortAction';
import SponsorsActions from '../Toolkit/SponsorsActions';
import SubcriptionTransactionAction from "../Toolkit/SubcriptionTransactionAction";
import TeaserAction from '../Toolkit/TeaserAction';
import UsAction from '../Toolkit/UsAction';
import UserAction from '../Toolkit/UserAction';
import authAction from '../Toolkit/authAction';

export const store = configureStore({
  reducer: {
    auth: authAction,
    animator: AnimatorAction,
    artist: ArtistAction,
    emission: EmissionAction,
    music: MusicAction,
    quiz: QuizAction,
    short: ShortAction,
    sponsor: SponsorsActions,
    favourite: FavouriteAction,
    event: EventAction,
    post: PostAction,
    publication: UsAction,
    user: UserAction,
    teaser: TeaserAction,
    movie: MovieAction,
    subscriptionTransaction: SubcriptionTransactionAction
  }
});

export type AppDispatch = typeof store.dispatch;
