import fly_one from '../Assets/images/fly_page-0001.jpg';
import fly_two from '../Assets/images/fly_page-0002.jpg';
import fly_three from '../Assets/images/fly_page-0003.jpg';
import fly_four from '../Assets/images/fly_page-0004.jpg';
import fly_five from '../Assets/images/fly_page-0005.jpg';
import fly_six from '../Assets/images/fly_page-0006.jpg';

export const flyers = [
  {
    id: 1,
    name: 'page1',
    image: fly_one
  },
  {
    id: 2,
    name: 'page2',
    image: fly_two
  },
  {
    id: 3,
    name: 'page3',
    image: fly_three
  },
  {
    id: 4,
    name: 'page4',
    image: fly_four
  },
  {
    id: 5,
    name: 'page5',
    image: fly_five
  },
  {
    id: 6,
    name: 'page6',
    image: fly_six
  }
];
