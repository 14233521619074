import { useState } from 'react';
import { FaCloudDownloadAlt, FaEdit } from 'react-icons/fa';
import { GoEye } from 'react-icons/go';
import { MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { backendURLFile } from '../../../Config/baseUrl';
import DeleteEvent from './Modals/Delete/DeleteEvent';
import UpdateEvent from './Modals/Update/UpdateEvent';

export default function RowsEvent({ event, i, admin }: any) {
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const Head = 'text-xs text-left text-main font-semibold px-6 py-2 uppercase';
  const Text = 'text-sm text-left leading-6 whitespace-nowrap px-5 py-3';
  return (
    <>
      <DeleteEvent modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} event={event} />
      <UpdateEvent modalOpen={openModal} setModalOpen={setOpenModal} ev={event} />
      <tr key={i}>
        <td className={`${Text}`}>
          <div className="w-12 p-1 bg-dry border border-border h-12 rounded overflow-hidden">
            <img className="h-full w-full  object-cover" src={`${backendURLFile}/${event.post.files[0]}`} alt="event" />
          </div>
        </td>
        <td className={`${Text}`}>
          {event.ticketPlace.length > 9 ? event.ticketPlace.slice(0, 10) + '...' : event.ticketPlace}
        </td>
        <td className={`${Text}`}>{event.date}</td>
        <td className={`${Text}`}>{event.place.length > 9 ? event.place.slice(0, 10) + '...' : event.place}</td>
        <td className={`${Text} float-left flex-rows gap-2`}>
          {admin ? (
            <>
              <button
                onClick={() => setOpenModal(true)}
                className="border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2"
              >
                Edit <FaEdit className="text-green-500" />
              </button>
              <button
                onClick={() => setOpenModalDelete(true)}
                className="bg-subMain text-white rounded flex-colo w-7 h-7"
              >
                <MdDelete />
              </button>
            </>
          ) : (
            <>
              <button className="border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2">
                Download <FaCloudDownloadAlt className="text-green-500" />
              </button>
              <Link to={`/events/${event?.name}`}>
                <button className="bg-subMain text-white rounded flex-colo w-6 h-6">
                  <GoEye />
                </button>
              </Link>
            </>
          )}
        </td>
      </tr>
    </>
  );
}
