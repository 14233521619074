import React, { useEffect, useState } from 'react';
import { getAllVideos, VideoData } from "../../../../shared/indexBb/indexedDBHelper";
import Sidebar from "../../../Admin/Components/Sidebar";
import DetailMovieDownloaded from "./DetailMovieDownloaded";
import MovieItemsDownloaded from "./MovieItemsDownloaded";

interface DownloadProps {

}

const Download: React.FC<DownloadProps> = () => {
  /**
   * VARIABLE
   */
  const [movies, setMovies] = useState<VideoData[]>([])
  const [selectedMovie, setSelectedMovie] = useState<VideoData | null>(null)
  /**
   * LYFECICLE
   */
  useEffect(() => {
    allVideos()
  }, []);

  /**
   * FUNCTION
   */
  const allVideos = async () => {
    const allVideos = await getAllVideos()
    setMovies(allVideos)
  }

  const handleDetailMovie = (movie: VideoData) => {
    setSelectedMovie(movie)
  }

  const handleChangeSelectedMovie = () => {
    setSelectedMovie(null)
  }

  return (
    <Sidebar>
      <div className="flex flex-col gap-6">
        <div className="flex-btn gap-2">
          <h2 className="text-xl font-bold">Téléchargement</h2>
        </div>
        {
          !selectedMovie && <div className="grid gap-x-8 gap-y-4 grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-2 max-[400px]:grid-cols-1 mt-2">
            {
              movies && movies?.map((videoData: VideoData, index: number) => (
                <MovieItemsDownloaded key={`movie_${index}`} videoData={videoData} handleDetailMovie={handleDetailMovie} />
              ))
            }
          </div>
        }

        {selectedMovie && <DetailMovieDownloaded detailMovie={selectedMovie} handleChangeSelectedMovie={handleChangeSelectedMovie} />}
      </div>
    </Sidebar>
  );
}

export default Download;
