import React, { useState } from 'react';
import { FcComments, FcFilm, FcLeft, FcLike } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { backendURLFile } from "../../../../Config/baseUrl";
import { VideoData } from "../../../../shared/indexBb/indexedDBHelper";

interface DetailMovieDownloadedProps {
  detailMovie: VideoData
  handleChangeSelectedMovie: () => void
}

const DetailMovieDownloaded: React.FC<DetailMovieDownloadedProps> = ({ detailMovie, handleChangeSelectedMovie }) => {
  /**
   * VARIABLE
   */
  const navigate = useNavigate();
  const { movie, blob } = detailMovie
  const [playVideo, setPlayVideo] = useState<boolean>(false)


  /**
   * FUNCTION
   */
  const handlePlay = async () => {
    try {
      setPlayVideo(true)
      setTimeout(() => {
        const url = URL.createObjectURL(blob);
        const videoElement = document.querySelector('video') as HTMLVideoElement;
        videoElement.src = url;
        videoElement.play();
      }, 1000);

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full flex flex-col m-2  max-md:mb-64">
      <div className="flex-btn gap-2">
        <h2 className="text-xl font-bold cursor-pointer"><FcLeft onClick={() => { handleChangeSelectedMovie(); setPlayVideo(false) }} /></h2>
        <div className="col-span-3">
        </div>
      </div>
      <div className="justify-center m-15 mt-4 d-flex flex-column">
        <div>
          <article className="text-wrap">
            <h3 className="text-2xl font-semibold text-pink-500">{movie?.title}</h3>
            <h5 className="text-sm font-semibold mt-5">Description :</h5>
            <p className="text-xs p-1">{movie?.description}</p>
          </article>
          <div className="flex flex-row">
            <img className="w-2/1 h-32 float-left p-2" src={`${backendURLFile}/${movie?.cover_image}`} />
            <div className="p-3">
              <ul>
                <li>{`Genre : ${movie?.genres?.name}`}</li>
                <li>{`Durée : ${movie?.duration}`}</li>
                <li>{`Date de sortie : ${movie?.release_date}`}</li>
                <li>{`Directeur : ${movie?.director}`}</li>
                {
                  movie?.isFree == 1 ? <li>Gratuit</li> : <li>{`Prix: ${movie?.price} Ar`}</li>
                }

              </ul>
            </div>
          </div>
          <div className="flex mt-4 space-x-2">
            <div
              onClick={handlePlay}
              className="flex items-center px-3 py-1.5 bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] cursor-pointer bg-white rounded-lg shadow-lg hover:border-2 border-zinc-50"
            >
              <FcFilm className="mr-2" />
              <h2 className="text-sm font-semibold">Voir Film</h2>
            </div>
          </div>
        </div>

        <div className="w-full h-5/6 pt-2 rounded-xl mt-4">
          {
            playVideo && <video className="w-full max-w-3xl mx-auto" controls>
              <source type="video/mp4" />
              Your browser does not support the video tag.
            </video>

          }
          <div className="h-1/3 text-base text-slate-100 flex flex-row justify-between">
            <div>
              <p className="text-sm font-semibold pt-2">
              </p>
            </div>
            <div className="text-base pt-3 flex flex-row">
              <span className="cursor-pointer flex flex-row">
                {0} <FcComments size={23} />
              </span>
              <span className="mx-10 cursor-pointer flex flex-row">
                {0} <FcLike size={23} />
              </span>
            </div>
          </div>
          <div className="text-base">
            <p>@ Utilisateur</p>
            <p>Commentaire de l'utilisateur</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailMovieDownloaded;
