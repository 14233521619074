import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/Store";
import { ITransaction, getAllTransaction } from "../../../../Toolkit/SubcriptionTransactionAction";
import RowsTransaction from "./RowsTransaction";

const TableTransaction: React.FC = () => {

  /**
  * REDUX
  */
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: any) => state.subscriptionTransaction.message);
  const transactionList: ITransaction[] = useSelector((state: any) => state.subscriptionTransaction.transactionList);

  /**
   * VARIABLE
   */
  const head = 'text-xs text-left text-main font-semibold px-6 py-2 uppercase';


  /**
   * LYFECICLE
   */
  useEffect(() => {
    dispatch(getAllTransaction())
  }, [])

  return (
    <div className="overflow-x-auto relative w-full h-[400px] overflow-y-auto">
      <table className="w-full table-auto border border-border divide-y divide-border">
        <thead>
          <tr className="bg-dryGray">
            <th scope="col" className={`${head}`} style={{ width: '20%' }}>
              Date d'abonnement
            </th>
            <th scope="col" className={`${head}`} style={{ width: '20%' }}>
              Date d'éxpiration
            </th>
            <th scope="col" className={`${head}`} style={{ width: '20%' }}>
              Type d'abonnement
            </th>
            <th scope="col" className={`${head}`} style={{ width: '20%' }}>
              Film
            </th>
            <th scope="col" className={`${head}`}>
              Moyen de payement
            </th>
            <th scope="col" className={`${head}`}>
              Status
            </th>
            <th scope="col" className={`${head}`}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-main divide-y divide-gray-800">
          {transactionList.map((transaction: ITransaction, i: number) => (
            <RowsTransaction transaction={transaction} key={i} admin={true} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableTransaction;
