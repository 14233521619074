import Listvideos from './Listvideos';

function Videosplayer({ data, titleVideo, type }: any) {
  return (
    <div className="grid gap-x-8 gap-y-4 grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-2 max-[400px]:grid-cols-1">
      {data.map((movie: any, i: number) => {
        return (
          <div key={i}>
            <Listvideos movie={movie} data={data} titleVideo={titleVideo} key={i} type={type} />
          </div>
        );
      })}
    </div>
  );
}

export default Videosplayer;
