import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../Components/Sidebar';
import Table from '../../Components/Table';
import { Movies } from '../../../../Data/VideoData';
import { HiPlusCircle } from 'react-icons/hi';
import CreateEmission from '../../Components/Modals/Create/CreateVideoEmission';
import { AppDispatch } from '../../../../Store/Store.jsx';
import { GetAllposts, setMessageNull } from '../../../../Toolkit/PostAction';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useParams } from 'react-router-dom';

function Emissions() {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: any) => state.post.message);
  const [openModal, setOpenModal] = useState(false);
  const [cast, setCast] = useState(null);
  const posts = useSelector((state: any) => state.post.lists);
  const finalpost = posts.filter((post: any) => post.tvShowId == id);
  useEffect(() => {
    dispatch(GetAllposts());
  }, [message]);
  return (
    <>
      <Sidebar>
        <CreateEmission modalOpen={openModal} setModalOpen={setOpenModal} id={id} />
        <div className="flex flex-col gap-6">
          <div className="flex-btn gap-2">
            <h2 className="text-xl font-bold">Liste des videos</h2>
            <button
              onClick={() => setOpenModal(true)}
              className="bg-subMain flex-rows gap-4 font-medium transitions hover:bg-main border border-subMain text-white py-2 px-4 rounded"
            >
              <HiPlusCircle /> Ajouter
            </button>
          </div>
          {posts && <Table data={finalpost} admin={true} emission="starvan" />}
        </div>
      </Sidebar>
      {message && (
        <div
          className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{message}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(setMessageNull())} />
          </div>
        </div>
      )}
    </>
  );
}

export default Emissions;
