import React from 'react';

const AbonnementPending: React.FC = () => {
  return (
    <div
      className="fixed right-0 top-0  w-96 bg-zinc-900 bg-opacity-90 p-4 text-base text-danger-700 gap-4 flex justify-center hover:bg-opacity-90"
      role="alert"
    >
      {/* <div className="flex flex-row">
        <div>
          <img className="h-14 w-14" src={process.env.PUBLIC_URL + '/Images/casque.png'} />
        </div>
        <div className="text-[10px] flex flex-col">
          <p className="text-gray-100 text-base font-semibold">Votre abonnement est en cours de traitement,</p>
          <p className="text-gray-100 text-base font-semibold">Veuillez patient</p>
          <div className="flex flex-row justify-between">
            <p
              className="text-red-700 hover:text-red-500 cursor-pointer"
            >
              Fermer
            </p>
          </div>
        </div>
      </div>
      <span className="z-50 bg-red-500 text-white text-xs font-semibold px-2 rounded-full absolute top-0 right-96">
        1
      </span> */}
    </div>
  );
}

export default AbonnementPending;
