import React, { useState, ChangeEvent, FormEvent } from 'react';
import { MdUpload } from 'react-icons/md';
import { CirclesWithBar } from 'react-loader-spinner';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { IoIosCloseCircleOutline } from 'react-icons/io';

interface Formule {
  nom: string;
  email: string;
  message: string;
}

function Contact() {
  const [formData, setFormData] = useState<Formule>({
    nom: '',
    email: '',
    message: ''
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    // Replace these values with your Email.js template ID and user ID
    const serviceID = 'service_ggkxyy4';
    const templateID = 'template_f018z9j';
    const userID = 'GstsnLu2Apfp8XBmR';

    // Send the email using Email.js
    emailjs
      .send(serviceID, templateID, formData as any, userID)
      .then((response: EmailJSResponseStatus) => {
        setFormData({ nom: '', email: '', message: '' });
        setMessage('Message anvoyer avec succèss');
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  return (
    <div className="inline-block sm:w-4/5 md:w-4/5 lg:w-4/5 w-full align-middle p-10 overflow-y-auto h-auto border border-border bg-main text-white  rounded-2xl">
      <div className="flex flex-col gap-6 relative">
        {loading && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
            <CirclesWithBar
              height="100"
              width="100"
              color="#E90064"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              outerCircleColor=""
              innerCircleColor=""
              barColor=""
              ariaLabel="circles-with-bar-loading"
            />
          </div>
        )}
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="w-full gap-6">
            <div className="text-sm w-full">
              <label className="text-border font-semibold">Votre nom complet</label>
              <input
                type="text"
                id="nom"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                required
                placeholder="Nom complet..."
                className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
              />
            </div>
          </div>
          <div className="w-full  gap-6 mt-4">
            <div className="text-sm w-full">
              <label className="text-border font-semibold">Votre email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Votre email"
                className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
              />
            </div>
          </div>
          <div className="w-full  gap-6 mt-4">
            <div className="text-sm w-full">
              <label className="text-border font-semibold">Votre message</label>
              <input
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                type="text"
                placeholder="..."
                className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-dry`}
              />
            </div>
          </div>
          <div className="flex justify-end items-center my-4 mt-8">
            <button
              type="submit"
              className="bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6"
            >
              <MdUpload /> Envoyer
            </button>
          </div>
        </form>
        {message && (
          <div
            className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
            role="alert"
          >
            <div className="text-[12px] flex gap-8 items-center">
              <p className="text-gray-100">Votre message à ete envoyé avec succèss</p>
            </div>
            <div className="text-white cursor-pointer">
              <IoIosCloseCircleOutline onClick={() => setMessage('')} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Contact;
