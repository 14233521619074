import React, { useEffect, useState } from 'react';
import Titles from '../Components/Titles';
import { BsCollectionFill } from 'react-icons/bs';
import Movie from './Video';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllposts } from '../../../Toolkit/PostAction';
import { AppDispatch } from '../../../Store/Store';
import { FiChevronsRight, FiChevronsLeft } from 'react-icons/fi';

function PopularMovies() {
  /**
   * REDUX
   */
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: any) => state.post.message);
  const word = useSelector((state: any) => state.post.filterWord);
  const posts = useSelector((state: any) => state.post.lists);

  /**
   * VARIABLE
   */
  const FilteredPost = posts.filter((post: any) => post.legend.toLowerCase().includes(word.toLowerCase()));
  const [visibleCount, setVisibleCount] = useState(8);
  const [showMore, setShowMore] = useState(false);
  const [postsToShow, setPostsToShow] = useState([]);
  const [page, setPage] = useState<number>(0)
  const [numberTotalPage, setNumberTotalPage] = useState<number>(0)

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    if (posts.length > 0) {
      setNumberTotalPage(Math.ceil(posts.length / visibleCount))
    }
  }, [posts])

  useEffect(() => {
    if (posts && postsToShow && posts.length === postsToShow.length) {
      setShowMore(false)
    }
  }, [postsToShow, posts])

  useEffect(() => {
    if (posts.length > 0) {
      setPostsToShow(FilteredPost.slice(0, (page === 0 ? 1 : page) * visibleCount));
      setShowMore(true);
    }
  }, [page, posts]);

  useEffect(() => {
    dispatch(GetAllposts());
  }, []);

  /**
   * FUNCTION
   */
  const handleShowMore = () => {
    setPage((page) => page + 1);
  };

  const handleShowLess = () => {
    setPage(0);
  };

  return (
    <div className="my-16">
      <Titles title="Videos populaire" Icon={BsCollectionFill} />
      <p className="text-lg font-medium my-6 ">
        Total{' '}
        <span className="font-bold text-subMain">{(word !== null ? FilteredPost : posts).length} Vidéos trouvées</span>
      </p>
      <div className="grid sm:mt-12 mt-6 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10">
        {postsToShow.map((movie: any, index: any) => (
          <Movie key={index} movie={movie} />
        ))}
      </div>
      {showMore ? (
        <button className="bg-subMain text-white font-bold py-2 px-4 rounded mt-4" onClick={handleShowMore}>
          <p className="flex gap-1 items-center">
            Voir plus <FiChevronsRight />
          </p>
        </button>
      ) : (
        <button className="bg-subMain text-white font-bold py-2 px-4 rounded mt-4" onClick={handleShowLess}>
          <p className="flex gap-1 items-center">
            <FiChevronsLeft /> Voir moins
          </p>
        </button>
      )}
    </div>
  );
}

export default PopularMovies;
