import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendURL } from '../Config/baseUrl';

interface Sponsor {
  name: string;
  logo?: File;
  description: string;
  link: string;
}
interface SponsorWithId {
  spons: Sponsor;
  id: number;
}

interface sponsorState {
  loading: boolean;
  lists: Array<Sponsor>;
  error: string | null;
  message: string | null;
}
export const GetAllsponsor = createAsyncThunk('sponsor/all', async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await axios.get(`${backendURL}/sponsors`, config);
    return data.sponsors;
  } catch (error: any) {
    return error.message;
  }
});
export const SponsorCreate = createAsyncThunk('sponsor/create', async (SponsorInfo: Sponsor) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.post(`${backendURL}/sponsor`, SponsorInfo, config);
      return data;
    } else {
      throw new Error('Erreur de jéton,veuillez reconnecter');
    }
  } catch (error: any) {
    return error.message;
  }
});
export const SponsorDelete = createAsyncThunk('sponsor/delete', async (id: number) => {
  try {
    if (localStorage.getItem('adminToken')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`
        }
      };
      const { data } = await axios.delete(`${backendURL}/sponsor/${id}`, config);
      return data;
    } else {
      throw new Error('Erreur de jéton,veuillez reconnecter');
    }
  } catch (error: any) {
    return error.message;
  }
});

const initialState: sponsorState = {
  loading: false,
  lists: [],
  error: null,
  message: null
};

const sponsorSlice = createSlice({
  name: 'sponsor',
  initialState,
  reducers: {
    seterrornull: (state) => {
      state.error = null;
    },
    setMessageNull: (state) => {
      state.message = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllsponsor.pending, (state: sponsorState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllsponsor.fulfilled, (state: sponsorState, { payload }: any) => {
      state.loading = false;
      state.lists = payload;
    });
    builder.addCase(GetAllsponsor.rejected, (state: sponsorState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(SponsorDelete.pending, (state: sponsorState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(SponsorDelete.fulfilled, (state: sponsorState, { payload }: any) => {
      state.loading = false;
      state.message = 'Suppression de votre partenaire avec succèss';
    });
    builder.addCase(SponsorDelete.rejected, (state: sponsorState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(SponsorCreate.pending, (state: sponsorState, { payload }: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(SponsorCreate.fulfilled, (state: sponsorState, { payload }: any) => {
      state.loading = false;
      state.message = 'Création de votre partenaire avec succèss';
    });
    builder.addCase(SponsorCreate.rejected, (state: sponsorState, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });
  }
});

export const { seterrornull, setMessageNull } = sponsorSlice.actions;
export default sponsorSlice.reducer;
