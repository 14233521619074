import React, { useEffect, useState } from 'react';
import { HiPlusCircle } from "react-icons/hi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/Store";
import { IMovie, getMovieLists, setMessageNull, setMovieList, setUpdatedMovieNull } from "../../../../Toolkit/MovieAction";
import CreateMovie from "../../Components/Modals/Create/CreateMovie";
import Sidebar from "../../Components/Sidebar";
import TableMovie from "../../Components/TableMovie";

const Movie: React.FC = () => {
  /**
  * REDUX
  */
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: any) => state.movie.message);
  const updatedMovie = useSelector((state: any) => state.movie.updatedMovie);
  const movieLists = useSelector((state: any) => state.movie.movieLists);

  /**
   * VARIABLE
   */
  const [openModalCreate, setModalOpenCreate] = useState<boolean>(false);

  /**
   * LYFECYCLE
   */
  useEffect(() => {
    if (openModalCreate === false) {
      dispatch(getMovieLists())
    }
  }, [openModalCreate])

  /**
 * LYFECYLE
 */
  useEffect(() => {
    if (updatedMovie) {
      const updateMovie: IMovie[] = movieLists.map((movie: IMovie) => {
        return movie.id === updatedMovie.id ? updatedMovie : movie
      })
      dispatch(setMovieList(updateMovie))
    }
  }, [updatedMovie])

  return (
    <Sidebar>
      <div className="flex flex-col gap-6">
        <div className="flex-btn gap-2">
          <h2 className="text-xl font-bold">Films</h2>
          <button
            className="bg-subMain flex-rows gap-4 font-medium transitions hover:bg-main border border-subMain text-white py-2 px-4 rounded"
            onClick={() => setModalOpenCreate(true)}
          >
            <HiPlusCircle /> Create
          </button>
        </div>
        <TableMovie />
        <CreateMovie modalOpen={openModalCreate} setModalOpen={setModalOpenCreate} />
      </div>
      {message && (
        <div
          className="absolute left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{message}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => { dispatch(setMessageNull()); dispatch(setUpdatedMovieNull()) }} />
          </div>
        </div>
      )}
    </Sidebar>
  );
}

export default Movie;
