// ModalChoicePayement.tsx
import React from 'react';
import MainModal from "../../../Admin/Components/Modals/MainModal";

interface ModalChoicePayementProps {
  openModal: boolean;
  setOpenModal: any;
  handleChoiceMoyenPayement: (choice: string) => void;
}

const ModalChoicePayement: React.FC<ModalChoicePayementProps> = ({ openModal, setOpenModal, handleChoiceMoyenPayement }) => {
  /**
   * FUNCTION
   */
  const handleChoiceMoyenPayment = (choice: string) => {
    handleChoiceMoyenPayement(choice);
  };

  return (
    <MainModal modalOpen={openModal} setModalOpen={setOpenModal}>
      <div className="inline-block w-full sm:w-4/5 md:w-3/5 lg:w-2/5 align-middle p-6 sm:p-10 overflow-y-auto border border-border bg-main text-white rounded-2xl">
        <p className="text-2xl sm:text-3xl md:text-4xl font-extrabold text-yellow-500 mb-6">Choix du moyen de payement</p>
        <div className="flex flex-wrap justify-between py-4 px-4 sm:px-6">
          {['MVola', 'Orange Money', 'Visa', 'Master Card'].map((paymentMethod, index) => (
            <div key={index} className="flex-grow p-2">
              <div
                onClick={() => handleChoiceMoyenPayment(paymentMethod.toLowerCase().replace(' ', '_'))}
                className="p-4 bg-gradient-to-r from-[#5C00B9] to-[#F31F5F] cursor-pointer rounded-xl shadow-lg flex justify-center items-center hover:border-2 border-zinc-50"
              >
                <h2 className="text-xl sm:text-2xl md:text-3xl font-extrabold p-3 whitespace-nowrap">{paymentMethod}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MainModal>
  );
}

export default ModalChoicePayement;
