import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { IoIosCloseCircleOutline, IoIosEye, IoIosEyeOff } from 'react-icons/io';
import { RxEnter } from 'react-icons/rx';
import { CirclesWithBar } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Layout from '../../Client/Layout/Layout';

import { useState } from 'react';
import { AppDispatch } from '../../../Store/Store';
import { LoginAdmin, seterrornull } from '../../../Toolkit/authAction';

const schema = yup
  .object({
    email: yup.string().required(),
    password: yup.string().required()
  })
  .required();
type FormData = yup.InferType<typeof schema>;

function Login() {
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const error = useSelector((state: any) => state.auth.error);
  const loading = useSelector((state: any) => state.auth.loading);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: FormData) => {
    try {
      await dispatch(LoginAdmin(data)).then((res) => {
        navigate('/dashboard');
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <Layout>
      <div className="container mx-auto px-2 my-12 flex-colo">
        <div className="relative w-full 2xl:w-2/5 gap-8 flex-colo p-14 md:w-3/5 bg-dry rounded-lg border border-border">
          {loading && (
            <div className="absolute top-32 mx-auto">
              <CirclesWithBar
                height="100"
                width="100"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            </div>
          )}
          <img src="/assets/images/logo.png" alt="logo" className="w-full h-12 object-contain" />
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="text-sm w-full">
              <label className="text-white font-semibold">Email</label>
              <input
                {...register('email')}
                name="email"
                required
                type="email"
                placeholder="Saisissez ici votre email"
                className="w-full  text-sm  mt-2 p-5 border border-border rounded text-text bg-main"
              />
            </div>
            <div className="text-sm w-full mt-4 relative">
              <label className="text-white font-semibold">Password</label>
              <div className="relative">
                <input
                  {...register('password')}
                  name="password"
                  required
                  type={showPassword ? 'text' : 'password'} // Toggle type based on state
                  placeholder="Saisissez ici votre mot de passe"
                  className="w-full text-sm mt-2 p-5 border border-border rounded text-text bg-main"
                />
                <div
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <IoIosEyeOff /> : <IoIosEye />}
                </div>
              </div>
            </div>
            <div className="text-sm w-full mt-4">
              <button
                type="submit"
                className="mt-4 bg-subMain transitions hover:bg-main flex-rows gap-4 text-white p-4 rounded-lg w-full cursor-pointer"
              >
                <RxEnter /> Connecter
              </button>
            </div>
          </form>
          {/*
                 <Link to="/dashboard" className='bg-subMain transitions hover:bg-main flex-rows gap-4 text-white p-4 rounded-lg w-full'>
                    <RxEnter/>Signin
                 </Link>
                  */}
          <p className="text-center text-border">
            Vous avez oublié votre mot de passe ?{' '}
            <Link to="/contact-us" className="text-dryGray font-semibold ml-2">
              Contacter l'admin
            </Link>
          </p>
        </div>
      </div>
      {error && (
        <div
          className="absolute left-1 bottom-1 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">Une erreur a été survenu lors de votre connexion,verifiez vos données</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(seterrornull())} />
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Login;
