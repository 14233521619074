import ChartData from '../../../Data/ChartData';

const Emissions = [
  {
    color: '#C6E0FD'
  },
  {
    color: '#fca5fc'
  },
  {
    color: '#daa5fc'
  },
  {
    color: '#FEF08A'
  },
  {
    color: '#60A5FA'
  }
];

export default function Statistique({ data }: any) {
  return (
    <>
      <div className="grid grid-cols-12 mt-8 gap-8">
        <div className="col-span-12 bg-white rounded-2xl h-[330px]">
          <ChartData datafinal={data} />
        </div>
      </div>
    </>
  );
}
