import { useNavigate } from 'react-router-dom';
import { backendURLFile } from '../../../../../Config/baseUrl';

export function EventList({ data }: any) {
  const navigate = useNavigate();
  const showFullScreen = (i: number) => {
    navigate(`/dashboard/client/events/show`, {
      state: {
        data: data,
        index: i
      }
    });
  };
  const Text = 'text-sm text-left leading-6 whitespace-nowrap px-5 py-3';

  return (
    <div className="overflow-x-hidden w-full h-[590px] overflow-y-auto">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="bg-main divide-y divide-gray-800">
          {data.map((event: any, i: number) => (
            <tr key={i}>
              <td className={`${Text}`}>
                <div className="w-12 p-1 bg-dry border border-border h-12 rounded overflow-hidden cursor-pointer">
                  <img onClick={() => showFullScreen(i)} src={`${backendURLFile}/${event.post.files[0]}`} />
                </div>
              </td>
              <td className={`${Text}`}>
                <div>
                  <b>{event.post.legend}</b> <i> le {event.date}</i> .
                  <p className="hover:bg-violet-800 rounded p-1 cursor-pointer w-96">Chez {event.place}</p>
                </div>
              </td>
              <td className={`${Text}`}>
                <a href={event.ticketPlace} className="hover:bg-violet-800 rounded p-1" target="_blank">
                  Réservation
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
