import React, { useState, ChangeEvent } from 'react';
import MainModal from '../MainModal';
import { MdUpload } from 'react-icons/md';
import { ImPlay } from 'react-icons/im';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../Store/Store';
import { CreateShort, seterrornull } from '../../../../../Toolkit/ShortAction';
import { useNavigate } from 'react-router-dom';
import { CirclesWithBar } from 'react-loader-spinner';
import { IoIosCloseCircleOutline } from 'react-icons/io';

const schema = yup
  .object({
    title: yup.string().required(),
    description: yup.string().required(),
    short: yup.mixed().label('Votre photo')
  })
  .required();
type FormData = yup.InferType<typeof schema>;

function CreateShortComponent({ modalOpen, setModalOpen }: any) {
  const loading = useSelector((state: any) => state.short.loading);
  const error = useSelector((state: any) => state.short.erreur);
  const dispatch = useDispatch<AppDispatch>();
  const [play, setPlay] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });
  const [videolink, setvideolink] = useState('');
  const handleFileChangeVideo = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setvideolink(URL.createObjectURL(e.target.files[0]));
    }
  };
  const HandlePost = async (data: FormData) => {
    try {
      const photo = (data.short as any[])[0];
      if (photo) {
        const formData = new FormData();
        formData.append('short', photo);
        const { title, description, ...restData } = data;
        const datafinal = { title, description, ...Object.fromEntries(formData) };
        dispatch(CreateShort(datafinal)).then((res: any) => {
          reset();
          setvideolink('');
          setModalOpen(false);
          return res;
        });
      } else {
        return 1;
      }
    } catch (error) {
      return error;
    }
  };
  return (
    <>
      <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <div className="inline-block sm:w-4/5 md:w-4/5 lg:w-4/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl">
          {loading && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
              <CirclesWithBar
                height="100"
                width="100"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            </div>
          )}
          <form className="w-full" onSubmit={handleSubmit(HandlePost)}>
            <div className="flex flex-col gap-6">
              <div className="w-full  grid md:grid-cols-2 gap-6">
                <div className="text-sm w-full">
                  <label className="text-border font-semibold">Titre</label>
                  <input
                    {...register('title')}
                    name="title"
                    required
                    type="text"
                    placeholder="Titre de votre short"
                    className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-main`}
                  />
                  <p className="text-red-600 text-left">{errors.title?.message}</p>
                </div>
                <div className="text-sm w-full">
                  <label className="text-border font-semibold">Description</label>
                  <input
                    {...register('description')}
                    name="description"
                    required
                    type="text"
                    placeholder="Description"
                    className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-main`}
                  />
                  <p className="text-red-600 text-left">{errors.description?.message}</p>
                </div>
              </div>
              <div className="w-full">
                <p className="text-border font-semibold text-sm">Votre short</p>
                <div className="w-full text-center">
                  <div className="w-full">
                    <label className="flex justify-center h-24  transition bg-main border-2 px-6 py-4  border-border border-dashed  rounded-md  appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                      <span className="flex items-center space-x-2">
                        <ImPlay className="text-subMain h-24 w-24" />
                        <span className="font-medium text-white text-center">
                          Ajouter un video a votre publication(optionnel)
                        </span>
                      </span>
                      <input
                        {...register('short', {
                          onChange: handleFileChangeVideo
                        })}
                        type="file"
                        accept="video/*"
                        name="short"
                        className="opacity-0"
                      />
                      <p className="text-red-600 text-left">{errors.short?.message}</p>
                    </label>
                  </div>
                </div>
                <div className="mt-2 w-32 h-32 p-2 bg-main border border-border rounded">
                  {videolink && (
                    <video autoPlay={play} controls className="w-full h-full rounded">
                      <source src={videolink} type="video/mp4" title="titre" />
                    </video>
                  )}
                </div>
              </div>
              <div className="flex justify-end items-center my-4">
                <button
                  type="submit"
                  className="bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6"
                >
                  <MdUpload /> Publier le short
                </button>
              </div>
            </div>
          </form>
        </div>
      </MainModal>
      {error && (
        <div
          className="absolute -left-80 bottom-40 z-50 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">Une erreur a été survenu lors de création de votre post</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(seterrornull())} />
          </div>
        </div>
      )}
    </>
  );
}

export default CreateShortComponent;
