import React from 'react';
import { FcLeft } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { backendURLFile } from "../../../../../Config/baseUrl";

interface EventDetailProps {
  setIsShowDetail: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvent: any
}

const EventDetail: React.FC<EventDetailProps> = ({ setIsShowDetail, selectedEvent }) => {
  return (
    <div className="w-full flex flex-col m-2  max-md:mb-64">
      <div className="flex-btn gap-2">
        <h2 className="text-xl font-bold cursor-pointer"><FcLeft onClick={() => setIsShowDetail(false)} /></h2>
        <div className="col-span-3">
          Détail évènement
        </div>
      </div>
      <div className="container mx-auto p-4">
        <article className="text-wrap">
          <h3 className="text-2xl font-semibold text-pink-500">{selectedEvent?.post.legend}</h3>
        </article>
        <div className="flex flex-col md:flex-row mt-4">
          <img
            className="w-full md:w-2/5 h-32 p-2"
            src={`${backendURLFile}/${selectedEvent?.post.files[0]}`}
            style={{ width: '300px', height: '300px', objectFit: 'cover' }}
          />
          <div className="p-4">
            <ul style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <li><b>Date</b> : {selectedEvent?.date}</li>
              <li><b>Lieu</b> : {selectedEvent?.place}</li>
              <li><b>Ticket</b>  : {selectedEvent?.ticketPlace}</li>
              <li><b>Ajouter</b> : {selectedEvent?.post?.created_at}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventDetail;