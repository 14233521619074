import RowsMusic from './RowsMusic';

const Head = 'text-xs text-left text-main font-semibold px-6 py-2 uppercase';

function TableMusic({ data, admin }: any) {
  return (
    <div className="overflow-x-hidden relative w-full h-[400px] overflow-y-auto">
      <table className="w-full table-auto border border-border divide-y divide-border">
        <thead>
          <tr className="bg-dryGray">
            <th scope="col" className={`${Head}`}>
              Music
            </th>
            <th scope="col" className={`${Head}`}>
              Titre
            </th>

            <th scope="col" className={`${Head}`}>
              Producteur
            </th>
            <th scope="col" className={`${Head}`}>
              Categorie
            </th>
            <th scope="col" className={`${Head}`}>
              Artistes
            </th>
            <th scope="col" className={`${Head}`}>
              Date de création
            </th>
            <th scope="col" className={`${Head}`}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-main divide-y divide-gray-800">
          {data.map((music: any, i: number) => (
            <RowsMusic key={i} music={music} admin={admin} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableMusic;
