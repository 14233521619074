import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { backendURLFile } from '../../../Config/baseUrl';
import { AppDispatch } from '../../../Store/Store';
import { GetAllsponsor } from '../../../Toolkit/SponsorsActions';

function Banner() {
  /**
   * REDUX
   */
  const sponsors = useSelector((state: any) => state.sponsor.lists);

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    // dispatch(GetAllsponsor());
  }, []);

  return (
    <div className="relative">
      <Swiper
        direction="horizontal"
        spaceBetween={1}
        slidesPerView={1}
        loop={true}
        speed={500}
        modules={[Autoplay]}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        className="xl:h-auto bg-dry lg:h-auto h-auto"
      >
        {sponsors &&
          sponsors.length >= 1 &&
          sponsors?.map((sponsor: any, index: any) => (
            <SwiperSlide key={index} className="relative rounded">
              <img
                src={`${backendURLFile}/${sponsor.logo}`}
                alt={sponsor.name}
                className="w-full h-full object-cover"
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

export default Banner;
