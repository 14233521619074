import RowsEvent from './RowsEvent';
import RowsShort from './RowsShort';

const Head = 'text-xs text-left text-main font-semibold px-6 py-2 uppercase';
const Text = 'text-sm text-left leading-6 whitespace-nowrap px-5 py-3';

function TableShort({ data, admin }: any) {
  return (
    <div className="overflow-x-hidden relative w-full h-[400px] overflow-y-auto">
      <table className="w-full table-auto border border-border divide-y divide-border">
        <thead>
          <tr className="bg-dryGray">
            <th scope="col" className={`${Head}`}>
              Video
            </th>
            <th scope="col" className={`${Head}`}>
              Titre
            </th>
            <th scope="col" className={`${Head}`}>
              Description
            </th>
            <th scope="col" className={`${Head}`}>
              Date
            </th>
            {/* 
                    <th scope="col" className={`${Head}`}>
                        Language
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Year
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Hours
                    </th>
                    **/}
            <th scope="col" className={`${Head}`}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-main divide-y divide-gray-800">
          {data.map((short: any, i: number) => (
            <RowsShort short={short} key={i} admin={admin} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableShort;
