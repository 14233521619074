import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../../Admin/Components/Sidebar';
import { AppDispatch } from '../../../../Store/Store';
import { useEffect } from 'react';
import { userPost } from '../../../../Toolkit/UsAction';
import CurrentUser from './user';
import PublicationUser from '../Components/UsTools/Publication';
import Pub from '../../Components/Pub';
import { NavLink } from 'react-router-dom';

export default function Usclient() {
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: any) => state.publication.message);
  const publication: any[] = useSelector((state: any) => state.publication.post);

  useEffect(() => {
    dispatch(userPost());
  }, []);

  return (
    <Sidebar>
      <div className="flex flex-row">
        <div className="overflow-auto overscroll-y-none">
          <div className="flex flex-col gap-6">
            <CurrentUser />
          </div>
          <div>{publication && <PublicationUser data={publication} />}</div>
        </div>
        <div className="w-2/6 bg-main ml-5 rounded-lg max-md:hidden">
          <Pub />
          <div className="flex justify-center mt-5 ">
            <NavLink to="contact-us">
              <p className="text-lg font-semibold">Contactez-nous</p>
            </NavLink>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
