import { useState } from 'react';
import { FaCloudDownloadAlt, FaEdit } from 'react-icons/fa';
import { GoEye } from 'react-icons/go';
import { MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { convertArrayToString } from '../../../Config/AlgoInput';
import { backendURLFile } from '../../../Config/baseUrl';
import DeleteMusic from './Modals/Delete/DeleteMusic';
import UpdateMusic from './Modals/Update/UpdateMusic';

export default function RowsMusic({ music, i, admin }: any) {
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [play, setPlay] = useState(false);
  const Head = 'text-xs text-left text-main font-semibold px-6 py-2 uppercase';
  const Text = 'text-sm text-left leading-6 whitespace-nowrap px-5 py-3';
  return (
    <>
      <UpdateMusic modalOpen={openModal} setModalOpen={setOpenModal} music={music} />
      <DeleteMusic modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} music={music} />
      <tr key={i}>
        <td className={`${Text}`}>
          {music.post.cover ? (
            <div className="w-12 p-1 bg-dry border border-border h-12 rounded overflow-hidden">
              <img className="h-full w-full  object-cover" src={`${backendURLFile}/${music.post.cover}`} alt="event" />
            </div>
          ) : (
            <div className="w-12 p-1 bg-dry border border-border h-12 rounded overflow-hidden">
              <video autoPlay={play} controls className="w-full h-full rounded">
                <source src={`${backendURLFile}/${music.post.files[0]}`} type="video/mp4" title="titre" />
              </video>
            </div>
          )}
        </td>
        <td className={`${Text}`}>{music.title}</td>
        {/*
                <td className={`${Text}`}>{music.director}</td>
                    */}
        <td className={`${Text}`}>
          {music.producer && music.producer.length > 9 ? music.producer.slice(0, 10) + '...' : music.producer}
        </td>
        <td className={`${Text}`}>
          {convertArrayToString(music.categories).length > 9
            ? convertArrayToString(music.categories).slice(0, 10) + '...'
            : convertArrayToString(music.categories)}
        </td>
        <td className={`${Text}`}>
          {convertArrayToString(music.artists).length > 9
            ? convertArrayToString(music.artists).slice(0, 10) + '...'
            : convertArrayToString(music.artists)}
        </td>
        <td className={`${Text} text-subMain font-bold`}>{music.post.created_at}</td>
        <td className={`${Text} float-left flex-rows gap-2`}>
          {admin ? (
            <>
              <button
                onClick={() => setOpenModal(true)}
                className="border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2"
              >
                Edit <FaEdit className="text-green-500" />
              </button>
              <button
                onClick={() => setOpenModalDelete(true)}
                className="bg-subMain text-white rounded flex-colo w-7 h-7"
              >
                <MdDelete />
              </button>
            </>
          ) : (
            <>
              <button className="border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2">
                Download <FaCloudDownloadAlt className="text-green-500" />
              </button>
              <Link to={`/events/${music?.name}`}>
                <button className="bg-subMain text-white rounded flex-colo w-6 h-6">
                  <GoEye />
                </button>
              </Link>
            </>
          )}
        </td>
      </tr>
    </>
  );
}
