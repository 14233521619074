import React from 'react';
import MainModal from '../MainModal';
import { useDispatch, useSelector } from 'react-redux';
import { CirclesWithBar } from 'react-loader-spinner';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MdUpload } from 'react-icons/md';
import { CreateQuizW } from '../../../../../Toolkit/QuizAction';
import { AppDispatch } from '../../../../../Store/Store';
import { useNavigate } from 'react-router-dom';

const schema = yup
  .object({
    title: yup.string().required()
  })
  .required();
type FormData = yup.InferType<typeof schema>;

function CreateQuiz({ modalOpen, setModalOpen }: any) {
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: any) => state.quiz.loading);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });
  const HandlePostQuiz = async (data: FormData) => {
    try {
      dispatch(CreateQuizW(data)).then((res) => {
        reset();
        setModalOpen(false);
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-block sm:w-4/5 md:w-4/5 lg:w-2/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl">
        <div className="flex flex-col gap-6 relative">
          {loading && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
              <CirclesWithBar
                height="100"
                width="100"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            </div>
          )}
          <form className="w-full" onSubmit={handleSubmit(HandlePostQuiz)}>
            <div className="text-sm w-full">
              <label className="text-border font-semibold">Titre de votre quiz</label>
              <input
                {...register('title')}
                name="title"
                type="text"
                placeholder="Ici le titre de votre quiz"
                className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
              />
              <p className="text-red-600 text-left">{errors.title?.message}</p>
            </div>
            <div className="flex justify-end items-center my-4">
              <button
                type="submit"
                className="bg-subMain transitions hover:bg-dry border border-subMain text-white py-2  rounded w-full  flex-rows gap-6"
              >
                <MdUpload /> Ajouter le quiz
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainModal>
  );
}

export default CreateQuiz;
