import React, { useState, ChangeEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MdUpload } from 'react-icons/md';
import { BsFiletypeM4P, BsImage } from 'react-icons/bs';
import { MdVideoLibrary } from 'react-icons/md';
import { CirclesWithBar } from 'react-loader-spinner';
import { MusicCategories } from '../../../../../Data/CategoriesData';
import { RankingCategorie } from '../../../../../Data/CategoriesData';
import { Createteaser, GetAllCategories } from '../../../../../Toolkit/TeaserAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../Store/Store';
import { convertirDateTeaser } from '../../../../../Config/FormatDate';
import MainModal from '../MainModal';
import createArrayFromInput from '../../../../../Config/AlgoInput';
import { MusicUpdate } from '../../../../../Toolkit/MusicAction';

const schema = yup
  .object({
    legend: yup.string().required(),
    title: yup.string().required()
  })
  .required();
type FormData = yup.InferType<typeof schema>;

function UpdateMusic({ modalOpen, setModalOpen, music }: any) {
  const loading = useSelector((state: any) => state.music.loading);
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const HandlePostMusic = async (data: FormData) => {
    try {
      const mus = data;
      const id = music.id;
      dispatch(MusicUpdate({ mus, id })).then((res) => {
        setModalOpen(false);
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-block sm:w-4/5 md:w-4/5 lg:w-2/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl">
        <div className="flex flex-col gap-6 relative">
          {loading && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
              <CirclesWithBar
                height="100"
                width="100"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            </div>
          )}
          <form className="w-full" onSubmit={handleSubmit(HandlePostMusic)}>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full col-span-2">
                <label className="text-white font-semibold">Legend ou description</label>
                <input
                  defaultValue={music.post.legend}
                  {...register('legend')}
                  name="legend"
                  type="text"
                  placeholder="Legend ou description"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.legend?.message}</p>
              </div>
            </div>
            <div className="w-full  grid md:grid-cols-2 gap-6">
              <div className="text-sm w-full col-span-2">
                <label className="text-white font-semibold">Titre</label>
                <input
                  required
                  defaultValue={music.title}
                  {...register('title')}
                  name="title"
                  type="text"
                  placeholder="Stephanie,Basta lion,...(separéz par un virgule s'il y a plusieurs artistes)"
                  className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}
                />
                <p className="text-red-600 text-left">{errors.title?.message}</p>
              </div>
            </div>
            <div className="flex justify-end items-center my-4">
              <button
                type="submit"
                className="bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6"
              >
                <MdUpload /> modifier la music
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainModal>
  );
}

export default UpdateMusic;
