import React, { useEffect } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllposts } from '../../../Toolkit/PostAction';
import { AppDispatch } from '../../../Store/Store';
import { flyers } from '../../../Data/fly';

function Flyers() {
  const message = useSelector((state: any) => state.post.message);
  const posts = useSelector((state: any) => state.post.lists);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(GetAllposts());
  }, [message]);
  return (
    <div className="relative w-full">
      <Swiper
        direction="horizontal"
        spaceBetween={1}
        slidesPerView={1}
        loop={true}
        speed={1000}
        modules={[Autoplay]}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        className="w-full xl:h-auto bg-dry lg:h-auto h-auto"
      >
        {flyers.map((flyer: any, index: any) => (
          <SwiperSlide key={index} className="relative rounded overflow-hidden">
            <img src={flyer.image} alt={flyer.id} className="w-full h-full object-cover" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Flyers;
