import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { CirclesWithBar } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../Store/Store';
import { GetAllAnimators, setMessagenull } from '../../../../Toolkit/AnimatorAction';
import Sidebar from '../../../Admin/Components/Sidebar';
import { AnimatorList } from '../Components/EventTools/Eventshow';
import Pagination from '../Components/Pagination/Pagination';

export default function AnimateurClient() {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: any) => state.animator.message);
  const loading = useSelector((state: any) => state.animator.loading);
  const animators = useSelector((state: any) => state.animator.lists);

  // useState
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTableData, setCurrentTableData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Functions
  const dataFilter = (term: string) => {
    if (animators && animators.length > 0) {
      let array: any[] = [];
      animators.filter((item: any) => {
        if (item.name?.toLowerCase().includes(term?.toLowerCase())) {
          array.push(item);
        }
      });
      return array;
    } else {
      return [];
    }
  };

  const resultSearch = dataFilter(searchTerm);

  // Lifecycle: useEffect for fetching animators
  useEffect(() => {
    dispatch(GetAllAnimators());
  }, []);

  // Constants
  const PageSize = 8;

  // Lifecycle: useEffect for handling pagination
  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTableData(animators.slice(firstPageIndex, lastPageIndex));
  }, [animators, currentPage]);


  return (
    <>
      <Sidebar>
        <div className="flex flex-col gap-6">
          <div className="flex-btn gap-2">
            <h2 className="text-xl font-bold">Animateurs</h2>
            <div className="col-span-3">
              <form className="w-full text-sm bg-dryGray rounded flex-btn gap-4">
                <button className="bg-subMain w-12 flex-colo h-12 rounded text-white">
                  <FaSearch />
                </button>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="font-medium placeholder:text-border text-sm w-11/12 h-12 bg-transparent border-none px-2 text-black"
                  placeholder="Rechercher un animateur"
                />
              </form>
            </div>
          </div>
          <div className="flex justify-center m-15">
            {loading && (
              <CirclesWithBar
                height="300"
                width="300"
                color="#E90064"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel="circles-with-bar-loading"
              />
            )}
          </div>
          <div>
            {animators && searchTerm == '' && <AnimatorList data={currentTableData} />}
            {animators && searchTerm != '' && <AnimatorList data={resultSearch} />}
          </div>
          <div>
            <Pagination
              className="flex w-full justify-center"
              currentPage={currentPage}
              totalCount={animators.length}
              pageSize={PageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
          </div>
        </div>
      </Sidebar>
      {message && (
        <div
          className="absolute left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert"
        >
          <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">{message}</p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={() => dispatch(setMessagenull())} />
          </div>
        </div>
      )}
    </>
  );
}
