import { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { backendURLFile } from '../../../Config/baseUrl';
import DeleteSponsor from './Modals/Delete/DeleteSponsor';

export default function RowsSponsor({ sponsor, i, admin }: any) {
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const Head = 'text-xs text-left text-main font-semibold px-6 py-2 uppercase';
  const Text = 'text-sm text-left leading-6 whitespace-nowrap px-5 py-3';
  return (
    <>
      <DeleteSponsor modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} sponsor={sponsor} />
      <tr key={i}>
        <td className={`${Text}`}>
          <div className="w-12 p-1 bg-dry border border-border h-12 rounded overflow-hidden">
            <img className="h-full w-full  object-cover" src={`${backendURLFile}/${sponsor.logo}`} alt="event" />
          </div>
        </td>
        <td className={`${Text}`}>{sponsor.name}</td>
        <td className={`${Text} text-subMain text-[12px]`}>
          <a>{sponsor.description.length > 9 ? sponsor.description.slice(0, 20) + '...' : sponsor.description}</a>
        </td>
        <td className={`${Text} text-subMain text-[12px]`}>
          <a href={sponsor.link}>{sponsor.link.length > 9 ? sponsor.link.slice(0, 15) + '...' : sponsor.link}</a>
        </td>
        <td className={`${Text} flex-rows gap-2`}>
          {admin ? (
            <>
              {/*
                            <button onClick={()=>setOpenModal(true)} className='border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2'>
                                Edit <FaEdit className='text-green-500'/>
                            </button>
                            */}
              <button
                onClick={() => setOpenModalDelete(true)}
                className="bg-subMain text-white rounded flex-colo w-7 h-7"
              >
                <MdDelete />
              </button>
            </>
          ) : (
            <>
              {/*
                            <button className='border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2'>
                                Download <FaCloudDownloadAlt className='text-green-500'/>
                            </button>
                            <Link to={`/events/${event?.name}`}>
                                <button className='bg-subMain text-white rounded flex-colo w-6 h-6'>
                                    <GoEye/>
                                </button>
                            </Link>
                            */}
            </>
          )}
        </td>
      </tr>
    </>
  );
}
